//import _ from "lodash"
import EventEmitter from 'events';
import io from 'socket.io-client';
import baobabFunnel, { reloadModelData } from './socketBaobabFunnel';
import baobabTree from '../state/index';
import pubsub from '../pubsub';
import { handleSRCSettingsPayload } from './SRCServerStatesLogic';

class SocketClient extends EventEmitter {
    initSocket(authToken) {
        this.disconectedMessageTimeout = null;
        this.connectionLossConfirmed = false;
        let socketNamespaceAndParams = '/?token=' + authToken;
        this.socket = io(socketNamespaceAndParams, {
            //transports: ['websocket'],
            reconnection: true,
            reconnectionDelay: 5000,
            reconnectionDelayMax: 5000,
            reconnectionAttempts: 200,
        });

        this.socket.on('connect', () => {
            this.emit('socket_connected');
            this.subscribeToPublicChannel();
            clearTimeout(this.disconectedMessageTimeout);
            this.connectionLossConfirmed = false;
        });
        this.socket.on('disconnect', () => {
            this.disconectedMessageTimeout = setTimeout(() => {
                this.connectionLossConfirmed = true;
                // baobabTree.root.select(['socketConnection', 'showConnectingScreen']).set(true);
                // baobabTree.root.select(['socketConnection', 'connectingMessage']).set('DISCONNECTED');
                // baobabTree.root.select(['socketConnection', 'heading']).set('');
                baobabTree.root
                    .select(['socketConnection'])
                    .set({ showConnectingScreen: true, connectingMessage: 'DISCONNECTED', heading: '' });
            }, 10000);
        });
        this.socket.on('reconnect', (attempt) => {
            this.connectionLossConfirmed = false;
            // baobabTree.root.select(['socketConnection', 'showConnectingScreen']).set(false);
            // baobabTree.root.select(['socketConnection', 'connectingMessage']).set('');
            // baobabTree.root.select(['socketConnection', 'heading']).set('');
            baobabTree.root
                .select(['socketConnection'])
                .set({ showConnectingScreen: false, connectingMessage: '', heading: '' });
        });
        this.socket.on('reconnecting', (attemptCount) => {
            if (this.connectionLossConfirmed) {
                // baobabTree.root.select(['socketConnection', 'showConnectingScreen']).set(true);
                // baobabTree.root.select(['socketConnection', 'connectingMessage']).set('RECONNECTING');
                // baobabTree.root.select(['socketConnection', 'heading']).set('(attempt ' + attemptCount + ')');
                baobabTree.root
                    .select(['socketConnection'])
                    .set({
                        showConnectingScreen: true,
                        connectingMessage: 'RECONNECTING',
                        heading: '(attempt ' + attemptCount + ')',
                    });
            }
        });
        this.socket.on('connect_error', (err) => {});
        this.socket.on('reconnect_attempt', () => {
            // connection may have failed (caused by proxy, firewall, browser, ...)
            //this.socket.io.opts.transports = ['websocket', "polling"];
        });
        this.socket.on('reconnect_failed', () => {
            // baobabTree.root.select(['socketConnection', 'showConnectingScreen']).set(true);
            // baobabTree.root.select(['socketConnection', 'connectingMessage']).set('RECONNECTING FAILED');
            // baobabTree.root.select(['socketConnection', 'heading']).set('(refresh to try again)');
            baobabTree.root
            .select(['socketConnection'])
            .set({
                showConnectingScreen: true,
                connectingMessage: 'RECONNECTING FAILED',
                heading: '(refresh to try again)',
            });
        });

        this.socket.on('subscribed', (socketId) => {
            console.log('pipe connected ...');
            // baobabTree.root.select(['socketConnection', 'showConnectingScreen']).set(false);
            // baobabTree.root.select(['socketConnection', 'connectingMessage']).set('');
            // baobabTree.root.select(['socketConnection', 'heading']).set('');
            baobabTree.root
            .select(['socketConnection'])
            .set({
                showConnectingScreen: false,
                connectingMessage: '',
                heading: '',
            });
            //console.log(socketId);
        });

        this.socket.on('leaved', (socketId) => {
            console.log('pipe disconnected ...');
            //console.log(socketId);
            // baobabTree.root.select(['socketConnection', 'showConnectingScreen']).set(true);
            // baobabTree.root.select(['socketConnection', 'connectingMessage']).set('DISCONNECTED');
            // baobabTree.root.select(['socketConnection', 'heading']).set('');
            baobabTree.root
            .select(['socketConnection'])
            .set({
                showConnectingScreen: true,
                connectingMessage: 'DISCONNECTED',
                heading: '',
            });
        });

        this.socket.on('message', (payload) => {
            //expected message payload object is: {entity, method, model, requestId}
            pubsub.emit('socketPayload', payload); //catch every special case on this one!!!!
            switch (payload.model) {
                case 'SRCInfo_virtualModel':
                    if (payload.entity.SRCSettings) {
                        handleSRCSettingsPayload(payload.entity);
                    }
                    break;
                case 'RELOAD_MODEL_DATA':
                    let modelName = payload.entity;
                    reloadModelData(modelName);
                    //reload model data
                    break;
                case 'SrcCardCodeCustom':
                    break;
                default:
                    baobabFunnel(payload.model, payload.method, payload.entity); //(modelName, method, payload)=>{
                    break;
            }
        });
    }

    subscribeToPublicChannel() {
        //messages on public channel - most commonly used....
        if (this.socket.connected) {
            this.socket.emit('subscribe', 'public');
        }
    }

    subscribeUser(uuid) {
        //messages for this user
        if (this.socket && this.socket.connected) {
            this.socket.emit('subscribe', uuid);
        }
    }
}

export default SocketClient;
