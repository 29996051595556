import * as React from 'react';
import { RoomInfoEditModel, ReservationModelWithModelsIncluded, ReservationModel, TaskManagementItemModel, LastCleaningForRooms } from '@common/modelDefinition';
import { translate } from '@data/translations';
import PageTitle from '@components/pageTitle';
import rest from '../../data/restWrapper';
// @ts-ignore
import _ from 'lodash';
//@ts-ignore
import { branch } from 'baobab-react/higher-order';
import './style.css';
import { setModalContent, closeModal } from '@components/modal';
import ConfirmButtons from '@components/buttons/confirmButtons';
import getRoomSRCStatusIcon, { getRoomSRCStatus } from '../../data/controllers/SRCStatus';
import { reservationStatusColorsHEX } from '../../data/reservationStatusEnums';
import BasicSRCSettings from '../roomInfo/components/basicSRCSettingsModal';
import { ReactComponent as HardwareIcon } from '../../assets/custom/icons/hardware.svg';
import { ReservationStatus } from '@common/modelDefinition';
import ReservationForm from '@pages/newReservations/index';
import moment from 'moment';
import {  TaskStatusEnumText } from '@pages/householdNew/interfaces';
import { getLastCleanedTimestamps } from '@pages/householdNew/api';

interface Map {
    [key: number]: string | undefined;
}

const activityStatus: Map = {
    0: 'Early bird',
    1: 'Confirmed',
    2: 'Check in',
    3: 'Canceled',
    4: 'Check out',
    5: 'Checked out',
    6: 'Stay over',
    7: 'Late check in',
    8: 'Checked in',
    9: 'Autoblocked',
};
// const cleaningStatusEnums = {
//     0: translate('cl_noActionTaken'),
//     1: translate('cl_cleaningRequested'),
//     2: translate('cl_stagedForCleaning'),
//     3: translate('cl_cleaningStarted'),
//     4: translate('cl_cleaningFinished'),
//     5: translate('cl_cleaningPostponed'),
//     6: translate('cl_cleaningSkipped'),
// };

interface JournalProps {
    history: any;
    match: any;
    reservations: ReservationModelWithModelsIncluded[];
    rooms: RoomInfoEditModel[];
    systemLocale: string;
    reservationRoute?: string;
    location: any;
    executeScrollTop: Function;
    defaultCurrency: string;
    activeEventTickets: any;
    groupedCleaningTasksByRoom: any;
    // cleaningStatusHeaders: any;
}

function Journal(props: JournalProps) {
    const { reservations, rooms, location, executeScrollTop } = props;
    const [reservation, setReservation] = React.useState(null as ReservationModel | null);

    const headerHeightRef: any = React.useRef(null);
    const footerHeightRef: any = React.useRef(null);

    const [split, setSplit] = React.useState<boolean>(false);
    const [headerHeight, setHeaderHeight] = React.useState<number>(0);
    const [footerHeight, setFooterHeight] = React.useState<number>(0);

    const checkedInReservationIds: number[] = [];
    const checkedInRoomInfoIds: number[] = [];

    for (const reservation of reservations) {
        if (reservation.statusEnum === 2) {
            checkedInReservationIds.push(reservation.id);
            checkedInRoomInfoIds.push(reservation.roomInfoId);
        }
    }
    const checkedInReservations = reservations.filter((r) => checkedInReservationIds.includes(r.id));

    const arrivalReservationIds: number[] = [];
    const arrivalRoomInfoIds: number[] = [];
    for (const reservation of reservations) {
        if (
            reservation.statusEnum === 1 &&
            (moment(reservation.checkInTimestamp).isSame(moment(), 'day') ||
                reservation.checkInTimestamp < moment().valueOf())
        ) {
            arrivalReservationIds.push(reservation.id);
            arrivalRoomInfoIds.push(reservation.roomInfoId);
        }
    }

    const arrivalReservations = reservations.filter(
        (r) => arrivalReservationIds.includes(r.id) && !checkedInRoomInfoIds.includes(r.roomInfoId)
    );

    const relevantReservations: ReservationModelWithModelsIncluded[] =
        checkedInReservations.concat(arrivalReservations);
    const sortedReservations = relevantReservations.sort(
        (a: ReservationModelWithModelsIncluded, b: ReservationModelWithModelsIncluded) =>
            Number(b.checkOutTimestamp) < Number(a.checkOutTimestamp) ? 1 : -1
    );

    const filteredRoomsIds: number[] = [];
    for (const room of rooms) {
        filteredRoomsIds.push(room.id);
    }

    const filteredReservations = sortedReservations.filter(
        (r) => r.RoomInfo && filteredRoomsIds.includes(r.RoomInfo.id)
    );

    const printJournal: boolean = location.search.lastIndexOf('printJournal') !== -1 ? true : false;

    React.useEffect(() => {
        if (!split) {
            return;
        }

        window.addEventListener('beforeprint', () => {
            setSplit(false);
        });
        return () => {
            window.removeEventListener('beforeprint', () => {
                return;
            });
        };
    }, [split]);

    React.useEffect(() => {
        if (printJournal) {
            const _headerHeight = headerHeightRef?.current?.scrollHeight;
            const _footerHeight = footerHeightRef?.current?.scrollHeight;
            setHeaderHeight(_headerHeight);
            setFooterHeight(_footerHeight);
        }
    }, [printJournal]);

    const setReservationModal = (r: ReservationModel | null) => {
        setReservation(r);
    };

    const splitReservationsIndex = Math.ceil(filteredReservations.length / 2);
    const firstHalfReservations: ReservationModelWithModelsIncluded[] = [];
    const secondHalfReservations: ReservationModelWithModelsIncluded[] = [];
    filteredReservations.forEach((r, index) => {
        if (index < splitReservationsIndex) {
            firstHalfReservations.push(r);
        } else {
            secondHalfReservations.push(r);
        }
    });

    return (
        <div className="mb-4">
            {reservation ? (
                <ReservationForm
                    history={props.history}
                    mousePosition={{ x: 0, y: 0 }}
                    selectedRoomsForReservations={null}
                    toggleSelectRoomForReservation={() => setReservationModal(null)}
                    reservationId={reservation?.id}
                    groupUuid={reservation?.groupUuid}
                />
            ) : null}
            <button
                className="button-white-default d-print-none"
                onClick={() => {
                    executeScrollTop();
                }}
                style={{
                    backgroundColor: 'black',
                    color: 'white',
                    opacity: 0.5,
                    position: 'fixed',
                    bottom: printJournal ? '20px' : '45px',
                    right: printJournal ? '20px' : `20px`,
                    // left: printJournal ? '' : `20px`,

                    zIndex: 2222,
                }}
            >
                <i className="fa fa-arrow-up"></i>
            </button>
            {printJournal ? (
                !split ? (
                    <div style={{ minWidth: '600px' }}>
                        <div className="journal-header" ref={headerHeightRef}>
                            <JournalHeader
                                printJournal={printJournal}
                                {...props}
                                split={split}
                                setSplit={setSplit}
                                setReservationModal={setReservationModal}
                            />
                        </div>
                        <div className="journal-footer" ref={footerHeightRef}>
                            <div className="ml-2 text-white" style={{ opacity: 0 }}>
                                {translate('Journal')}
                            </div>
                        </div>
                        <table className="w-100">
                            <thead>
                                <tr>
                                    <td>
                                        <div style={{ height: `${headerHeight ? headerHeight : 100}px` }}></div>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div /*style={{ top: `${headerHeight}px` }}*/>
                                            <JournalItems
                                                printJournal={printJournal}
                                                {...props}
                                                reservations={filteredReservations}
                                                arrivalRoomInfoIds={arrivalRoomInfoIds}
                                                setReservationModal={setReservationModal}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>
                                        <div style={{ height: `${footerHeight ? footerHeight : 60}px` }}></div>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                ) : (
                    <div className="col-12" style={{ minWidth: '600px' }}>
                        <div className="d-flex">
                            <div className="col-6">
                                <JournalHeader
                                    printJournal={printJournal}
                                    {...props}
                                    split={split}
                                    setSplit={setSplit}
                                    hideButtons={true}
                                    setReservationModal={setReservationModal}
                                />
                                <JournalItems
                                    printJournal={printJournal}
                                    {...props}
                                    reservations={firstHalfReservations}
                                    arrivalRoomInfoIds={arrivalRoomInfoIds}
                                    setReservationModal={setReservationModal}
                                />
                            </div>
                            <div className="col-6">
                                <JournalHeader
                                    printJournal={printJournal}
                                    {...props}
                                    split={split}
                                    setSplit={setSplit}
                                    setReservationModal={setReservationModal}
                                />
                                <JournalItems
                                    printJournal={printJournal}
                                    {...props}
                                    reservations={secondHalfReservations}
                                    arrivalRoomInfoIds={arrivalRoomInfoIds}
                                    setReservationModal={setReservationModal}
                                />
                            </div>
                        </div>
                    </div>
                )
            ) : (
                <div style={{ minWidth: '600px' }}>
                    <JournalHeader printJournal={printJournal} setReservationModal={setReservationModal} {...props} />
                    <JournalItems
                        printJournal={printJournal}
                        {...props}
                        reservations={filteredReservations}
                        arrivalRoomInfoIds={arrivalRoomInfoIds}
                        setReservationModal={setReservationModal}
                    />
                </div>
            )}
        </div>
    );
}

interface JournalPreviewProps extends JournalProps {
    printJournal: boolean;
    arrivalRoomInfoIds?: number[];
    setSplit?: Function;
    split?: boolean;
    hideButtons?: boolean;
    // cleaningStatusHeaders: any;
    setReservationModal: Function;
}

const JournalHeader = (props: JournalPreviewProps) => {
    const { printJournal, history, setSplit, split, hideButtons } = props;

    return (
        <div>
            <div>
                <div className="title-with-options display-flex space-between">
                    <PageTitle title={translate('Journal')} />
                    <div className="page-title-button">
                        {!printJournal ? (
                            <div
                                onClick={() => {
                                    history.push(`?printJournal`);
                                }}
                                className="button-white-default  d-print-none"
                            >
                                <i className={'fa fa-expand '}></i>
                            </div>
                        ) : (
                            <div
                                className="btn-group d-print-none"
                                style={hideButtons ? { opacity: 0, pointerEvents: 'none' } : {}}
                            >
                                <div
                                    onClick={() => {
                                        history.push(`?journal`);
                                    }}
                                    className="button-white-default "
                                >
                                    <i className={'fa fa-compress  '}></i>
                                </div>
                                {!split ? (
                                    <div
                                        onClick={() => {
                                            window.print();
                                        }}
                                        className="button-white-default "
                                    >
                                        <i className={'fa fa-print'}></i>
                                    </div>
                                ) : null}
                                {setSplit ? (
                                    <div
                                        onClick={() => {
                                            setSplit(!split);
                                        }}
                                        className="button-white-default "
                                    >
                                        {split ? (
                                            <i className={'fa fa-file-o'}></i>
                                        ) : (
                                            <i className={'fa fa-columns'}></i>
                                        )}
                                    </div>
                                ) : null}
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div>
                <div className="custom-list-header">
                    <div>{translate('Room')}</div>
                    {/* <div>{translate('Settings')}</div> */}
                    <div>{translate('Guest')}</div>
                    <div>{translate('Guests')}</div>
                    <div>{translate('Arrival')}</div>
                    <div>{translate('Departure')}</div>
                    <div>{translate('Price')}</div>
                    <div>{translate('Info')}</div>
                    <div>{translate('Note')}</div>
                    <div>{translate('Room mode')}</div>
                    <div>{translate('Household')}</div>
                    <div>{translate('Cleaned')}</div>
                    <div>{translate('Res status')}</div>
                    <div>{translate('Action')}</div>
                </div>
            </div>
        </div>
    );
};

const JournalItems = (props: JournalPreviewProps) => {
    const {
        reservations,
        /* cleaningStatusHeaders,*/ setReservationModal /* activeEventTickets */,
        groupedCleaningTasksByRoom,
    } = props;
    const [lastCleaningForRooms, setLastCleaningForRooms] = React.useState<LastCleaningForRooms[]>([]);
    const [reservationsWithActivityStatus, setReservations] = React.useState<any>(null);

    // const presentDayCleaningStatusHeader = cleaningStatusHeaders[0];
    // const cleaningStatuses = presentDayCleaningStatusHeader ? presentDayCleaningStatusHeader.CleaningStatuses : [];

    React.useEffect(() => {
        const loadData = async () => {
            try {
                const _reservations = await rest('/getReservationActivityStatus');
                const roomInfoIds:number[] = _reservations.map((r:ReservationModel)=>r.roomInfoId)
                const _lastCleaningForRooms = await getLastCleanedTimestamps(roomInfoIds)
                setLastCleaningForRooms(_lastCleaningForRooms)
                setReservations(_reservations);
            } catch (err) {
                console.warn(err);
            }
        };

        loadData().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, []);

    const findReservationActivityStatus = (reservation: ReservationModelWithModelsIncluded) => {
        let res = _.find(reservationsWithActivityStatus, (resWithAct: any) => {
            return resWithAct && resWithAct.id === reservation.id;
        });

        return res?.activityStatus;
    };

    return (
        <div>
            {reservations?.map((r) => {
                const lastCleaningForRoom = lastCleaningForRooms.find(l=>l.roomInfoId ===r.roomInfoId )
                return (
                    <ItemRow
                        activityStatusProp={findReservationActivityStatus(r)}
                        key={r.id}
                        reservation={r}
                        {...props}
                        groupedCleaningTasksByRoom={groupedCleaningTasksByRoom}
                        lastCleaningForRoom={lastCleaningForRoom}
                        setReservationModal={setReservationModal}
                    />
                );
            })}
        </div>
    );
};





interface ItemRowProps extends JournalPreviewProps {
    reservation: ReservationModelWithModelsIncluded;
    groupedCleaningTasksByRoom: any;
    activityStatusProp: number;
    setReservationModal: Function;
    lastCleaningForRoom?:LastCleaningForRooms
}

const ItemRow = (props: ItemRowProps) => {
    const {
        reservation,
        arrivalRoomInfoIds = [],
        systemLocale,
        defaultCurrency,
        printJournal,
        // history,
        reservationRoute = 'reservations',
        groupedCleaningTasksByRoom,
        activityStatusProp,
        setReservationModal,
        lastCleaningForRoom
    } = props;


    const { notes } = reservation;
    const adultsNumber = reservation.adultsNumber ? reservation.adultsNumber : 1;
    const customerName = reservation.Customer
        ? `${reservation.Customer.firstName} ${reservation.Customer.lastName}`
        : '';
    const companyName = reservation.Company ? reservation.Company.name : '';
    const guest =
        companyName.length > 0 && customerName.length > 0 ? (
            <div className="">
                {companyName}
                <br />
                {customerName}
            </div>
        ) : customerName.length > 0 ? (
            <div className="">{customerName}</div>
        ) : (
            <div className="">{companyName}</div>
        );

    const { checkoutWarning, guestArivalReminder, checkInWarning, guestArivalWarning, guestDepartureReminder } =
        getWarning(reservation, arrivalRoomInfoIds);

    const getLastCleaningTimeForRoom = (roomInfoId: number) => {
        const targetRoomTasks = groupedCleaningTasksByRoom[roomInfoId];
        if (targetRoomTasks && targetRoomTasks.length > 0) {
            const targetTask:TaskManagementItemModel = targetRoomTasks[0]; // treba ispitati radi li sort dobro
                const taskHistory = targetTask.taskHistory
                    ? typeof targetTask.taskHistory === 'string'
                        ? JSON.parse(targetTask.taskHistory)
                        : targetTask.taskHistory
                    : [];
                    const lastCleaningTime = taskHistory.length>0 ? taskHistory[0].endTimestamp : null
                    return moment(lastCleaningTime).fromNow()
          
        }else{
     
            return lastCleaningForRoom?.endTimestamp ?  moment(lastCleaningForRoom.endTimestamp).fromNow() : translate('Never')
        }
    };

    const getLastCleaningStatusForRoom = (roomInfoId: number) => {
        const targetRoomTasks = groupedCleaningTasksByRoom[roomInfoId];
        if (targetRoomTasks && targetRoomTasks.length > 0) {
            const targetTask:TaskManagementItemModel = targetRoomTasks[0]; // treba ispitati radi li sort dobro
            const taskStatus = targetTask.taskStatus
            return taskStatus ? taskStatus : 0
        }else{
            return 0
        }
    };

    let reservationRoomInfo = null;
    if (reservation.RoomInfo) {
        reservationRoomInfo = _.find(props.rooms, { id: reservation.RoomInfo.id });
    }

    const total = getReservationPrice(reservation);
    const roomSRCStatusIcon = getRoomSRCStatusIcon(reservationRoomInfo);
    const srcRoomStatus = getRoomSRCStatus(reservationRoomInfo);
    const lastCleaningTime = getLastCleaningTimeForRoom(reservation.roomInfoId);
    const lastCleaningStatus:number = getLastCleaningStatusForRoom(reservation.roomInfoId);

    const changeRoomMode = async (modeToSet: any, remoteDeviceId: any) => {
        if (!remoteDeviceId) {
            throw new Error('Remote id missing');
        }
        setModalContent(
            <div className="container-fluid">
                <div className="text-center">
                    {translate('Are you sure you want to change room mode?')}
                    <hr />
                </div>

                <div className="text-center">
                    <ConfirmButtons
                        onConfirm={async () => {
                            const next24Hours = new Date().getTime() + 24 * 60 * 60 * 1000;
                            const cardKeys = ['guest1', 'guest2', 'guest3'];

                            let payload = {};

                            if (modeToSet === 'PRECOMFORT') {
                                payload = {
                                    code: 1,
                                    validToTimestamp: next24Hours,
                                    cardCodeType: 'guest1',
                                    reservationId: null,
                                };
                                await rest('/src/setCardCodeWithValidToTime/' + remoteDeviceId, 'POST', payload);
                            } else if (modeToSet === 'ECO') {
                                for (let i = 0; i < cardKeys.length; i++) {
                                    payload = {
                                        code: 0,
                                        validToTimestamp: new Date().getTime(),
                                        cardCodeType: cardKeys[i],
                                        reservationId: null,
                                    };
                                    await rest('/src/setCardCodeWithValidToTime/' + remoteDeviceId, 'POST', payload);
                                }
                            }
                            closeModal();
                        }}
                        onCancel={() => {
                            closeModal();
                        }}
                    />
                </div>
            </div>,

            translate('Confirm'), // header
            false, // showCloseButton
            'modal-md'
        );
    };

    const handleShowBasicOptions = (reservation: ReservationModelWithModelsIncluded) => {
        const roomName = reservation.RoomInfo?.name;
        const basicSRCSettings = (
            <BasicSRCSettings
                roomInfoId={reservation.roomInfoId}
                key={_.uniqueId('asd')}
                reservation={reservation}
                remoteDeviceId={reservation?.RoomInfo?.remoteDeviceId}
            />
        );
        setModalContent(
            basicSRCSettings,
            <span>
                {translate('Room settings') + ' - '} <b>{roomName}</b>
            </span>,
            true,
            'modal-xl'
        );
    };

    const getRoomMode = (reservation: ReservationModelWithModelsIncluded) => {
        let roomModeText = '';
        let roomMode = props.activeEventTickets.find((ev: any) => {
            return (
                ev.roomInfoId === reservation.roomInfoId &&
                (ev.ruleName === 'alarm_ecoRoomMode' ||
                    ev.ruleName === 'alarm_comfortRoomMode' ||
                    ev.ruleName === 'alarm_preComfortRoomMode')
            );
        });

        if (roomMode === undefined) {
            roomModeText = 'Unknown';
        } else if (roomMode.ruleName === 'alarm_ecoRoomMode') {
            roomModeText = 'ECO';
        } else if (roomMode.ruleName === 'alarm_comfortRoomMode') {
            roomModeText = 'COMFORT';
        } else if (roomMode.ruleName === 'alarm_preComfortRoomMode') {
            roomModeText = 'PRECOMFORT';
        }
        return roomModeText;
    };
    return (
        <div
            className="custom-list-item"
            style={{ overflow: 'hidden' }}
            onClick={(e) => {
                e.stopPropagation();
                if (printJournal) {
                    return null;
                } else {
                    if (reservationRoute === 'reservations') {
                        setReservationModal(reservation);
                        // history.push(`/reservations/${reservation.id}/edit`);
                    } else {
                        setReservationModal(reservation);
                        // history.push(`/${reservationRoute}/${reservation.roomInfoId}/${reservation.id}/edit`);
                    }
                }
            }}
        >
            <div className="" style={{ justifyContent: 'space-between' }}>
                <div
                    style={{
                        height: '96%',
                        width: '6px',
                        marginRight: '6px',
                        backgroundColor: reservationStatusColorsHEX[reservation.statusEnum],
                    }}
                ></div>
                {reservation.RoomInfo?.name}{' '}
                {[1, 2].includes(srcRoomStatus) ? (
                    <button
                        onClick={(e) => {
                            e.stopPropagation();
                            handleShowBasicOptions(reservation);
                        }}
                        className="btn btn-sm btn-light border m-0 p-0 mr-1"
                    >
                        {roomSRCStatusIcon}
                    </button>
                ) : (
                    <button
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        className="btn btn-sm btn-light border m-0 p-0 cursor-disabled mr-1"
                    >
                        <HardwareIcon></HardwareIcon>
                    </button>
                )}
            </div>
            {/* <div>
               </div>     */}
            <div>{guest}</div>
            <div className="">{adultsNumber}</div>
            <div className="" style={{ position: 'relative' }}>
                <span className="">{moment(reservation.checkInTimestamp).locale(systemLocale).format('ll')}</span>
            </div>
            <div className="" style={{ position: 'relative' }}>
                <span className="">{moment(reservation.checkOutTimestamp).locale(systemLocale).format('ll')}</span>
            </div>
            <div className="">
                {total} {defaultCurrency}
            </div>
            <div className="position-relative">
                {checkoutWarning}
                {guestArivalReminder}
                {checkInWarning}
                {guestArivalWarning}
                {guestDepartureReminder}
            </div>
            <div className="">
                <span className="">{notes}</span>
            </div>

            <div className="">
                <span className="">
                    <div>{translate(getRoomMode(reservation))}</div>
                </span>
            </div>

            <div className="">
                {translate(TaskStatusEnumText[lastCleaningStatus])}
            </div>
            <div>{lastCleaningTime ? lastCleaningTime : translate('Unknown')}</div>
            <div>
                <div className="">{translate(activityStatus[activityStatusProp])}</div>
            </div>

            <div>
                {reservation.statusEnum === ReservationStatus.checkedIn || srcRoomStatus !== 1 ? null : (
                    <div
                        className="w-100"
                        style={{
                            display: 'flex',
                            justifyContent: 'space-around',
                        }}
                    >
                        <div
                            onClick={() => {
                                if (reservation.RoomInfo && getRoomMode(reservation) !== 'ECO')
                                    changeRoomMode('ECO', reservation?.RoomInfo.remoteDeviceId);
                            }}
                            className="button-info"
                        >
                            <i
                                className={
                                    'fa fa-check-circle ' +
                                    (getRoomMode(reservation) === 'ECO'
                                        ? 'text-success fa fa-check-circle'
                                        : 'fa fa-circle-o')
                                }
                            ></i>{' '}
                            <small>ECO</small>
                        </div>
                        <div
                            onClick={() => {
                                if (reservation.RoomInfo && getRoomMode(reservation) !== 'PRECOMFORT')
                                    changeRoomMode('PRECOMFORT', reservation.RoomInfo.remoteDeviceId);
                            }}
                            className="button-info"
                        >
                            <i
                                className={
                                    'fa fa-check-circle ' +
                                    (getRoomMode(reservation) === 'PRECOMFORT'
                                        ? 'text-success fa fa-check-circle'
                                        : 'fa fa-circle-o')
                                }
                            ></i>{' '}
                            <small>PRECOMFORT</small>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

const getReservationPrice = (reservation: ReservationModelWithModelsIncluded) => {
    let total = 0;
    const adultsNumber = reservation.adultsNumber ? reservation.adultsNumber : 1;
    const reservationOfferArr = reservation.rememberedAccommodationPriceInfo;
    const reservationOffer =
        reservationOfferArr && reservationOfferArr.length > 0
            ? reservationOfferArr[reservationOfferArr.length - 1]
            : null;

    const priceOfferIntervals =
        reservationOffer && reservationOffer.priceOfferIntervals ? reservationOffer.priceOfferIntervals : [];
    priceOfferIntervals.forEach((p) => {
        const { fromToDays } = p;
        let adultsNumberPriceIndex = adultsNumber === 0 ? 0 : adultsNumber - 1;
        if (adultsNumber >= p.apRules.length) {
            adultsNumberPriceIndex = p.apRules.length - 1;
        }
        const unitPrice = p.apRules[adultsNumberPriceIndex];
        total += Number(unitPrice) * Number(fromToDays);
    });
    return total;
};

const getWarning = (reservation: ReservationModelWithModelsIncluded, arrivalRoomInfoIds: number[]) => {
    let checkoutWarning: any = '';
    let guestArivalReminder: any = '';
    let checkInWarning: any = '';
    let guestArivalWarning: any = '';
    let guestDepartureReminder: any = '';
    if (reservation.checkOutTimestamp < moment().valueOf()) {
        checkoutWarning = (
            <i
                title={translate('The guests should have been checked out')}
                className="fa fa-sign-out text-danger px-1"
                aria-hidden="true"
            ></i>
        );
    }

    if (reservation.checkInTimestamp < moment().valueOf() && reservation.statusEnum === 1) {
        checkInWarning = (
            <i
                title={translate('The guests should have been checked in')}
                className="fa fa-sign-in text-danger px-1"
                aria-hidden="true"
            ></i>
        );
    }
    if (moment(reservation.checkInTimestamp).isSame(moment(), 'day') && reservation.statusEnum === 1) {
        guestArivalReminder = (
            <i
                title={translate('The arrival of guests is today')}
                className="fa fa-sign-in text-primary px-1"
                aria-hidden="true"
            ></i>
        );
    }

    if (
        moment(reservation.checkOutTimestamp).isSame(moment(), 'day') &&
        reservation.statusEnum === 2 &&
        reservation.checkOutTimestamp > moment().valueOf()
    ) {
        guestDepartureReminder = (
            <i
                title={translate('The departure of guests is today')}
                className="fa fa-sign-out text-secondary px-1"
                aria-hidden="true"
            ></i>
        );
    }

    if (reservation.statusEnum === 2 && arrivalRoomInfoIds.includes(reservation.roomInfoId)) {
        guestArivalWarning = (
            <i
                title={translate('The arrival of guests is today')}
                className="fa fa-sign-in text-primary px-1"
                aria-hidden="true"
            ></i>
        );
    }

    return { checkoutWarning, guestArivalReminder, checkInWarning, guestArivalWarning, guestDepartureReminder };
};

export default branch(
    {
        systemLocale: ['locale'],
        defaultCurrency: ['defaultCurrency'],
        activeEventTickets: ['model', 'RoomEventTicket'],
        // cleaningStatusHeaders: ['monkeys', 'cleaningStatusHeadersWithCleaningStatuses'],
    },
    Journal
);
