import {
    CompanyModel,
    CustomerModel,
    InvoiceItemPosPresenter,
    PaginatedData,
    PosItemModel,
    PosTerminalConsumption,
    PosTerminalPosItemModel,
} from '@common/modelDefinition';
import rest from '@data/restWrapper';
import baobab from '@data/state/index';

const getActivePosTerminalConsumption = () => {
    const posTerminalConsumption = baobab.root.select('state', 'posTerminal', 'activeItemsSelection').get();
    return posTerminalConsumption;
};

// const performBackendCalculation = async () => {
//     const posTerminalId = baobab.root.select('state', 'posTerminal', 'invoice', 'posTerminalId').get();

//     const posTerminalConsumption = getActivePosTerminalConsumption();
//     const { invoice, invoiceItems } = await rest('/getPendingInvoicePosTerminal', 'POST', {
//         posTerminalId: posTerminalId,
//         posTerminalConsumption,
//     });

//     baobab.root.select('state', 'posTerminal', 'invoice').set(invoice);
//     baobab.root.select('state', 'posTerminal', 'invoiceItems').set(invoiceItems);
// };

export const performFrontendCalculation = () => {
    const posTerminalConsumption = getActivePosTerminalConsumption();
    const newInvoice = { ...baobab.root.select('state', 'posTerminal', 'invoice').get() };
    const invoiceItems = posTerminalConsumption.map((c: PosTerminalConsumption) => {
        return {
            posItemId: c.posItemId,
            itemName: c.itemName,
            quantity: Number(c.quantity),
            bruttoUnitPrice: Number(c.newPrice),
            bruttoUnitsValue: Number(c.quantity) * Number(c.newPrice),
            unitPrice: Number(c.unitPrice),
        };
    });

    let total = 0;
    invoiceItems.forEach((i: InvoiceItemPosPresenter) => {
        total = total + i.bruttoUnitsValue;
    });
    newInvoice.totalBaseValue = total;
    newInvoice.total = total;
    baobab.root.select('state', 'posTerminal', 'invoice').set(newInvoice);
    baobab.root.select('state', 'posTerminal', 'invoiceItems').set(invoiceItems);
};

const loadPendingInvoice = async (posTerminalId: number, resId?: number) => {
    voidInvoice();
    //poziva konstruktor i ostalo
    const { invoice, previousInvoices } = await rest('/getPendingInvoicePosTerminal', 'POST', {
        posTerminalId: posTerminalId,
        reservationId: resId,
    });
    baobab.root.select('state', 'posTerminal', 'invoice').set(invoice);
    // baobab.root.select('state', 'posTerminal', 'invoiceItems').set(invoiceItems);
    baobab.root.select('state', 'posTerminal', 'previousInvoices').set(previousInvoices);
    if (!resId) {
        clearInvoiceProperties();
    }
    //invoiceItems
};

const addInvoiceItem = async (posItem: PosItemModel, quantity?: number) => {
    const { id, price, type, name } = posItem;
    const posTerminalConsumption: PosTerminalConsumption[] = [
        ...baobab.root.select('state', 'posTerminal', 'activeItemsSelection').get(),
    ];
    const invoiceItems: InvoiceItemPosPresenter[] = baobab.root.select('state', 'posTerminal', 'invoiceItems').get();
    const loadedItem = invoiceItems.find((i) => i.unitPrice === price && i.posItemId === id);
    if (loadedItem === undefined && id) {
        const newItem: PosTerminalConsumption = {
            posItemId: id,
            quantity: quantity ? quantity : 1,
            newPrice: price ? price : 0,
            type: type ? type : 'posTerminal',
            itemName: name,
            unitPrice: price ? price : 0,
            discountPercentage: 0
        };
        posTerminalConsumption.push(newItem);
        baobab.root.select('state', 'posTerminal', 'activeItemsSelection').set(posTerminalConsumption);
    } else {
        const quantity_ = quantity ? Number(quantity) : 1;
        const newQuantity = Number(loadedItem?.quantity) + quantity_;
        const newPosTerminalConsumption = posTerminalConsumption.map((p) => {
            if (Number(p.newPrice) === loadedItem?.unitPrice && p.posItemId === loadedItem.posItemId) {
                const editedPosItemConsumption = { ...p };
                editedPosItemConsumption.quantity = newQuantity;
                return editedPosItemConsumption;
            } else {
                return p;
            }
        });
        baobab.root.select('state', 'posTerminal', 'activeItemsSelection').set(newPosTerminalConsumption);
    }
    performFrontendCalculation();
};

const substractInvoiceItem = (invoiceItem: InvoiceItemPosPresenter) => {
    const posTerminalConsumption = baobab.root.select('state', 'posTerminal', 'activeItemsSelection').get();
    const targetConsumption = posTerminalConsumption.find(
        (p: PosTerminalConsumption) => Number(p.newPrice) === invoiceItem.unitPrice && p.posItemId === invoiceItem.posItemId
    );

    if (targetConsumption.quantity <= 1) {
        //ukloni ga iz arraya
        const newPosTerminalConsumption = posTerminalConsumption.filter((p: PosTerminalConsumption) => {
            if (Number(p.newPrice) === invoiceItem.unitPrice && p.posItemId === invoiceItem.posItemId) {
                return null;
            } else {
                return p;
            }
        });

        baobab.root.select('state', 'posTerminal', 'activeItemsSelection').set(newPosTerminalConsumption);
    } else {
        const newQuantity = (invoiceItem.quantity ? invoiceItem.quantity : 0) - 1;
        const newPosTerminalConsumption = posTerminalConsumption.map((p: PosTerminalConsumption) => {
            if (Number(p.newPrice) === invoiceItem.unitPrice && p.posItemId === invoiceItem.posItemId) {
                const editedPosItemConsumption = { ...p };
                editedPosItemConsumption.quantity = newQuantity;
                return editedPosItemConsumption;
            } else {
                return p;
            }
        });
        baobab.root.select('state', 'posTerminal', 'activeItemsSelection').set(newPosTerminalConsumption);
    }

    performFrontendCalculation();
};

const invoiceItemAddition = (invoiceItem: InvoiceItemPosPresenter) => {
    const posTerminalConsumption = baobab.root.select('state', 'posTerminal', 'activeItemsSelection').get();
    const newQuantity = (invoiceItem.quantity ? invoiceItem.quantity : 0) + 1;
    const newPosTerminalConsumption = posTerminalConsumption.map((p: PosTerminalConsumption) => {
        if (Number(p.newPrice) === invoiceItem.unitPrice && p.posItemId === invoiceItem.posItemId) {
            const editedPosItemConsumption = { ...p };
            editedPosItemConsumption.quantity = newQuantity;
            return editedPosItemConsumption;
        } else {
            return p;
        }
    });
    baobab.root.select('state', 'posTerminal', 'activeItemsSelection').set(newPosTerminalConsumption);
    performFrontendCalculation();
};

const removeInvoiceItem = (invoiceItem: InvoiceItemPosPresenter) => {
    const posTerminalConsumption = baobab.root.select('state', 'posTerminal', 'activeItemsSelection').get();

    const newPosTerminalConsumption = posTerminalConsumption.filter((p: PosTerminalConsumption) => {
        if (Number(p.newPrice) === invoiceItem.unitPrice && p.posItemId === invoiceItem.posItemId) {
            return null;
        } else {
            return p;
        }
    });

    baobab.root.select('state', 'posTerminal', 'activeItemsSelection').set(newPosTerminalConsumption);

    performFrontendCalculation();
};

const setPointOfSaleInputs = async (value: string | number | boolean | null | undefined, type: string) => {
    const invoiceCursor = baobab.root.select('state', 'posTerminal', 'invoice');
    invoiceCursor.set([type], value);
};

const setCustomerInputs = async (customer: CustomerModel | null) => {
    const invoiceCursor = baobab.root.select('state', 'posTerminal', 'invoice');
    if (customer) {
        const customerInfo = getCustomerInfo(customer);
        invoiceCursor.set('customerId', customer.id);
        invoiceCursor.set('customerInfo', customerInfo);
    } else {
        invoiceCursor.set('customerId', null);
        invoiceCursor.set('customerInfo', null);
    }
};

const setCompanyInputs = async (company: CompanyModel | null) => {
    const invoiceCursor = baobab.root.select('state', 'posTerminal', 'invoice');
    if (company) {
        const companyInfo = getCompanyInfo(company);
        invoiceCursor.set('companyId', company.id);
        invoiceCursor.set('companyInfo', companyInfo);
    } else {
        invoiceCursor.set('companyId', null);
        invoiceCursor.set('companyInfo', null);
    }
};

const getCompanyInfo = (company: CompanyModel) => {
    if (company) {
        const { idNumber, oib, name, address, poNumber, town, country, taxFree } = company;
        const newObj = { idNumber, oib, name, address, poNumber, town, country, type: 'company', taxFree };
        return JSON.stringify(newObj);
    } else {
        return null;
    }
};

const getCustomerInfo = (customer: CustomerModel) => {
    if (customer) {
        const { documentId, firstName, lastName, address, poNumber, town, country } = customer;
        const newObj = { documentId, firstName, lastName, address, poNumber, town, country, type: 'customer' };
        return JSON.stringify(newObj);
    } else {
        return null;
    }
};

const setInvoiceType = async (invoiceType: number) => {
    const invoiceCursor = baobab.root.select('state', 'posTerminal', 'invoice');
    const editedInvoice = invoiceCursor.get();
    invoiceCursor.set('invoiceType', invoiceType);
    const typeOfPayment =
        invoiceType !== 2 && editedInvoice.typeOfPayment === null
            ? 'G'
            : invoiceType === 2 && editedInvoice.typeOfPayment !== null
            ? null
            : editedInvoice.typeOfPayment;
    invoiceCursor.set('typeOfPayment', typeOfPayment);
};

const issueInvoice = async (reservationId: number | null | undefined) => {
    const posTerminalId = baobab.root.select('state', 'posTerminal', 'invoice', 'posTerminalId').get();
    const invoice = baobab.root.select('state', 'posTerminal', 'invoice').get();
    const posTerminalConsumption = getActivePosTerminalConsumption();
    await rest('/issuePosTerminalInvoice', 'POST', {
        posTerminalId: posTerminalId,
        posTerminalConsumption: posTerminalConsumption,
        reservationId,
        invoice: invoice,
    });
    voidInvoice();
};

const voidInvoice = async () => {
    //izbriši sve sa //activeItemsSelection:{} što i obnovi rekalkulaciju računa, što će za rezultat imati praznu desnu stranu
    baobab.root.select('state', 'posTerminal', 'activeItemsSelection').set([]);
    baobab.root.select('state', 'posTerminal', 'filteredItems').set(null);
    baobab.root.select('state', 'posTerminal', 'invoiceItems').set([]);
    baobab.root.select('state', 'posTerminal', 'invoice').set({});
};

const setCategory = (category: string | null, posItems:PosItemModel[]) => {
    if (category === null) {
        baobab.root.select('state', 'posTerminal', 'filteredItems').set(null);
    } else {
        
      const _posItems = posItems.filter((i: PosItemModel) => i.enabled);
        const filtered: PosItemModel[] = [];
        _posItems.forEach((p: PosItemModel) => {
            const tag = p.category ? p.category.split(';') : [];
            tag.forEach((t) => {
                if (t === category) {
                    filtered.push(p);
                }
            });
        });
        baobab.root.select('state', 'posTerminal', 'filteredItems').set(filtered);
    }
};

const attachItemsToRes = async (reservationId: number) => {
    const invoice = { ...baobab.root.select('state', 'posTerminal', 'invoice').get() };
    invoice.invoiceType = 2;
    const posTerminalId = baobab.root.select('state', 'posTerminal', 'invoice', 'posTerminalId').get();
    const posTerminalConsumption = getActivePosTerminalConsumption();
    await rest('/issuePosTerminalInvoice', 'POST', {
        posTerminalId: posTerminalId,
        posTerminalConsumption: posTerminalConsumption,
        reservationId,
        invoice,
    });
    voidInvoice();
};

const loadGroupReservationsAndInvoices = async () => {
    const allGroups = [];
    const groupReservations = await rest(`/getGroupReservations`, 'POST', {
        statuses: [1],
        short: true,
    });
    const groupInvoices = await rest(`/getGroupInvoices`, 'POST', {
        statuses: [1],
        short: true,
    });
    for (const groupReservation of groupReservations) {
        allGroups.push(groupReservation);
    }
    for (const groupInvoice of groupInvoices) {
        allGroups.push(groupInvoice);
    }
    return allGroups;
};

const clearInvoiceProperties = async () => {
    const invoiceCursor = baobab.root.select('state', 'posTerminal', 'invoice');
    invoiceCursor.set(['invoiceType'], 0);
    invoiceCursor.set(['customerId'], null);
    invoiceCursor.set(['customerInfo'], null);
    invoiceCursor.set(['companyId'], null);
    invoiceCursor.set(['companyInfo'], null);
    invoiceCursor.set(['reservationId'], null);
    invoiceCursor.set(['groupUuid'], null);
    invoiceCursor.set(['typeOfPayment'], 'G');
};

const getPosTerminalPosItems = async (posTerminalId: number, dontIncludePosItem?: boolean) => {
    const terminalsPosItems = dontIncludePosItem
        ? await rest('/getPosTerminalPosItems/' + posTerminalId + '?dontIncludePosItem=true')
        : await rest('/getPosTerminalPosItems/' + posTerminalId);
    return terminalsPosItems;
};

const getPosItems = async (): Promise<PaginatedData> => {
    return await rest('/getPosItems', 'GET');
};

const attachPosItemToTerminal = async (posItem: PosItemModel, posTerminalId: number) => {
    const attachPosItem = await rest('/attachPosItemToTerminal', 'POST', {
        posItem: posItem,
        posTerminalId: posTerminalId,
    });
    return attachPosItem;
};

const filterPosTerminalPosItems = (
    posItems: PosItemModel[],
    posTerminalPosItems: PosTerminalPosItemModel[],
    posTerminalId: number
) => {
    const filteredPosItems = posItems?.map((p) => {
        const validPosItem = posTerminalPosItems.find((po) => {
            if (
                Number(po.posTerminalId) === Number(posTerminalId) &&
                Number(p.id) === Number(po.posItemId) &&
                p.enabled
            ) {
                return po;
            } else {
                return null;
            }
        });

        if (validPosItem) {
            const newPosItem = { ...p };
            newPosItem.price = Number(validPosItem?.price);
            return newPosItem;
        } else {
            return null;
        }
    });

    const filteredPosItemsWithoutNull: PosItemModel[] = [];
    filteredPosItems.forEach((p) => {
        if (p) {
            filteredPosItemsWithoutNull.push(p);
        }
    });

    return filteredPosItemsWithoutNull;
};

export {
    loadPendingInvoice,
    addInvoiceItem,
    substractInvoiceItem,
    setPointOfSaleInputs,
    issueInvoice,
    setCategory,
    voidInvoice,
    attachItemsToRes,
    // savePosItemPrice,
    setInvoiceType,
    loadGroupReservationsAndInvoices,
    getPosTerminalPosItems,
    filterPosTerminalPosItems,
    removeInvoiceItem,
    setCustomerInputs,
    setCompanyInputs,
    attachPosItemToTerminal,
    invoiceItemAddition,
    getPosItems,
};
