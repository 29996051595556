import {
    CellsWidth,
    EntryCalcHeadersWithItems,
    EntryCalculationItemExtended,
    EntryCalculationItemModel,
    HiddenCols,
    PriceChangeLogHeaderWithItems,
    PriceChangeLogItemModel,
    PriceChangeLogItemsExtended,
    SheetChange,
    StockDocHeadersWithItems,
    StockDocItemModel,
    StockDocItemsExtended,
    StockDocumentTypesEnum,
    StockHeaderEnums,
    StockKeepingUnitEditModel,
    StockKeepingUnitModel,
    TaxModel,
} from '@common/modelDefinition';
import translate from '@data/translations';
import { toast } from 'react-toastify';
import { getPrevOrNextDocumentOrEntryCalc, saveGeneralSettings } from './api';
//@ts-ignore
import _ from 'lodash';

interface StockControllerInterface {
    checkValidationInputs(objToValidate: any, getValidationInputs: Function): void;
    markInvalidInputRefs(validationResults: any): void;
    //zajednicke funkcije za dokumente i kalkulaciju
    getInputHeaderId(SelectedHeadersEnums: StockHeaderEnums, input: string): number;
    getSelectedHeaders(hiddenCols: string[] | null, type: string, SelectedHeadersEnums: StockHeaderEnums): number[];
    remakeHeaders(
        selectedHeaders: number[],
        SelectedHeadersEnums: StockHeaderEnums,
        CellsWitdh: CellsWidth,
        setCellWidth: Function,
        setHeaders: Function,
        setHeadersEnums: Function
    ): void;
    selectCells(value: number, selectedHeaders: number[]): number[];
    saveHiddenCols(
        SelectedHeadersEnums: StockHeaderEnums,
        selectedHeaders: number[],
        type: number | string | null,
        hiddenCols: HiddenCols,
        document: boolean
    ): Promise<void>;
    pageChange(
        history: History,
        type: string,
        stockDocument: StockDocHeadersWithItems | null,
        entryCalc: EntryCalcHeadersWithItems | null
    ): Promise<void>;
    changeCodeOrBarcodeCell(
        data: any,
        change: SheetChange,
        HeadersEnums: StockHeaderEnums,
        document: boolean
    ): Promise<any[]>;
    //za dokumente
    makeStockDocItemModel(
        data: any,
        taxes: TaxModel[],
        _HeaderEnums: StockHeaderEnums,
        selectedSkuItem: StockKeepingUnitModel
    ): StockDocItemModel;
    makeDataForTableForDocuments(
        stockDocItems: StockDocItemsExtended[],
        taxes: TaxModel[],
        setData: Function,
        type: number
    ): void;
    findTaxId(taxName: string, taxes: TaxModel[]): TaxModel | null;
    getTaxForCell(
        change: SheetChange,
        SelectedHeadersEnums: StockHeaderEnums,
        taxes: TaxModel[],
        taxCell: string
    ): TaxModel | null;
    returnEditStockDocItemValue(
        change: SheetChange,
        SelectedHeadersEnums: StockHeaderEnums,
        taxes: TaxModel[]
    ): number | string | null;
    getReadOnlyDocProps(SelectedHeadersEnums: StockHeaderEnums): number[];
    //za kalkulaciju
    makeEntryCalcItemModel(data: any): EntryCalculationItemModel;
    getReadOnlyProps(SelectedHeadersEnums: StockHeaderEnums): number[];
    makeDataForTableForCalculation(
        entryCalcItems: EntryCalculationItemExtended[],
        setTotalItemsEntryPrice: Function,
        setData: Function
    ): void;
    recalculateEditedItems(
        data: any,
        editedObj: EntryCalculationItemModel,
        SelectedHeadersEnums: StockHeaderEnums
    ): EntryCalculationItemModel;
    haveCurrencyorPercentageIndexCalculation(SelectedHeadersEnums: StockHeaderEnums): {
        haveCurrencyIndex: number[];
        havePercentageIndex: number[];
    };
    //on change
    onChangeDocumentCells(
        changes: SheetChange[],
        data: any[],
        stockDocItems: StockDocItemsExtended[],
        SelectedHeadersEnums: StockHeaderEnums,
        taxes: TaxModel[],
        _HeaderEnums: StockHeaderEnums,
        selectValue?: string | number
    ): Promise<any>;
    onChangeCalculationCell(
        changes: SheetChange[],
        data: any[],
        entryCalcItems: EntryCalculationItemExtended[],
        SelectedHeadersEnums: StockHeaderEnums,
        selectValue?: number
    ): Promise<any>;
}

class StockController implements StockControllerInterface {
    checkValidationInputs(objToValidate: any, getValidationInputs: Function) {
        let validationResults = getValidationInputs(objToValidate);
        this.markInvalidInputRefs(validationResults);
    }

    markInvalidInputRefs(validationResults: any) {
        //generic
        const { invalidInputs, validInputs } = validationResults;
        invalidInputs.forEach((input: any) => {
            if (input && input.current) {
                let classes = input.current.className ? input.current.className?.split(' ') : [];
                if (!classes?.includes('is-invalid')) {
                    classes.push('is-invalid');
                }
                input.current.className = classes.join(' ');
            }
        });
        validInputs.forEach((input: any) => {
            if (input && input.current) {
                let classes = input.current.className ? input.current.className?.split(' ') : [];
                if (classes?.includes('is-invalid')) {
                    _.remove(classes, (i: any) => i === 'is-invalid');
                }
                input.current.className = classes.join(' ');
            }
        });
    }

    getInputHeaderId(SelectedHeadersEnums: StockHeaderEnums, input: string): number {
        return Number(Object.keys(SelectedHeadersEnums).find((k: string) => SelectedHeadersEnums[Number(k)] === input));
    }

    getSelectedHeaders(hiddenCols: string[] | null, type: string, SelectedHeadersEnums: StockHeaderEnums): number[] {
        const selectedHeaders: any[] = hiddenCols
            ? Object.keys(SelectedHeadersEnums)
                  .map((h: string) => (!hiddenCols?.includes(SelectedHeadersEnums[Number(h)]) ? Number(h) : null))
                  .filter((h) => h !== null)
            : Object.keys(SelectedHeadersEnums)
                  .map((h) => {
                      if (type === 'documents') {
                          const hidden = [
                              'id',
                              'taxId2',
                              'taxId3',
                              'skuId',
                              'internalSkuCode',
                              'internalSkuBarcode',
                              'skuItemName',
                          ];
                          if (hidden?.includes(SelectedHeadersEnums[Number(h)])) {
                              return null;
                          } else {
                              return Number(h);
                          }
                      } else if (type === 'stockReport') {
                          if (
                              SelectedHeadersEnums[Number(h)] !== 'skuId' &&
                              SelectedHeadersEnums[Number(h)] !== 'barcode' &&
                              SelectedHeadersEnums[Number(h)] !== 'code' &&
                              SelectedHeadersEnums[Number(h)] !== 'stockDocItemId' &&
                              SelectedHeadersEnums[Number(h)] !== 'taxId1'
                          ) {
                              return Number(h);
                          } else {
                              return null;
                          }
                      } else if (type === 'calculation') {
                          const hidden = [
                              'id',
                              'stockMeasureUnit',
                              'entryVATInfo',
                              'tax1Total',
                              'tax2Total',
                              'tax3Total',
                              'skuId',
                              'barcode',
                              'code',
                              'name',
                          ];
                          if (hidden?.includes(SelectedHeadersEnums[Number(h)])) {
                              return null;
                          } else {
                              return Number(h);
                          }
                      } else {
                          return Number(h);
                      }
                  })
                  .filter((h) => h !== null);

        return selectedHeaders;
    }

    remakeHeaders(
        selectedHeaders: number[],
        SelectedHeadersEnums: StockHeaderEnums,
        CellsWitdh: CellsWidth,
        setCellWidth: Function,
        setHeaders: Function,
        setHeadersEnums: Function
    ): void {
        const _cellsWidth = Object.keys(CellsWitdh)
            .map((k: string) => (selectedHeaders.includes(Number(k)) ? CellsWitdh[Number(k)] : null))
            .filter((k) => k !== null);
        setCellWidth([..._cellsWidth]);

        const _headers = Object.keys(SelectedHeadersEnums)
            .map((h: string) => (selectedHeaders.includes(Number(h)) ? SelectedHeadersEnums[Number(h)] : null))
            .filter((h) => h !== null);
        setHeaders(_headers);

        const hEnums: any = {};
        _headers.forEach((h, index) => {
            hEnums[Number(index)] = h;
        });
        setHeadersEnums({ ...hEnums });
    }

    selectCells(value: number, selectedHeaders: number[]): number[] {
        let _selectedHeaders = [...selectedHeaders];
        if (_selectedHeaders.includes(value)) {
            _selectedHeaders = _selectedHeaders.filter((s) => s !== value);
        } else {
            _selectedHeaders.push(value);
        }
        return _selectedHeaders;
    }

    async saveHiddenCols(
        SelectedHeadersEnums: StockHeaderEnums,
        selectedHeaders: number[],
        type: number | string | null,
        hiddenCols: HiddenCols,
        document: boolean
    ): Promise<void> {
        const unSelectedHeaders: string[] = [];
        Object.keys(SelectedHeadersEnums).forEach((h: string) => {
            if (!selectedHeaders.includes(Number(h))) {
                unSelectedHeaders.push(SelectedHeadersEnums[Number(h)]);
            }
        });
        try {
            const _hiddenDocCol = hiddenCols ? { ...hiddenCols } : {};
            if (type && typeof type === 'number' && document) {
                const docType = StockDocumentTypesEnum[type];
                _hiddenDocCol[docType] = unSelectedHeaders;
            } else if (type === null && document) {
                //ovdje ne bi trebao nikad uci
                _hiddenDocCol['stockDocuments'] = unSelectedHeaders;
            } else if (type && !document) {
                _hiddenDocCol[type] = unSelectedHeaders;
            }
            const params = JSON.stringify(_hiddenDocCol);
            const obj = {
                key: 'hiddenDocumentColumns',
                params: params,
            };
            await saveGeneralSettings(obj);
        } catch (error: any) {
            toast(error.message, { type: toast.TYPE.ERROR });
        }
    }

    async pageChange(
        history: any,
        type: string,
        stockDocument: StockDocHeadersWithItems | null,
        entryCalc: EntryCalcHeadersWithItems | null
    ) {
        if (stockDocument?.id) {
            const prevOrNextDoc = await getPrevOrNextDocumentOrEntryCalc(type, stockDocument.id, true);
            if (Number(prevOrNextDoc)) {
                history.push('/goodsManagement/documents/edit/' + prevOrNextDoc);
            }
        } else if (entryCalc?.id) {
            const prevOrNextCalc = await getPrevOrNextDocumentOrEntryCalc(type, entryCalc.id);
            if (Number(prevOrNextCalc)) {
                history.push('/goodsManagement/entryCalc/edit/' + prevOrNextCalc);
            }
        }
    }

    changeCodeOrBarcodeCell(
        data: any,
        change: SheetChange,
        HeadersEnums: StockHeaderEnums,
        document: boolean
    ): Promise<any[]> {
        const newData = [...data];
        if (change?.skuItem?.active) {
            newData[change.y][this.getInputHeaderId(HeadersEnums, 'skuId')] = change?.skuItem.id;
            newData[change.y][this.getInputHeaderId(HeadersEnums, 'skuItem')] =
                change?.skuItem.name + (change?.skuItem?.code ? ' (' + change.skuItem.code + ')' : '');
            if (document) {
                newData[change.y][this.getInputHeaderId(HeadersEnums, 'stockMeasureUnit')] =
                    change?.skuItem.stockMeasureUnit;
                newData[change.y][this.getInputHeaderId(HeadersEnums, 'skuPrice')] = change?.skuItem.salesPrice;
            }
            return newData[change.y];
        } else {
            throw new Error(translate('Item is not active!'));
        }
    }

    // ZA DOKUMENTE

    makeStockDocItemModel(
        data: any,
        taxes: TaxModel[],
        _HeaderEnums: StockHeaderEnums,
        selectedSkuItem: StockKeepingUnitModel
    ): StockDocItemModel {
        const taxId1 = this.findTaxId(data[this.getInputHeaderId(_HeaderEnums, 'taxId1')], taxes);
        const taxId2 = this.findTaxId(data[this.getInputHeaderId(_HeaderEnums, 'taxId2')], taxes);
        const taxId3 = this.findTaxId(data[this.getInputHeaderId(_HeaderEnums, 'taxId3')], taxes);

        const stockDocItem: StockDocItemModel = {
            skuId: data[this.getInputHeaderId(_HeaderEnums, 'skuId')],
            quantity: Number(data[this.getInputHeaderId(_HeaderEnums, 'stockUnitQuantity')])
                ? Number(data[this.getInputHeaderId(_HeaderEnums, 'stockUnitQuantity')])
                : 0,
            purchasePrice: Number(data[this.getInputHeaderId(_HeaderEnums, 'purchaseUnitPrice')])
                ? data[this.getInputHeaderId(_HeaderEnums, 'purchaseUnitPrice')]
                : 0,
            taxId1: taxId1?.id ? taxId1.id : null,
            taxId2: taxId2?.id ? taxId2.id : null,
            taxId3: taxId3?.id ? taxId3.id : null,
            skuPrice: Number(data[this.getInputHeaderId(_HeaderEnums, 'skuPrice')])
                ? Number(data[this.getInputHeaderId(_HeaderEnums, 'skuPrice')])
                : 0,
            skuName: selectedSkuItem?.name ? selectedSkuItem?.name : '',
            lockedItem: false,
        };
        return stockDocItem;
    }

    makeStockDocItemModelBySelectedSkuItems(selectedSkuItem: StockKeepingUnitEditModel): StockDocItemModel {
        const stockDocItem: StockDocItemModel = {
            skuId: selectedSkuItem?.id,
            quantity: 0,
            purchasePrice: 0,
            taxId1: selectedSkuItem?.taxId1,
            taxId2: selectedSkuItem?.taxId2,
            taxId3: selectedSkuItem?.taxId3,
            skuPrice: selectedSkuItem?.salesPrice,
            skuName: selectedSkuItem?.name ? selectedSkuItem?.name : '',
            lockedItem: false,
            parentId: selectedSkuItem?.parentId,
        };
        return stockDocItem;
    }

    makeDataForTableForDocuments(
        stockDocItems: StockDocItemsExtended[],
        taxes: TaxModel[],
        setData: Function,
        type: number
    ) {
        const _data: any = [];
        stockDocItems.forEach((r: StockDocItemsExtended) => {
            const taxId1 = taxes?.find((t) => t.id === r.taxId1);
            const taxId2 = taxes?.find((t) => t.id === r.taxId2);
            const taxId3 = taxes?.find((t) => t.id === r.taxId3);

            let row = [];
            row.push(r.id);
            row.push(
                r.StockKeepingUnit?.name
                    ? r.StockKeepingUnit?.name +
                          (r.StockKeepingUnit?.code ? ' (' + r.StockKeepingUnit?.code + ')' : '') +
                          (r.StockKeepingUnit?.stockMeasureUnit ? ' ' + r.StockKeepingUnit?.stockMeasureUnit : '')
                    : '' + (r.StockKeepingUnit?.code ? ' (' + r.StockKeepingUnit?.code + ')' : '')
            );

            if (type === StockDocumentTypesEnum.goodsReceivingNote) {
                row.push(r.StockKeepingUnit?.stockMeasureUnit ? r.StockKeepingUnit?.stockMeasureUnit : '');
                row.push(r.StockKeepingUnit?.purchaseMeasureUnit ? r.StockKeepingUnit?.purchaseMeasureUnit : '');
                row.push(
                    r.StockKeepingUnit?.purchaseMeasureUnit_stockUnitMultiplier
                        ? Number(r.StockKeepingUnit?.purchaseMeasureUnit_stockUnitMultiplier).toFixed(2)
                        : 0
                );
                row.push(r?.StockKeepingUnit?.salesPrice ? Number(r.StockKeepingUnit?.salesPrice).toFixed(2) : '');
                row.push(r.quantity ? r.quantity : 0);
                row.push(
                    r.StockKeepingUnit?.purchaseMeasureUnit &&
                        r.StockKeepingUnit?.purchaseMeasureUnit_stockUnitMultiplier
                        ? Number(r.quantity / r.StockKeepingUnit?.purchaseMeasureUnit_stockUnitMultiplier).toFixed(2)
                        : 0
                );
                row.push(r.purchasePrice ? Number(r.purchasePrice).toFixed(2) : 0);
                row.push(
                    (r.quantity ? Number(r.quantity) : 0) *
                        (r.purchasePrice ? Number(Number(r.purchasePrice).toFixed(2)) : 0)
                );
                row.push(taxId1?.name ? taxId1.name : '');
                row.push(taxId2?.name ? taxId2.name : '');
                row.push(taxId3?.name ? taxId3.name : '');
                row.push(r.skuId);
            } else if (type === StockDocumentTypesEnum.goodsDespatchNote) {
                row.push(r?.StockKeepingUnit?.salesPrice ? Number(r.StockKeepingUnit?.salesPrice).toFixed(2) : '');
                row.push(r.quantity ? r.quantity : 0);
                row.push(taxId1?.name ? taxId1.name : '');
                row.push(taxId2?.name ? taxId2.name : '');
                row.push(taxId3?.name ? taxId3.name : '');
                row.push(r.skuId);
            } else if (
                type === StockDocumentTypesEnum.writeOffGoods ||
                type === StockDocumentTypesEnum.offerNote ||
                type === StockDocumentTypesEnum.invoicedStockNote ||
                (type === StockDocumentTypesEnum.goodsReceivingNote && r.invoiceItemId)
            ) {
                row.push(r?.StockKeepingUnit?.salesPrice ? Number(r.StockKeepingUnit?.salesPrice).toFixed(2) : '');
            } else if (type === StockDocumentTypesEnum.procurementOrder) {
                row.push(r.externalCode ? r.externalCode : '');
                row.push(r.purchasePrice ? Number(r.purchasePrice).toFixed(4) : '');
            }

            if (
                type === StockDocumentTypesEnum.writeOffGoods ||
                type === StockDocumentTypesEnum.offerNote  ||
                type === StockDocumentTypesEnum.invoicedStockNote ||
                type === StockDocumentTypesEnum.interWarehouseTransfer ||
                type === StockDocumentTypesEnum.procurementOrder ||
                (type === StockDocumentTypesEnum.goodsReceivingNote && r.invoiceItemId)
            ) {
                row.push(r.quantity ? r.quantity : 0);
                row.push(r.skuId);
            }

            _data.push(row);
        });
        setData([..._data]);
    }

    findTaxId(taxName: string, taxes: TaxModel[]): TaxModel | null {
        const tax = taxName && taxName !== '' ? taxes?.find((t) => t.name === taxName) : null;
        return tax ? tax : null;
    }

    getTaxForCell(
        change: SheetChange,
        SelectedHeadersEnums: StockHeaderEnums,
        taxes: TaxModel[],
        taxCell: string
    ): TaxModel | null {
        const tax =
            taxCell === 'taxId1' || taxCell === 'taxId2' || taxCell === 'taxId3'
                ? Number(change.x) ===
                      Number(
                          this.getInputHeaderId(
                              SelectedHeadersEnums,
                              taxCell === 'taxId1' ? 'taxId1' : taxCell === 'taxId2' ? 'taxId2' : 'taxId3'
                          )
                      ) && typeof change.value === 'string'
                    ? this.findTaxId(change.value, taxes)
                        ? this.findTaxId(change.value, taxes)
                        : null
                    : null
                : null;
        return tax;
    }

    returnEditStockDocItemValue(
        change: SheetChange,
        SelectedHeadersEnums: StockHeaderEnums,
        taxes: TaxModel[]
    ): number | string | null {
        const tax1 = this.getTaxForCell(change, SelectedHeadersEnums, taxes, 'taxId1');
        const tax2 = this.getTaxForCell(change, SelectedHeadersEnums, taxes, 'taxId2');
        const tax3 = this.getTaxForCell(change, SelectedHeadersEnums, taxes, 'taxId3');

        const itemValue =
            Number(change.x) === Number(this.getInputHeaderId(SelectedHeadersEnums, 'stockUnitQuantity'))
                ? Number(change.value)
                : Number(change.x) === Number(this.getInputHeaderId(SelectedHeadersEnums, 'taxId1'))
                ? tax1?.id
                    ? tax1.id
                    : null
                : Number(change.x) === Number(this.getInputHeaderId(SelectedHeadersEnums, 'taxId2'))
                ? tax2?.id
                    ? tax2.id
                    : null
                : Number(change.x) === Number(this.getInputHeaderId(SelectedHeadersEnums, 'taxId3'))
                ? tax3?.id
                    ? tax3.id
                    : null
                : change.value;
        return itemValue;
    }

    getReadOnlyDocProps(SelectedHeadersEnums: StockHeaderEnums) {
        const readOnlyArray = [
            this.getInputHeaderId(SelectedHeadersEnums, 'id'),
            this.getInputHeaderId(SelectedHeadersEnums, 'skuPrice'),
            this.getInputHeaderId(SelectedHeadersEnums, 'skuId'),
            this.getInputHeaderId(SelectedHeadersEnums, 'stockMeasureUnit'),
            this.getInputHeaderId(SelectedHeadersEnums, 'purchaseMeasureUnit'),
            this.getInputHeaderId(SelectedHeadersEnums, 'purchaseMeasureUnit_stockUnitMultiplier'),
            this.getInputHeaderId(SelectedHeadersEnums, 'purchaseValueStockUnit'),
            this.getInputHeaderId(SelectedHeadersEnums, 'externalSkuCode'),
            this.getInputHeaderId(SelectedHeadersEnums, 'taxId1'),
            this.getInputHeaderId(SelectedHeadersEnums, 'taxId2'),
            this.getInputHeaderId(SelectedHeadersEnums, 'taxId3'),
        ];
        return readOnlyArray;
    }

    // ZA ULAZNU KALKULACIJU

    makeEntryCalcItemModel(
        data: any,
        skuItem?: StockKeepingUnitModel,
        entryCalcHeaderId?: number
    ): EntryCalculationItemModel {
        const entryCalcItem: EntryCalculationItemModel = {
            barcode: skuItem ? skuItem.barcode : data[this.getInputHeaderId(CalculationHeadersEnums, 'barcode')],
            code: skuItem ? skuItem.code : data[this.getInputHeaderId(CalculationHeadersEnums, 'code')],
            name: skuItem ? skuItem.name : data[this.getInputHeaderId(CalculationHeadersEnums, 'name')],
            skuPrice: skuItem
                ? skuItem.salesPrice
                : data[this.getInputHeaderId(CalculationHeadersEnums, 'currentSkuPrice')],
            quantity: skuItem ? 0 : data[this.getInputHeaderId(CalculationHeadersEnums, 'quantity')],
            entryUnitPrice: skuItem ? 0 : data[this.getInputHeaderId(CalculationHeadersEnums, 'entryUnitPrice')],
            entryPrice: skuItem ? 0 : data[this.getInputHeaderId(CalculationHeadersEnums, 'entryPrice')],
            entryVAT: skuItem ? 0 : data[this.getInputHeaderId(CalculationHeadersEnums, 'entryVAT')],
            entryVATInfo: skuItem ? 0 : data[this.getInputHeaderId(CalculationHeadersEnums, 'entryVATInfo')],
            transportation: skuItem ? 0 : data[this.getInputHeaderId(CalculationHeadersEnums, 'transportation')],
            shipping: skuItem ? 0 : data[this.getInputHeaderId(CalculationHeadersEnums, 'shipping')],
            customs: skuItem ? 0 : data[this.getInputHeaderId(CalculationHeadersEnums, 'customs')],
            otherCosts: skuItem ? 0 : data[this.getInputHeaderId(CalculationHeadersEnums, 'otherCosts')],
            grossMarginPercentage: skuItem
                ? 0
                : data[this.getInputHeaderId(CalculationHeadersEnums, 'grossMarginPercentage')],
            grossMarginUnitValue: skuItem
                ? 0
                : data[this.getInputHeaderId(CalculationHeadersEnums, 'grossMarginUnitValue')],
            grossMarginValue: skuItem ? 0 : data[this.getInputHeaderId(CalculationHeadersEnums, 'grossMarginValue')],
            netoUnitMargin: skuItem ? 0 : data[this.getInputHeaderId(CalculationHeadersEnums, 'netoUnitMargin')],
            netoMargin: skuItem ? 0 : data[this.getInputHeaderId(CalculationHeadersEnums, 'netoMargin')],
            tax1: skuItem ? skuItem.taxId1 : data[this.getInputHeaderId(CalculationHeadersEnums, 'tax1')],
            tax2: skuItem ? skuItem.taxId2 : data[this.getInputHeaderId(CalculationHeadersEnums, 'tax2')],
            tax3: skuItem ? skuItem.taxId3 : data[this.getInputHeaderId(CalculationHeadersEnums, 'tax3')],
            tax1Total: skuItem ? 0 : data[this.getInputHeaderId(CalculationHeadersEnums, 'tax1Total')],
            tax2Total: skuItem
                ? 0
                : data[this.getInputHeaderId(CalculationHeadersEnums, 'tax2Total')]
                ? data[this.getInputHeaderId(CalculationHeadersEnums, 'tax2Total')]
                : null,
            tax3Total: skuItem
                ? 0
                : data[this.getInputHeaderId(CalculationHeadersEnums, 'tax3Total')]
                ? data[this.getInputHeaderId(CalculationHeadersEnums, 'tax3Total')]
                : null,
            exitUnitPrice: skuItem ? 0 : data[this.getInputHeaderId(CalculationHeadersEnums, 'exitUnitPrice')],
            exitPrice: skuItem ? 0 : data[this.getInputHeaderId(CalculationHeadersEnums, 'exitPrice')],
            saleUnitPrice: skuItem ? 0 : data[this.getInputHeaderId(CalculationHeadersEnums, 'saleUnitPrice')],
            salePrice: skuItem ? 0 : data[this.getInputHeaderId(CalculationHeadersEnums, 'salePrice')],
            entryCalcHeaderId: entryCalcHeaderId
                ? entryCalcHeaderId
                : data[this.getInputHeaderId(CalculationHeadersEnums, 'entryCalcHeaderId')],
            skuId: skuItem ? skuItem.id : data[this.getInputHeaderId(CalculationHeadersEnums, 'skuId')],
        };
        return entryCalcItem;
    }

    getReadOnlyProps(SelectedHeadersEnums: StockHeaderEnums): number[] {
        const readOnlyProps = [
            this.getInputHeaderId(SelectedHeadersEnums, 'id'),
            this.getInputHeaderId(SelectedHeadersEnums, 'currentSkuPrice'),
            this.getInputHeaderId(SelectedHeadersEnums, 'stockMeasureUnit'),
            this.getInputHeaderId(SelectedHeadersEnums, 'entryVATInfo'),
            this.getInputHeaderId(SelectedHeadersEnums, 'grossMarginUnitValue'),
            this.getInputHeaderId(SelectedHeadersEnums, 'grossMarginValue'),
            this.getInputHeaderId(SelectedHeadersEnums, 'netoUnitMargin'),
            this.getInputHeaderId(SelectedHeadersEnums, 'netoMargin'),
            this.getInputHeaderId(SelectedHeadersEnums, 'entryPrice'),
            this.getInputHeaderId(SelectedHeadersEnums, 'salePrice'),
            this.getInputHeaderId(SelectedHeadersEnums, 'tax1Total'),
            this.getInputHeaderId(SelectedHeadersEnums, 'tax2Total'),
            this.getInputHeaderId(SelectedHeadersEnums, 'tax3Total'),
            this.getInputHeaderId(SelectedHeadersEnums, 'exitUnitPrice'),
            this.getInputHeaderId(SelectedHeadersEnums, 'exitPrice'),
            this.getInputHeaderId(SelectedHeadersEnums, 'transportation'),
            this.getInputHeaderId(SelectedHeadersEnums, 'shipping'),
            this.getInputHeaderId(SelectedHeadersEnums, 'customs'),
            this.getInputHeaderId(SelectedHeadersEnums, 'otherCosts'),
            this.getInputHeaderId(SelectedHeadersEnums, 'skuId'),
        ];
        return readOnlyProps;
    }

    makeDataForTableForCalculation(entryCalcItems: EntryCalculationItemExtended[], setData: Function): void {
        const _data: any = [];
        let rowHeaders = [];
        for (const h in CalculationHeadersEnums) {
            rowHeaders.push(translate(CalculationHeadersEnums[h]));
        }
        _data.push(rowHeaders);

        entryCalcItems.forEach((r: EntryCalculationItemExtended) => {
            let row = [];
            row.push(r.id);
            row.push(
                r.name
                    ? r.name +
                          (r.code ? ' (' + r.code + ') ' : '') +
                          (' ' + r.StockKeepingUnit?.stockMeasureUnit ? r.StockKeepingUnit.stockMeasureUnit : '')
                    : r.code
                    ? ' (' + r.code + ')'
                    : ''
            );
            row.push(r.quantity ? Number(r.quantity) : 0);
            row.push(r.entryUnitPrice ? Number(Number(r.entryUnitPrice).toFixed(4)) : 0.0);
            row.push(r.entryVAT ? Number(Number(r.entryVAT).toFixed(4)) : 0.0);
            row.push(r.grossMarginPercentage ? Number(Number(r.grossMarginPercentage).toFixed(4)) : 0.0);
            row.push(r.tax1 ? Number(Number(r.tax1).toFixed(4)) : '');
            row.push(r.tax2 ? Number(Number(r.tax2).toFixed(4)) : '');
            row.push(r.tax3 ? Number(Number(r.tax3).toFixed(4)) : '');
            row.push(r.exitUnitPrice ? Number(Number(r.exitUnitPrice).toFixed(4)) : 0.0);
            row.push(
                r.skuPrice || r.skuPrice === 0
                    ? Number(Number(r.skuPrice).toFixed(4))
                    : r.StockKeepingUnit?.salesPrice
                    ? Number(r.StockKeepingUnit.salesPrice)
                    : 0.0
            );
            row.push(r.saleUnitPrice ? Number(Number(r.saleUnitPrice).toFixed(4)) : 0.0);
            row.push(r.transportation ? Number(Number(r.transportation).toFixed(4)) : 0.0);
            row.push(r.shipping ? Number(Number(r.shipping).toFixed(4)) : 0.0);
            row.push(r.customs ? Number(Number(r.customs).toFixed(4)) : 0.0);
            row.push(r.otherCosts ? Number(Number(r.otherCosts).toFixed(4)) : 0.0);
            row.push(r.netoUnitMargin ? Number(Number(r.netoUnitMargin).toFixed(4)) : 0.0);
            row.push(r.entryPrice ? Number(Number(r.entryPrice).toFixed(4)) : 0.0);
            row.push(r.entryVATInfo ? Number(Number(r.entryVATInfo).toFixed(4)) : 0.0);
            row.push(r.tax1Total ? Number(Number(r.tax1Total).toFixed(4)) : 0.0);
            row.push(r.tax2Total ? Number(Number(r.tax2Total).toFixed(4)) : 0.0);
            row.push(r.tax3Total ? Number(Number(r.tax3Total).toFixed(4)) : 0.0);
            row.push(r.exitPrice ? Number(Number(r.exitPrice).toFixed(4)) : 0.0);
            row.push(r.salePrice ? Number(Number(r.salePrice).toFixed(4)) : 0.0);
            row.push(r.netoMargin ? Number(Number(r.netoMargin).toFixed(4)) : 0.0);
            row.push(r.StockKeepingUnit?.name ? r.StockKeepingUnit.name : '');
            row.push(r.StockKeepingUnit?.code ? r.StockKeepingUnit.code : '');
            row.push(r.StockKeepingUnit?.barcode ? r.StockKeepingUnit.barcode : '');
            row.push(r.StockKeepingUnit?.id ? Number(r.StockKeepingUnit.id) : '');

            _data.push(row);
        });
        setData([..._data]);
    }

    recalculateEditedItems(data: any, editedObj: EntryCalculationItemModel): EntryCalculationItemModel {
        const _i: any = { ...editedObj };
        _i[CalculationHeadersEnums[this.getInputHeaderId(CalculationHeadersEnums, 'entryVATInfo')]] = Number(
            data[this.getInputHeaderId(CalculationHeadersEnums, 'entryVATInfo')]
        );
        _i[CalculationHeadersEnums[this.getInputHeaderId(CalculationHeadersEnums, 'tax1Total')]] = Number(
            data[this.getInputHeaderId(CalculationHeadersEnums, 'tax1Total')]
        );
        _i[CalculationHeadersEnums[this.getInputHeaderId(CalculationHeadersEnums, 'tax2Total')]] = Number(
            data[this.getInputHeaderId(CalculationHeadersEnums, 'tax2Total')]
        );
        _i[CalculationHeadersEnums[this.getInputHeaderId(CalculationHeadersEnums, 'tax3Total')]] = Number(
            data[this.getInputHeaderId(CalculationHeadersEnums, 'tax3Total')]
        );
        _i[CalculationHeadersEnums[this.getInputHeaderId(CalculationHeadersEnums, 'grossMarginValue')]] = Number(
            data[this.getInputHeaderId(CalculationHeadersEnums, 'grossMarginValue')]
        );
        _i[CalculationHeadersEnums[this.getInputHeaderId(CalculationHeadersEnums, 'exitPrice')]] = Number(
            data[this.getInputHeaderId(CalculationHeadersEnums, 'exitPrice')]
        );
        _i[CalculationHeadersEnums[this.getInputHeaderId(CalculationHeadersEnums, 'grossMarginPercentage')]] = Number(
            data[this.getInputHeaderId(CalculationHeadersEnums, 'grossMarginPercentage')]
        );
        _i[CalculationHeadersEnums[this.getInputHeaderId(CalculationHeadersEnums, 'netoMargin')]] = Number(
            data[this.getInputHeaderId(CalculationHeadersEnums, 'netoMargin')]
        );
        _i[CalculationHeadersEnums[this.getInputHeaderId(CalculationHeadersEnums, 'transportation')]] = Number(
            data[this.getInputHeaderId(CalculationHeadersEnums, 'transportation')]
        );
        _i[CalculationHeadersEnums[this.getInputHeaderId(CalculationHeadersEnums, 'shipping')]] = Number(
            data[this.getInputHeaderId(CalculationHeadersEnums, 'shipping')]
        );
        _i[CalculationHeadersEnums[this.getInputHeaderId(CalculationHeadersEnums, 'customs')]] = Number(
            data[this.getInputHeaderId(CalculationHeadersEnums, 'customs')]
        );
        _i[CalculationHeadersEnums[this.getInputHeaderId(CalculationHeadersEnums, 'otherCosts')]] = Number(
            data[this.getInputHeaderId(CalculationHeadersEnums, 'otherCosts')]
        );
        _i[CalculationHeadersEnums[this.getInputHeaderId(CalculationHeadersEnums, 'salePrice')]] = Number(
            data[this.getInputHeaderId(CalculationHeadersEnums, 'salePrice')]
        );
        return _i;
    }

    haveCurrencyorPercentageIndexCalculation(SelectedHeadersEnums: StockHeaderEnums): {
        haveCurrencyIndex: number[];
        havePercentageIndex: number[];
    } {
        const haveCurrencyIndex = [
            this.getInputHeaderId(SelectedHeadersEnums, 'entryVATInfo'),
            this.getInputHeaderId(SelectedHeadersEnums, 'transportation'),
            this.getInputHeaderId(SelectedHeadersEnums, 'shipping'),
            this.getInputHeaderId(SelectedHeadersEnums, 'customs'),
            this.getInputHeaderId(SelectedHeadersEnums, 'otherCosts'),
            this.getInputHeaderId(SelectedHeadersEnums, 'grossMarginValue'),
            this.getInputHeaderId(SelectedHeadersEnums, 'tax1Total'),
            this.getInputHeaderId(SelectedHeadersEnums, 'tax2Total'),
            this.getInputHeaderId(SelectedHeadersEnums, 'tax3Total'),
        ];
        const havePercentageIndex = [
            this.getInputHeaderId(SelectedHeadersEnums, 'grossMarginPercentage'),
            this.getInputHeaderId(SelectedHeadersEnums, 'entryVAT'),
            this.getInputHeaderId(SelectedHeadersEnums, 'tax1'),
            this.getInputHeaderId(SelectedHeadersEnums, 'tax2'),
            this.getInputHeaderId(SelectedHeadersEnums, 'tax3'),
        ];
        return { haveCurrencyIndex, havePercentageIndex };
    }

    //on change funkcije za dokumente i kalkulaciju
    async onChangeDocumentCells(
        changes: SheetChange[],
        data: any,
        stockDocItems: StockDocItemsExtended[],
        SelectedHeadersEnums: StockHeaderEnums,
        taxes: TaxModel[],
        _HeaderEnums: StockHeaderEnums,
        selectValue?: string | number
    ): Promise<any> {
        let newData = [...data],
            deleteRow: number | null = null,
            _stockDocItems: StockDocItemsExtended[] = [...stockDocItems];
        let editedStockDocItems: StockDocItemsExtended[] = [],
            selectedSkuItem: StockKeepingUnitModel = {} as StockKeepingUnitModel;
        const last = Object.keys(SelectedHeadersEnums)[Object.keys(SelectedHeadersEnums).length - 1];

        if (changes.length > 1) {
            if (changes.every((x: SheetChange) => x.value === null || x.value === '')) {
                //izbirsan row
                const itemExist = stockDocItems[changes[0].y] ? { ...stockDocItems[changes[0].y] } : null;
                if (
                    itemExist?.id &&
                    !editedStockDocItems.find((i) => i.id === itemExist.id) &&
                    !itemExist?.lockedItem
                ) {
                    const _itemToDel = { ...itemExist };
                    _itemToDel.delete = true;
                    editedStockDocItems.push(_itemToDel);
                    _stockDocItems = stockDocItems.filter((i) => i.id !== itemExist.id);
                }
                deleteRow = changes[0].y;
            } else if (changes.every((change: SheetChange) => change.x === changes[0].x)) {
                //copy paste po stupcu
                for (const change of changes) {
                    const _editItem: any = _stockDocItems[change.y] ? { ..._stockDocItems[change.y] } : null;
                    if (_editItem && !_editItem?.lockedItem) {
                        const inputCell = Object.keys(_HeaderEnums).find(
                            (h) => _HeaderEnums[Number(h)] === SelectedHeadersEnums[change.x]
                        );
                        if (inputCell && newData[change.y]) {
                            _editItem[
                                change.x === this.getInputHeaderId(SelectedHeadersEnums, 'stockUnitQuantity')
                                    ? 'quantity'
                                    : change.x === this.getInputHeaderId(SelectedHeadersEnums, 'purchaseUnitPrice')
                                    ? 'purchasePrice'
                                    : _HeaderEnums[Number(inputCell)]
                            ] = this.returnEditStockDocItemValue(change, SelectedHeadersEnums, taxes);
                            editedStockDocItems.push(_editItem);
                            newData[change.y][inputCell] = change.value;
                        }
                    }
                }
            }
        } else {
            //changes length === 1
            for (const change of changes) {
                const _editItem: any = _stockDocItems[change.y] ? { ..._stockDocItems[change.y] } : null;

                if (change.x === this.getInputHeaderId(SelectedHeadersEnums, 'skuItem') && change.value !== '') {
                    if (!newData[change.y]) {
                        newData[change.y] = [];
                    }

                    if (_editItem) {
                        throw new Error(translate('You can not change sku item!'));
                    } else {
                        let _change: SheetChange = {
                            x: change.x,
                            y: change.y,
                            value: selectValue ? selectValue : change.value,
                        };
                        if (change?.skuItem) {
                            _change.skuItem = { ...change.skuItem };
                            newData[change.y] = this.changeCodeOrBarcodeCell(newData, _change, _HeaderEnums, true);
                            selectedSkuItem = { ...change?.skuItem };
                        } else {
                            newData = [...data];
                        }
                    }
                } else if (
                    change.x >= this.getInputHeaderId(SelectedHeadersEnums, 'stockUnitQuantity') &&
                    change.x <= Number(last) &&
                    change.value !== ''
                ) {
                    //ostala polja koja se popunjaju
                    if (!newData[change.y]) {
                        throw new Error(translate('Please add code first!'));
                    }

                    const inputCell = Object.keys(_HeaderEnums).find(
                        (h) => _HeaderEnums[Number(h)] === SelectedHeadersEnums[change.x]
                    );

                    const isPiecesQtyCell = change.x === this.getInputHeaderId(SelectedHeadersEnums, 'piecesQuantity');

                    if (inputCell) {
                        newData[change.y][Number(inputCell)] =
                            change.x >= this.getInputHeaderId(SelectedHeadersEnums, 'stockUnitQuantity') &&
                            (change.x <= this.getInputHeaderId(SelectedHeadersEnums, 'purchaseValueStockUnit') ||
                                change.x <= this.getInputHeaderId(SelectedHeadersEnums, 'itemValue') ||
                                change.x <= this.getInputHeaderId(SelectedHeadersEnums, 'skuId'))
                                ? !Number(change.value)
                                    ? 0
                                    : Number(change.value)
                                : change.value;

                        if (isPiecesQtyCell) {
                            newData[change.y][this.getInputHeaderId(_HeaderEnums, 'stockUnitQuantity')] =
                                Number(change.value) *
                                Number(
                                    newData[change.y][
                                        this.getInputHeaderId(_HeaderEnums, 'purchaseMeasureUnit_stockUnitMultiplier')
                                    ]
                                );
                        }

                        if (!_editItem?.lockedItem) {
                            _editItem.edit = true;
                            //mora postojati ovdje jer prvo upisujem code
                            let alreadyInEditingArray = editedStockDocItems.find((i) => i.id === _editItem.id)
                                ? { ...editedStockDocItems.find((i) => i.id === _editItem.id) }
                                : null;
                            if (alreadyInEditingArray) {
                                editedStockDocItems = editedStockDocItems.map((i: StockDocItemsExtended) => {
                                    if (i.id === _editItem.id) {
                                        const _i: any = { ...i };
                                        if (isPiecesQtyCell) {
                                            _i['quantity'] =
                                                Number(change.value) *
                                                Number(_i.StockKeepingUnit?.purchaseMeasureUnit_stockUnitMultiplier);
                                        } else {
                                            _i[
                                                change.x ===
                                                this.getInputHeaderId(SelectedHeadersEnums, 'stockUnitQuantity')
                                                    ? 'quantity'
                                                    : change.x ===
                                                      this.getInputHeaderId(SelectedHeadersEnums, 'purchaseUnitPrice')
                                                    ? 'purchasePrice'
                                                    : _HeaderEnums[Number(inputCell)]
                                            ] = this.returnEditStockDocItemValue(change, SelectedHeadersEnums, taxes);
                                        }
                                        return _i;
                                    } else {
                                        return i;
                                    }
                                });
                            } else {
                                if (isPiecesQtyCell) {
                                    _editItem['quantity'] =
                                        Number(change.value) *
                                        Number(_editItem.StockKeepingUnit?.purchaseMeasureUnit_stockUnitMultiplier);
                                } else {
                                    _editItem[
                                        change.x === this.getInputHeaderId(SelectedHeadersEnums, 'stockUnitQuantity')
                                            ? 'quantity'
                                            : change.x ===
                                              this.getInputHeaderId(SelectedHeadersEnums, 'purchaseUnitPrice')
                                            ? 'purchasePrice'
                                            : _HeaderEnums[Number(inputCell)]
                                    ] = this.returnEditStockDocItemValue(change, SelectedHeadersEnums, taxes);
                                }
                                editedStockDocItems.push(_editItem);
                            }
                        }
                    }
                }
            }
        }

        if (deleteRow !== null) {
            newData = newData.filter((r, index) => index !== deleteRow);
        }
        return { newData, editedStockDocItems, selectedSkuItem };
    }

    async onChangeCalculationCell(
        changes: SheetChange[],
        data: any[],
        entryCalcItems: EntryCalculationItemExtended[],
        SelectedHeadersEnums: StockHeaderEnums,
        selectValue?: number | null
    ): Promise<any> {
        let newData = [...data],
            editedColumn = null,
            deleteRow: number | null = null,
            _entryCalcItems: EntryCalculationItemModel[] = [...entryCalcItems];
        let _editedEntryCalcItems: EntryCalculationItemModel[] = [];
        const last = Object.keys(SelectedHeadersEnums)[Object.keys(SelectedHeadersEnums).length - 1];

        if (changes.length > 1) {
            //znaci da je izbrisan row jer mozemo editirati samo do 19og stupca
            if (changes.every((x: SheetChange) => x.value === null || x.value === '')) {
                const itemExist = entryCalcItems[changes[0].y - 1] ? { ...entryCalcItems[changes[0].y - 1] } : null;
                if (itemExist?.id && !_editedEntryCalcItems.find((i) => i.id === itemExist.id)) {
                    const _itemToDel = { ...itemExist };
                    _itemToDel.delete = true;
                    _editedEntryCalcItems.push(_itemToDel);
                    _entryCalcItems = entryCalcItems.filter((i) => i.id !== itemExist.id);
                }
                deleteRow = changes[0].y;
            } else if (changes.every((change: SheetChange) => change.x === changes[0].x)) {
                //copy paste po stupcu
                editedColumn = SelectedHeadersEnums[changes[0].x];
                for (const change of changes) {
                    const _editItem: any = _entryCalcItems[change.y - 1] ? { ..._entryCalcItems[change.y - 1] } : null;
                    if (_editItem) {
                        const inputCell = Object.keys(CalculationHeadersEnums).find(
                            (h) => CalculationHeadersEnums[Number(h)] === SelectedHeadersEnums[change.x]
                        );
                        const { havePercentageIndex } =
                            this.haveCurrencyorPercentageIndexCalculation(SelectedHeadersEnums);
                        const percInd = havePercentageIndex.includes(change.x);
                        //@ts-ignore
                        const splitedValue = percInd ? change?.value?.split?.(' %') : [change.value];
                        if (
                            inputCell &&
                            newData[change.y] &&
                            Number(inputCell) !== this.getInputHeaderId(SelectedHeadersEnums, 'skuItem')
                        ) {
                            newData[change.y][inputCell] = splitedValue[0];
                            _editItem[CalculationHeadersEnums[Number(inputCell)]] = splitedValue[0];
                            const recalItem = this.recalculateEditedItems(newData[change.y], _editItem);
                            _editedEntryCalcItems.push(recalItem);
                        }
                    }
                }
            }
        } else {
            // changes.length === 1)
            for (let change of changes) {
                const _editItem: any = _entryCalcItems[change.y - 1] ? { ..._entryCalcItems[change.y - 1] } : null;
                const { havePercentageIndex } = this.haveCurrencyorPercentageIndexCalculation(SelectedHeadersEnums);
                const percInd = havePercentageIndex.includes(change.x);
                //@ts-ignore
                const splitedValue = percInd ? change?.value?.split?.(' %') : [change.value];

                if (change.x === this.getInputHeaderId(SelectedHeadersEnums, 'skuItem') && splitedValue[0] !== '') {
                    if (!newData[change.y]) {
                        newData[change.y] = [];
                    }

                    if (_editItem) {
                        throw new Error(translate('You can not change sku item!'));
                    } else {
                        //barcode polje
                        let _change: SheetChange = {
                            x: change.x,
                            y: change.y,
                            value: selectValue ? selectValue : splitedValue[0],
                        };
                        try {
                            if (change?.skuItem) {
                                const itemExist = _entryCalcItems?.find((i) => i.skuId === change?.skuItem?.id);
                                if (itemExist) {
                                    newData = [...data];
                                    throw new Error(translate('You already added sku item!'));
                                } else {
                                    _change.skuItem = { ...change.skuItem };
                                    newData[change.y] = this.changeCodeOrBarcodeCell(
                                        newData,
                                        _change,
                                        CalculationHeadersEnums,
                                        false
                                    );
                                    editedColumn = 'skuId';
                                }
                            } else {
                                newData = [...data];
                            }
                        } catch (error: any) {
                            throw new Error(error);
                        }
                    }
                } else if (
                    change.x >= this.getInputHeaderId(SelectedHeadersEnums, 'skuItem') &&
                    change.x <= Number(last) &&
                    splitedValue[0] !== ''
                ) {
                    //ostala polja koja se popunjuju
                    if (!newData[change.y]) {
                        throw new Error(translate('Please add code first!'));
                    }
                    const inputCell = Object.keys(CalculationHeadersEnums).find(
                        (h) => CalculationHeadersEnums[Number(h)] === SelectedHeadersEnums[change.x]
                    );

                    editedColumn = SelectedHeadersEnums[change.x];

                    if (
                        change.x === this.getInputHeaderId(SelectedHeadersEnums, 'entryVAT') ||
                        change.x === this.getInputHeaderId(SelectedHeadersEnums, 'tax1') ||
                        change.x === this.getInputHeaderId(SelectedHeadersEnums, 'tax2') ||
                        change.x === this.getInputHeaderId(SelectedHeadersEnums, 'tax3')
                    ) {
                        // ako je neki cell od onih gdje je select option
                        if (selectValue) {
                            change.value = Number(selectValue) ? Number(selectValue) : null;
                            if (inputCell) {
                                newData[change.y][Number(inputCell)] = Number(selectValue) ? Number(selectValue) : null;

                                if (_editItem) {
                                    let alreadyInEditingArray = _editedEntryCalcItems.find((i) => i.id === _editItem.id)
                                        ? { ..._editedEntryCalcItems.find((i) => i.id === _editItem.id) }
                                        : null;
                                    if (alreadyInEditingArray) {
                                        // eslint-disable-next-line no-loop-func
                                        _editedEntryCalcItems = _editedEntryCalcItems.map((i) => {
                                            if (i.id === _editItem.id) {
                                                const _i: any = { ...i };
                                                _i[CalculationHeadersEnums[Number(inputCell)]] = Number(selectValue)
                                                    ? Number(selectValue)
                                                    : null;
                                                const recalItem = this.recalculateEditedItems(newData[change.y], _i);
                                                return recalItem;
                                            } else {
                                                return i;
                                            }
                                        });
                                    } else {
                                        _editItem[CalculationHeadersEnums[Number(inputCell)]] = Number(selectValue)
                                            ? Number(selectValue)
                                            : null;
                                        const recalItem = this.recalculateEditedItems(newData[change.y], _editItem);
                                        _editedEntryCalcItems.push(recalItem);
                                    }
                                }
                            }
                        }
                    } else {
                        // ako je obicni input
                        if (inputCell) {
                            newData[change.y][Number(inputCell)] =
                                change.x >= this.getInputHeaderId(SelectedHeadersEnums, 'quantity') &&
                                change.x <= this.getInputHeaderId(SelectedHeadersEnums, 'exitPrice')
                                    ? Number(splitedValue[0])
                                        ? Number(splitedValue[0])
                                        : 0.0
                                    : splitedValue[0];
                            if (change.x === this.getInputHeaderId(SelectedHeadersEnums, 'entryPrice')) {
                                // eslint-disable-next-line no-loop-func
                                _editedEntryCalcItems = _entryCalcItems.map((i) => {
                                    const _i: any = { ...i };
                                    if (_editItem && i.id === _editItem.id) {
                                        _i[CalculationHeadersEnums[Number(inputCell)]] = Number(splitedValue[0])
                                            ? Number(splitedValue[0])
                                            : 0.0;
                                    }
                                    const changedData = newData?.find((d: any) => d[0] === _i.id);
                                    if (changedData) {
                                        const recalItem = this.recalculateEditedItems(changedData, _i);
                                        return recalItem;
                                    } else {
                                        return _i;
                                    }
                                });
                            } else if (_editItem) {
                                let alreadyInEditingArray = _editedEntryCalcItems.find((i) => i.id === _editItem.id)
                                    ? { ..._editedEntryCalcItems.find((i) => i.id === _editItem.id) }
                                    : null;
                                if (alreadyInEditingArray) {
                                    // eslint-disable-next-line no-loop-func
                                    _editedEntryCalcItems = _editedEntryCalcItems.map((i) => {
                                        if (i.id === _editItem.id) {
                                            const _i: any = { ...i };
                                            _i[CalculationHeadersEnums[Number(inputCell)]] =
                                                Number(change.x) >=
                                                    this.getInputHeaderId(SelectedHeadersEnums, 'quantity') &&
                                                Number(change.x) <=
                                                    this.getInputHeaderId(SelectedHeadersEnums, 'exitPrice')
                                                    ? Number(splitedValue[0])
                                                        ? Number(splitedValue[0])
                                                        : 0.0
                                                    : splitedValue[0];
                                            const recalItem = this.recalculateEditedItems(newData[change.y], _i);
                                            return recalItem;
                                        } else {
                                            return i;
                                        }
                                    });
                                } else {
                                    _editItem[CalculationHeadersEnums[Number(inputCell)]] =
                                        Number(change.x) >= this.getInputHeaderId(SelectedHeadersEnums, 'quantity') &&
                                        Number(change.x) <= this.getInputHeaderId(SelectedHeadersEnums, 'exitPrice')
                                            ? Number(splitedValue[0])
                                                ? Number(splitedValue[0])
                                                : 0.0
                                            : splitedValue[0];
                                    const recalItem = this.recalculateEditedItems(newData[change.y], _editItem);
                                    _editedEntryCalcItems.push(recalItem);
                                }
                            }
                        }
                    }
                }
            }
        }
        if (deleteRow !== null) {
            newData = newData.filter((r, index) => index !== deleteRow);
        }
        return { newData, _editedEntryCalcItems, editedColumn };
    }

    makeDataForTableForPriceChageLogs(priceChangeLogs: PriceChangeLogItemsExtended[], setData: Function) {
        const _data: any = [];
        priceChangeLogs.forEach((r: PriceChangeLogItemsExtended) => {
            let row = [];
            row.push(r.id);
            row.push(
                r.StockKeepingUnit?.name
                    ? r.StockKeepingUnit?.name +
                          (r.StockKeepingUnit?.code ? ' (' + r.StockKeepingUnit?.code + ')' : '') +
                          (r.StockKeepingUnit?.stockMeasureUnit ? ' ' + r.StockKeepingUnit?.stockMeasureUnit : '')
                    : '' + (r.StockKeepingUnit?.code ? ' (' + r.StockKeepingUnit?.code + ')' : '')
            );
            row.push(r.StockKeepingUnit?.stockMeasureUnit);
            row.push(Number(r.skuStockCurrent ? r.skuStockCurrent : 0)?.toFixed(2));
            row.push(Number(r.previousSkuPrice ? r.previousSkuPrice : 0)?.toFixed(2));
            row.push(Number(r.previousSkuPriceValue ? r.previousSkuPriceValue : 0)?.toFixed(2));
            row.push(Number(r.newSkuPrice ? r.newSkuPrice : 0)?.toFixed(2));
            row.push(Number(r.newSkuPriceValue ? r.newSkuPriceValue : 0)?.toFixed(2));
            row.push(Number(r.taxAdjustment ? r.taxAdjustment : 0)?.toFixed(2));
            row.push(Number(r.priceAdjustment ? r.priceAdjustment : 0)?.toFixed(2));
            row.push(r.skuId);

            _data.push(row);
        });
        setData([..._data]);
    }

    makePriceChangeLogItemModel(
        data: any,
        _HeaderEnums: StockHeaderEnums,
        selectedSkuItem: StockKeepingUnitModel,
        priceChangeLogHeaderId: number
    ): PriceChangeLogItemModel {
        const priceChangeLogItem: PriceChangeLogItemModel = {
            skuId: data[this.getInputHeaderId(_HeaderEnums, 'skuId')],
            skuName: selectedSkuItem?.name ? selectedSkuItem?.name : '',
            skuStockCurrent: Number(data[this.getInputHeaderId(_HeaderEnums, 'skuStockCurrent')])
                ? data[this.getInputHeaderId(_HeaderEnums, 'skuStockCurrent')]
                : 0,
            previousSkuPrice: Number(data[this.getInputHeaderId(_HeaderEnums, 'previousSkuPrice')])
                ? data[this.getInputHeaderId(_HeaderEnums, 'previousSkuPrice')]
                : 0,
            previousSkuPriceValue: Number(data[this.getInputHeaderId(_HeaderEnums, 'previousSkuPriceValue')])
                ? data[this.getInputHeaderId(_HeaderEnums, 'previousSkuPriceValue')]
                : 0,
            newSkuPrice: Number(data[this.getInputHeaderId(_HeaderEnums, 'newSkuPrice')])
                ? data[this.getInputHeaderId(_HeaderEnums, 'newSkuPrice')]
                : 0,
            newSkuPriceValue: Number(data[this.getInputHeaderId(_HeaderEnums, 'newSkuPriceValue')])
                ? data[this.getInputHeaderId(_HeaderEnums, 'newSkuPriceValue')]
                : 0,
            taxAdjustment: Number(data[this.getInputHeaderId(_HeaderEnums, 'taxAdjustment')])
                ? data[this.getInputHeaderId(_HeaderEnums, 'taxAdjustment')]
                : 0,
            priceAdjustment: Number(data[this.getInputHeaderId(_HeaderEnums, 'priceAdjustment')])
                ? data[this.getInputHeaderId(_HeaderEnums, 'priceAdjustment')]
                : 0,
            priceChangeLogHeaderId: priceChangeLogHeaderId,
        };
        return priceChangeLogItem;
    }

    makePriceLogItemModelBySelectedSkuItems(
        selectedSkuItem: StockKeepingUnitEditModel,
        priceChangeLogHeaderId: number
    ): PriceChangeLogItemModel {
        const priceLogItem: PriceChangeLogItemModel = {
            skuId: selectedSkuItem?.id,
            skuName: selectedSkuItem?.name,
            skuStockCurrent: 0,
            previousSkuPrice: selectedSkuItem?.salesPrice,
            previousSkuPriceValue: 0,
            newSkuPrice: 0,
            newSkuPriceValue: 0,
            taxAdjustment: 0,
            priceAdjustment: 0,
            priceChangeLogHeaderId: priceChangeLogHeaderId,
            parentId: selectedSkuItem?.parentId,
        };
        return priceLogItem;
    }

    getReadOnlyPriceChangeLogProps(SelectedHeadersEnums: StockHeaderEnums) {
        const readOnlyArray = [
            this.getInputHeaderId(SelectedHeadersEnums, 'id'),
            this.getInputHeaderId(SelectedHeadersEnums, 'skuId'),
            this.getInputHeaderId(SelectedHeadersEnums, 'stockMeasureUnit'),
            this.getInputHeaderId(SelectedHeadersEnums, 'skuStockCurrent'),
            this.getInputHeaderId(SelectedHeadersEnums, 'previousSkuPrice'),
            this.getInputHeaderId(SelectedHeadersEnums, 'previousSkuPriceValue'),
            this.getInputHeaderId(SelectedHeadersEnums, 'newSkuPriceValue'),
            this.getInputHeaderId(SelectedHeadersEnums, 'taxAdjustment'),
            this.getInputHeaderId(SelectedHeadersEnums, 'priceAdjustment'),
        ];
        return readOnlyArray;
    }

    async onChangePriceChangeLogCells(
        changes: SheetChange[],
        data: any,
        priceChangeLogItems: PriceChangeLogItemsExtended[],
        SelectedHeadersEnums: StockHeaderEnums,
        taxes: TaxModel[],
        _HeaderEnums: StockHeaderEnums,
        selectValue?: string | number
    ): Promise<any> {
        let newData = [...data],
            deleteRow: number | null = null,
            _priceChangeLogItem: PriceChangeLogItemsExtended[] = [...priceChangeLogItems];
        let editedPriceChangeLogItems: PriceChangeLogItemsExtended[] = [],
            selectedSkuItem: StockKeepingUnitModel = {} as StockKeepingUnitModel;

        if (changes.length > 1) {
            if (changes.every((x: SheetChange) => x.value === null || x.value === '')) {
                //izbirsan row
                const itemExist = priceChangeLogItems[changes[0].y] ? { ...priceChangeLogItems[changes[0].y] } : null;
                if (itemExist?.id && !editedPriceChangeLogItems.find((i) => i.id === itemExist.id)) {
                    const _itemToDel = { ...itemExist };
                    _itemToDel.delete = true;
                    editedPriceChangeLogItems.push(_itemToDel);
                    _priceChangeLogItem = priceChangeLogItems.filter((i) => i.id !== itemExist.id);
                }
                deleteRow = changes[0].y;
            } else if (changes.every((change: SheetChange) => change.x === changes[0].x)) {
                //copy paste po stupcu
                for (const change of changes) {
                    const _editItem: any = _priceChangeLogItem[change.y] ? { ..._priceChangeLogItem[change.y] } : null;
                    if (_editItem) {
                        const inputCell = Object.keys(_HeaderEnums).find(
                            (h) => _HeaderEnums[Number(h)] === SelectedHeadersEnums[change.x]
                        );
                        if (
                            inputCell &&
                            newData[change.y] &&
                            Number(inputCell) === this.getInputHeaderId(_HeaderEnums, 'newSkuPrice')
                        ) {
                            _editItem[SelectedHeadersEnums[change.x]] = Number(change.value);
                            editedPriceChangeLogItems.push(_editItem);
                            newData[change.y][inputCell] = change.value;
                        }
                    }
                }
            }
        } else {
            //changes length === 1
            for (const change of changes) {
                const _editItem: any = _priceChangeLogItem[change.y] ? { ..._priceChangeLogItem[change.y] } : null;

                if (change.x === this.getInputHeaderId(SelectedHeadersEnums, 'skuItem') && change.value !== '') {
                    if (!newData[change.y]) {
                        newData[change.y] = [];
                    }

                    if (_editItem) {
                        throw new Error(translate('You can not change sku item!'));
                    } else {
                        let _change: SheetChange = {
                            x: change.x,
                            y: change.y,
                            value: selectValue ? selectValue : change.value,
                        };
                        if (change?.skuItem) {
                            const itemExist = _priceChangeLogItem?.find((p) => p.skuId === change?.skuItem?.id);
                            if (itemExist) {
                                newData = [...data];
                                throw new Error(translate('You already added that sku item!'));
                            } else {
                                _change.skuItem = { ...change.skuItem };
                                newData[change.y] = this.changeCodeOrBarcodeCell(newData, _change, _HeaderEnums, true);
                                selectedSkuItem = { ...change?.skuItem };
                            }
                        } else {
                            newData = [...data];
                        }
                    }
                } else if (
                    change.x >= this.getInputHeaderId(SelectedHeadersEnums, 'newSkuPrice') &&
                    change.value !== ''
                ) {
                    //ostala polja koja se popunjaju
                    if (!newData[change.y]) {
                        throw new Error(translate('Please add sku item first!'));
                    }

                    const _editItem: any = _priceChangeLogItem[change.y] ? { ..._priceChangeLogItem[change.y] } : null;
                    if (_editItem) {
                        const inputCell = Object.keys(_HeaderEnums).find(
                            (h) => _HeaderEnums[Number(h)] === SelectedHeadersEnums[change.x]
                        );
                        if (
                            inputCell &&
                            newData[change.y] &&
                            Number(inputCell) === this.getInputHeaderId(_HeaderEnums, 'newSkuPrice')
                        ) {
                            _editItem[SelectedHeadersEnums[change.x]] = Number(change.value);
                            editedPriceChangeLogItems.push(_editItem);
                            newData[change.y][inputCell] = change.value;
                        }
                    }
                }
            }
        }

        if (deleteRow !== null) {
            newData = newData.filter((r, index) => index !== deleteRow);
        }
        return { newData, editedPriceChangeLogItems, selectedSkuItem };
    }
}

export const StockDocHeaderTemplate: StockDocHeadersWithItems = {
    docNumber: '',
    type: StockDocumentTypesEnum.goodsReceivingNote,
    date: new Date().getTime(),
    commitedDate: null,
    itemsCount: 0,
    itemsValue: 0,
    locked: false,
    note: null,
    createdByUser: null,
    commitedByUser: null,
    companyId: null,
    warehouseId: null,
    receivingWarehouseId: null,
    currency: null,
    Company: null,
    CreatedByUser: null,
    CommitedByUser: null,
    StockDocItems: [],
    docEntryCalcId: null,
};

export const EntryCalcHeaderTemplate: EntryCalcHeadersWithItems = {
    docNumber: '',
    stockDocHeaderId: null,
    date: new Date().getTime(),
    commitedDate: null,
    locked: false,
    currency: null,
    createdByUser: null,
    commitedByUser: null,
    companyId: null,
    totalTransportation: 0.0,
    totalShipping: 0.0,
    totalCustoms: 0.0,
    totalOtherCosts: 0.0,
    CreatedByUser1: null,
    CommitedByUser1: null,
    StockDocHeader: null,
    EntryCalculationItems: [],
    Company: null,
    calculationInvoiceNumber: null,
    newPriceValidFrom: null,
};

export const PriceChangeLogsHeaderWithItems: PriceChangeLogHeaderWithItems = {
    docNumber: '',
    date: new Date().getTime(),
    commitedDate: null,
    createdByUser: null,
    commitedByUser: null,
    newPriceValidFrom: new Date().getTime(),
    currency: null,
    locked: false,
    warehouseId: null,
    note: null,
    PriceChangeLogItem: [],
    CreatedByUser: null,
    CommitedByUser: null,
};

export const GoodsReceivingNoteHeadersEnums: StockHeaderEnums = {
    0: 'id',
    1: 'skuItem',
    2: 'stockMeasureUnit',
    3: 'purchaseMeasureUnit',
    4: 'purchaseMeasureUnit_stockUnitMultiplier',
    5: 'skuPrice',
    6: 'stockUnitQuantity',
    7: 'piecesQuantity',
    8: 'purchaseUnitPrice',
    9: 'purchaseValueStockUnit',
    10: 'taxId1',
    11: 'taxId2',
    12: 'taxId3',
    13: 'skuId',
};

export const GoodsDespatchNoteHeadersEnums: StockHeaderEnums = {
    0: 'id',
    1: 'skuItem',
    2: 'skuPrice',
    3: 'stockUnitQuantity',
    4: 'taxId1',
    5: 'taxId2',
    6: 'taxId3',
    7: 'skuId',
};

export const WriteOffGoodsAndOfferNoteHeadersEnums: StockHeaderEnums = {
    0: 'id',
    1: 'skuItem',
    2: 'skuPrice',
    3: 'stockUnitQuantity',
    4: 'skuId',
};

export const InterWarehouseTransferHeadersEnums: StockHeaderEnums = {
    0: 'id',
    1: 'skuItem',
    2: 'stockUnitQuantity',
    3: 'skuId',
};

export const ProcurementOrderHeaderEnums: StockHeaderEnums = {
    0: 'id',
    1: 'skuItem',
    2: 'externalSkuCode',
    3: 'purchasePrice',
    4: 'stockUnitQuantity',
    5: 'skuId',
};

export const AggregatedStockDocHeaderEnums: StockHeaderEnums = {
    0: 'id',
    1: 'skuItem',
    4: 'skuPrice',
    6: 'stockUnitQuantity',
    7: 'stockValue',
    8: 'skuId',
};

export const CalculationHeadersEnums: StockHeaderEnums = {
    0: 'id',
    1: 'skuItem',
    2: 'quantity',
    3: 'entryUnitPrice', //jedinična ulazna cijena s PDV
    4: 'entryVAT', //ulazni PDV, vrijednost u % (17% - u računici 0.17)
    5: 'grossMarginPercentage',
    //grossMarginUnitValue
    //grossMarginValue
    6: 'tax1', //izlazni pdv u %
    7: 'tax2',
    8: 'tax3',
    9: 'exitUnitPrice', //jedinična MPC bez taksi
    10: 'currentSkuPrice', //trenutna cijena sku artikla
    11: 'saleUnitPrice', //jedinična MPC s taksama ((exitPrice/quantity) + tax1Total + tax2Total + tax3Total)
    12: 'transportation', //ukupni trošak transporta (entryPrice * (totalTransportation / totalEntryPrice))
    13: 'shipping',
    14: 'customs',
    15: 'otherCosts',
    16: 'netoUnitMargin', //jedinična konačna dobit
    17: 'entryPrice', //ukupna ulazna cijena s PDV entryUnitPrice * quantity
    18: 'entryVATInfo', //ukupna ulazna vrijednost PDV
    19: 'tax1Total', //izlazna vrijednost pdv (tax1/100)*exitPrice
    20: 'tax2Total',
    21: 'tax3Total',
    22: 'exitPrice', //MPC bez taksi (entryPrice * (GMP / 100) + entryPrice)
    23: 'salePrice', //ukupna MPC s taksama (exitPrice + tax1Total + tax2Total + tax3Total)
    24: 'netoMargin', // konačna ukupna dobit grossMarginValue - transportation
    25: 'name',
    26: 'code',
    27: 'barcode',
    28: 'skuId',
};

export const InventoryHeadersEnums: StockHeaderEnums = {
    0: 'skuId',
    1: 'skuItem',
    2: 'previousState',
    3: 'totalGoodsReceived',
    4: 'totalGoodsDespatched',
    5: 'totalInovicedStock',
    6: 'manualTotalInovicedStock',
    7: 'totalCumulativeLoss',
    8: 'currentState',
    9: 'goodsReceiving',
    10: 'stateWithGoodsReceiving',
    11: 'newState',
    12: 'inventoryRecord',
    13: 'skuPrice',
    14: 'stockPriceValue',
    15: 'stockDocItemId',
};

export const SupplierPriceListHeaderEnums: StockHeaderEnums = {
    0: 'skuItem',
    1: 'supplierSkuCode',
    2: 'previousPrice',
    3: 'supplierProcurementPrice',
    4: 'skuId',
    5: 'internalSkuCode',
    6: 'internalSkuBarcode',
    7: 'skuItemName',
};

export const PriceChangeLogsHeadersEnums: StockHeaderEnums = {
    0: 'id',
    1: 'skuItem',
    2: 'stockMeasureUnit',
    3: 'skuStockCurrent',
    4: 'previousSkuPrice',
    5: 'previousSkuPriceValue',
    6: 'newSkuPrice',
    7: 'newSkuPriceValue',
    8: 'taxAdjustment',
    9: 'priceAdjustment',
    10: 'skuId',
};

export { StockController };
