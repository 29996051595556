import React, { useState } from 'react';
import PageTitle from '@components/pageTitle';
import translate from '@data/translations';
import moment from 'moment';
import 'moment/min/locales.min';
import {
    InvoiceConclusionModel,
    PosTerminalModel,
    InvoiceConclusionDetailsModel,
    // UserInfo,
} from '@common/modelDefinition';
// import InvoicePresenter from '@components/issueInvoice';
import InlineModal from '@components/modalInline';
import {
    createInvoiceConclusion,
    getInvoiceReconciliation,
    getInvoiceConclusions,
    getInvoiceConclusionDetails,
    updateInvoiceConslusion,
} from './api';
import ConfirmButtons from '@components/buttons/confirmButtons';
// import HeaderToolbar from '../posView/parts/HeaderToolbar';
//@ts-ignore
import { matchPath } from 'react-router';
import numbro from 'numbro';
//var searchDebouncer: NodeJS.Timeout = setTimeout(() => {}, 0);
import InlineRangeInput from '@components/inlineRangeInput';
import PrintView from '@components/printView/index';
import InvoiceConclusionReport from './invoiceConclusionReport';
import { PosItemSubTypes, PosItemTypes } from '@pages/priceList/pos/posItem/form';
import { getGroupedInvoiceConclusionsBySubTypeOfPlus, getGroupedInvoiceConclusionsByTypeOfPlus } from './utils';
interface InvoiceConclusionProps {
    history: any;
    location: any;
    // loggedUser: UserInfo;
    defaultCurrency: string;
    locale: string;
    companyInfo: any;
    targetPosTerminal?: PosTerminalModel | undefined;
}

function InvoiceConclusion(props: InvoiceConclusionProps) {
    const { location, defaultCurrency, locale, companyInfo, targetPosTerminal } = props;

    // const [searchTermState, setSearchTerm] = useState('');
    const [invoiceConclusionReports, setInvoiceConclusionReports] = useState<InvoiceConclusionModel[]>([]);
    const [invoiceReconciliationReport, setInvoiceReconciliationReport] = useState<InvoiceConclusionModel | null>(null);
    const [from, setFrom] = React.useState(new Date(moment().startOf('month').valueOf()));
    const [to, setTo] = React.useState(new Date());
    const [invoiceConclusionDetails, setInvoiceConclusionDetails] = useState<InvoiceConclusionModel | null>(null);
    const [showPosItemDetails, setShowPosItemDetails] = useState<boolean>(false);
    const [showConclusionReport, setShowConclusionReport] = useState<boolean>(false);
    const [reportType, setReportType] = useState<string | null>(null);

    const params = matchPath(location.pathname, { path: '/posTerminal/stateAndInvoiceConclusion/:posTerminalId' });
    const posTerminalId = params?.params?.posTerminalId;

    React.useEffect(() => {
        const loadInvoiceReconcilation = async () => {
            if (posTerminalId) {
                const _invoiceReconciliationReport = await getInvoiceReconciliation(posTerminalId);
                setInvoiceReconciliationReport(_invoiceReconciliationReport);
            }
        };
        loadInvoiceReconcilation().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, [posTerminalId]);

    React.useEffect(() => {
        loadData().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, [from, to]);

    const loadData = async (f = from, t = to) => {
        const _from = moment(f).startOf('day').valueOf();
        const _to = moment(t).endOf('day').valueOf();
        const invoiceConclusions = await getInvoiceConclusions(posTerminalId, _from, _to);
        setInvoiceConclusionReports(invoiceConclusions);
    };

    const handleDateSelection = async (type: string, date: any) => {
        const _from = type === 'from' ? date : from;
        const _to = type === 'to' ? date : to;
        await loadData(_from, _to);
        setFrom(_from);
        setTo(_to);
    };

    return (
        <div className="">
            {/* <div
                className="posTerminalHeader"
                style={{ backgroundColor: '#ffffff', borderBottom: '1px solid #d8e0ec', padding: '12px 0' }}
            > */}
            {/* <HeaderToolbar {...props} /> */}
            {/* </div> */}
            <div className="d-flex h-100 app-flex-div unselectable">
                <InlineModal
                    show={reportType !== null}
                    header={translate(reportType === 'zReport' ? 'Issue fiscal closure' : 'Issue daily report')}
                    setShow={setReportType}
                >
                    <InlineModalReports
                        posTerminalId={posTerminalId}
                        invoiceConclusionReports={invoiceConclusionReports}
                        reportType={reportType}
                        setReportType={setReportType}
                        setInvoiceReconciliationReport={setInvoiceReconciliationReport}
                        setInvoiceConclusionReports={setInvoiceConclusionReports}
                        invoiceReconciliationReport={invoiceReconciliationReport}
                    />
                </InlineModal>
                <div className="" id="main-app-div" style={{ width: '100%' }}>
                    <div className="title-with-options display-flex space-between d-print-none">
                        <PageTitle title={translate('Closing revenue balance') + ' - ' + targetPosTerminal?.name} />
                        <div className="display-flex">
                            <div className="page-title-button">
                                {!invoiceConclusionDetails ? (
                                    <div className="display-flex d-print-none">
                                        <div
                                            onClick={async () => {
                                                setReportType('xReport');
                                            }}
                                            className="button-light mr-16p"
                                        >
                                            {translate('X-Report')}
                                        </div>
                                        <div
                                            onClick={() => {
                                                setReportType('zReport');
                                            }}
                                            className="button-primary"
                                        >
                                            {translate('Z-Report')}
                                        </div>
                                    </div>
                                ) : (
                                    <div
                                        onClick={async () => {
                                            setInvoiceConclusionDetails(null);
                                            setShowPosItemDetails(false);
                                            setShowConclusionReport(false);
                                        }}
                                        className="button-light d-print-none"
                                    >
                                        {translate('GO BACK')}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {!invoiceConclusionDetails ? (
                        <React.Fragment>
                            <div className="search-and-page-controls display-flex space-between align-center">
                                <InlineRangeInput from={from} to={to} onChange={handleDateSelection} />
                            </div>
                            {invoiceReconciliationReport ? (
                                <div className="col-12 mt-3">
                                    <h5 className="mt-3 pointer text-center uppercase p-2">
                                        {<b>{translate('Invoice reconciliation')}</b>}{' '}
                                    </h5>
                                </div>
                            ) : null}
                            {invoiceReconciliationReport ? (
                                <React.Fragment>
                                    <InvoiceConclusionListHeader />
                                    <InvoiceConclusionList
                                        invoiceConclusions={[invoiceReconciliationReport]}
                                        defaultCurrency={defaultCurrency}
                                        setInvoiceConclusionDetails={setInvoiceConclusionDetails}
                                        setShowPosItemDetails={setShowPosItemDetails}
                                        setShowConclusionReport={setShowConclusionReport}
                                        locale={locale}
                                        setInvoiceReconciliationReport={setInvoiceReconciliationReport}
                                        invoiceReconciliationReport={invoiceReconciliationReport}
                                        setInvoiceConclusionReports={setInvoiceConclusionReports}
                                    />
                                </React.Fragment>
                            ) : null}

                            {invoiceConclusionReports.length > 0 ? (
                                <div className="col-12 mt-3">
                                    <h5 className="mt-3 pointer text-center uppercase p-2">
                                        {<b>{translate('Invoice conclusions')}</b>}{' '}
                                    </h5>
                                </div>
                            ) : (
                                <div className="col-12 mt-3">
                                    <b className="pl-3">{translate('There are no conclusions on the selected date')}</b>
                                </div>
                            )}
                            {invoiceConclusionReports.length > 0 ? (
                                <React.Fragment>
                                    <InvoiceConclusionListHeader />
                                    <InvoiceConclusionList
                                        invoiceConclusions={invoiceConclusionReports}
                                        defaultCurrency={defaultCurrency}
                                        setInvoiceConclusionDetails={setInvoiceConclusionDetails}
                                        setShowPosItemDetails={setShowPosItemDetails}
                                        setInvoiceReconciliationReport={setInvoiceReconciliationReport}
                                        setShowConclusionReport={setShowConclusionReport}
                                        locale={locale}
                                        invoiceReconciliationReport={invoiceReconciliationReport}
                                        setInvoiceConclusionReports={setInvoiceConclusionReports}
                                    />
                                </React.Fragment>
                            ) : null}
                        </React.Fragment>
                    ) : showPosItemDetails ? (
                        <InvoiceConclusionDetails
                            invoiceConclusion={invoiceConclusionDetails}
                            posTerminalId={posTerminalId}
                            defaultCurrency={defaultCurrency}
                        />
                    ) : showConclusionReport ? (
                        <PrintView
                            items={
                                <InvoiceConclusionReport
                                    companyInfo={companyInfo}
                                    location={location}
                                    defaultCurrency={defaultCurrency}
                                    invoiceConclusion={invoiceConclusionDetails}
                                />
                            }
                            documentHeader={<div className="col-12 mt-2"></div>}
                            documentFooter={<div className="col-12 mb-3"></div>}
                            footerHeight={52}
                            headerHeight={52}
                        />
                    ) : null}
                </div>
            </div>
        </div>
    );
}

interface InvoiceConclusionDetailsProps {
    invoiceConclusion: InvoiceConclusionModel;
    posTerminalId: number;
    defaultCurrency: string;
}

interface InvoiceConclusionDetailsResult {
    invoiced: InvoiceConclusionDetailsModel[];
    proformaDispatched: InvoiceConclusionDetailsModel[];
}

export interface InvoiceConclusionDetailsByTypeOrSubtype {
    [key: string]: InvoiceConclusionDetailsResult;
}

export interface SelectedTypeOrSubTypeByPosTerminalId {
    [key: number]: string[];
}

const InvoiceConclusionDetails = (props: InvoiceConclusionDetailsProps) => {
    const { invoiceConclusion, posTerminalId, defaultCurrency } = props;
    const [invoiceConclusionsDetails, setInvoiceConclusionsDetails] = useState<InvoiceConclusionDetailsResult | null>(
        null
    );
    const [invoiceConclusionDetailsByPlu, setInvoiceConclusionsDetailsByPlu] =
        useState<InvoiceConclusionDetailsResult | null>(null);

    const [selectedTypeOrSubType, setSelectedTypeOrSubType] = useState<SelectedTypeOrSubTypeByPosTerminalId | null>(
        null
    );

    React.useEffect(() => {
        if (invoiceConclusion) {
            const loadData = async () => {
                const _invoiceConclusionsDetails = await getInvoiceConclusionDetails({
                    posTerminalId,
                    invoiceConclusionId: invoiceConclusion?.id,
                    group: 'type',
                });
                setInvoiceConclusionsDetails(_invoiceConclusionsDetails);
                setInvoiceConclusionsDetailsByPlu(null);
            };
            loadData().catch((err) => {
                console.log(err);
            });
        }
        // eslint-disable-next-line
    }, [invoiceConclusion, posTerminalId]);

    React.useEffect(() => {
        if (selectedTypeOrSubType && !invoiceConclusionDetailsByPlu && invoiceConclusion && posTerminalId) {
            const loadData = async () => {
                const _invoiceConclusionsDetailsByPlu = await getInvoiceConclusionDetails({
                    posTerminalId,
                    invoiceConclusionId: invoiceConclusion?.id,
                    group: 'plu',
                });
                setInvoiceConclusionsDetailsByPlu(_invoiceConclusionsDetailsByPlu);
            };
            loadData().catch((err) => {
                console.log(err);
            });
        }
        // eslint-disable-next-line
    }, [selectedTypeOrSubType, invoiceConclusionDetailsByPlu, invoiceConclusion, posTerminalId]);

    if (!invoiceConclusionsDetails) {
        return null;
    }

    return (
        <div className="m-auto">
            <PrintView
                items={
                    <div>
                        <div className="col-12 mt-2 mb-3">
                            <h5 className="mt-2 mb-2 pointer text-center uppercase p-2">
                                {<b>{translate('Invoiced')}</b>}{' '}
                            </h5>
                        </div>
                        <InvoiceConclusionDetailsHeader />
                        <InvoiceConclusionDetailsList
                            invoiceConclusionDetails={invoiceConclusionsDetails?.invoiced}
                            invoiceConclusionDetailsByPlu={invoiceConclusionDetailsByPlu?.invoiced}
                            setInvoiceConclusionsDetailsByPlu={setInvoiceConclusionsDetailsByPlu}
                            setSelectedTypeOrSubType={setSelectedTypeOrSubType}
                            selectedTypeOrSubType={selectedTypeOrSubType}
                            defaultCurrency={defaultCurrency}
                        />
                    </div>
                }
                documentHeader={<div className="col-12 mt-2"></div>}
                documentFooter={<div className="col-12 mb-3"></div>}
                footerHeight={52}
                headerHeight={52}
            />
            {/* <PrintView
                pageBreak={false}
                items={
                    <div>
                        <div className="col-12 mt-2 mb-3">
                            <h5 className="mt-2 mb-2 pointer text-center uppercase p-2">
                                {<b>{translate('proformaDispatched')}</b>}{' '}
                            </h5>
                        </div>
                        <InvoiceConclusionDetailsHeader />
                        <InvoiceConclusionDetailsList
                            invoiceConclusionDetails={invoiceConclusionsDetails?.proformaDispatched}
                            defaultCurrency={defaultCurrency}
                        />
                    </div>
                }
                documentHeader={<div className="col-12 mt-2"></div>}
                documentFooter={<div className="col-12 mb-3"></div>}
                footerHeight={52}
                headerHeight={52}
            /> */}
        </div>
    );
};

interface InvoiceConclusionDetailsListProps {
    invoiceConclusionDetails?: InvoiceConclusionDetailsModel[];
    invoiceConclusionDetailsByPlu?: InvoiceConclusionDetailsModel[];
    defaultCurrency: string;
    setInvoiceConclusionsDetailsByPlu: Function;
    setSelectedTypeOrSubType: Function;
    selectedTypeOrSubType: SelectedTypeOrSubTypeByPosTerminalId | null;
}

export const InvoiceConclusionDetailsList = (props: InvoiceConclusionDetailsListProps) => {
    const {
        invoiceConclusionDetails,
        invoiceConclusionDetailsByPlu,
        defaultCurrency,
        setSelectedTypeOrSubType,
        selectedTypeOrSubType,
    } = props;
    const groupedInvoiceConclusionsByTypeOfPlus = invoiceConclusionDetailsByPlu
        ? getGroupedInvoiceConclusionsByTypeOfPlus(invoiceConclusionDetailsByPlu)
        : null;
    const groupedInvoiceConclusionsBySubTypeOfPlus = invoiceConclusionDetailsByPlu
        ? getGroupedInvoiceConclusionsBySubTypeOfPlus(invoiceConclusionDetailsByPlu)
        : null;
    let total = 0.0;
    return (
        <React.Fragment>
            <ul className={'mt-2 mb-1 pt-3 border-top'} style={{ listStyle: 'none' }}>
                {invoiceConclusionDetails?.map((i) => {
                    if (i.type) {
                        total += Number(i.totalValueWithDiscount);
                    }
                    const _subtype = i.subtype ? i.subtype : 'null';
                    const invoiceConclusionsByPluAndType = i.type
                        ? groupedInvoiceConclusionsByTypeOfPlus?.[i.type]
                            ? groupedInvoiceConclusionsByTypeOfPlus[i.type]
                            : []
                        : [];

                    const invoiceConclusionsByPluAndSubtype = groupedInvoiceConclusionsBySubTypeOfPlus?.[
                        _subtype
                    ]?.filter((inc: InvoiceConclusionDetailsModel) => inc.type === 'posTerminal')
                        ? groupedInvoiceConclusionsBySubTypeOfPlus[_subtype].filter(
                              (inc: InvoiceConclusionDetailsModel) => inc.type === 'posTerminal'
                          )
                        : null;

                    // const price = i.total / i.quantity;
                    return (
                        <div
                            key={i.type ? i.type : i.subtype}
                            onClick={() => {
                                const newSelectedTypeOrSubTypeObj = selectedTypeOrSubType
                                    ? { ...selectedTypeOrSubType }
                                    : { [i.posTerminalId]: [] };
                                const targetSelectedTypeOrSubType = newSelectedTypeOrSubTypeObj[i.posTerminalId]
                                    ? newSelectedTypeOrSubTypeObj[i.posTerminalId]
                                    : [];

                                if (i.type) {
                                    if (targetSelectedTypeOrSubType?.includes(i.type)) {
                                        const newSelectedTypeOrSubType = targetSelectedTypeOrSubType.filter(
                                            (s) => s !== i.type
                                        );
                                        newSelectedTypeOrSubTypeObj[Number(i.posTerminalId)] = newSelectedTypeOrSubType;
                                        setSelectedTypeOrSubType(newSelectedTypeOrSubTypeObj);
                                    } else {
                                        const newSelectedTypeOrSubType = [...targetSelectedTypeOrSubType, i.type];
                                        newSelectedTypeOrSubTypeObj[Number(i.posTerminalId)] = newSelectedTypeOrSubType;
                                        setSelectedTypeOrSubType(newSelectedTypeOrSubTypeObj);
                                    }
                                } else {
                                    if (i.subtype) {
                                        if (targetSelectedTypeOrSubType?.includes(i.subtype)) {
                                            const newSelectedTypeOrSubType = targetSelectedTypeOrSubType.filter(
                                                (s) => s !== i.subtype
                                            );
                                            newSelectedTypeOrSubTypeObj[Number(i.posTerminalId)] =
                                                newSelectedTypeOrSubType;
                                            setSelectedTypeOrSubType(newSelectedTypeOrSubTypeObj);
                                        } else {
                                            const newSelectedTypeOrSubType = [
                                                ...targetSelectedTypeOrSubType,
                                                i.subtype,
                                            ];
                                            newSelectedTypeOrSubTypeObj[Number(i.posTerminalId)] =
                                                newSelectedTypeOrSubType;
                                            setSelectedTypeOrSubType(newSelectedTypeOrSubTypeObj);
                                        }
                                    } else {
                                        if (targetSelectedTypeOrSubType.includes('null')) {
                                            const newSelectedTypeOrSubType = targetSelectedTypeOrSubType.filter(
                                                (s) => s !== 'null'
                                            );
                                            newSelectedTypeOrSubTypeObj[Number(i.posTerminalId)] =
                                                newSelectedTypeOrSubType;
                                            setSelectedTypeOrSubType(newSelectedTypeOrSubTypeObj);
                                        } else {
                                            const newSelectedTypeOrSubType = [...targetSelectedTypeOrSubType, 'null'];
                                            newSelectedTypeOrSubTypeObj[Number(i.posTerminalId)] =
                                                newSelectedTypeOrSubType;
                                            setSelectedTypeOrSubType(newSelectedTypeOrSubTypeObj);
                                        }
                                    }
                                }
                            }}
                        >
                            <li className={i.type ? 'pt-2 pb-1' : 'pt-1 pb-1'}>
                                <div className="row d-flex justify-content-between">
                                    <div
                                        style={i.type ? { fontWeight: 500 } : {}}
                                        className={i.type ? 'col-6' : 'col-6 pl-4'}
                                    >
                                        {translate(i.type ? PosItemTypes[i.type as keyof typeof PosItemTypes] : '')}
                                        {i.subtype
                                            ? `${translate(PosItemSubTypes[i.subtype as keyof typeof PosItemSubTypes])}`
                                            : i.type === null
                                            ? `${translate('Other')}`
                                            : ''}
                                    </div>

                                    {/* <div className="col-4 col-sm-3">
                                    {numbro(price).format({
                                        mantissa: 2,
                                        trimMantissa: false,
                                        thousandSeparated: true,
                                    })}{' '}
                                    {defaultCurrency}
                                </div>
                                <div className="col-4 col-sm-3">
                                    {' '}
                                    {numbro(i.quantity).format({
                                        mantissa: 2,
                                        trimMantissa: false,
                                        thousandSeparated: true,
                                    })}
                                </div> */}

                                    <div className="col-6 text-right" style={i.type ? { fontWeight: 500 } : {}}>
                                        {numbro(Number(i.totalValueWithDiscount)).format({
                                            mantissa: 2,
                                            trimMantissa: false,
                                            thousandSeparated: true,
                                        })}{' '}
                                        {defaultCurrency}
                                    </div>
                                </div>
                            </li>

                            {invoiceConclusionDetailsByPlu &&
                            i.type &&
                            selectedTypeOrSubType &&
                            selectedTypeOrSubType[Number(i.posTerminalId)] &&
                            selectedTypeOrSubType[Number(i.posTerminalId)].includes(i.type) ? (
                                <div>
                                    <InvoiceConclusionDetailsByPluHeader />
                                    <InvoiceConclusionDetailsListByPlu
                                        invoiceConclusionDetails={invoiceConclusionsByPluAndType}
                                        defaultCurrency={defaultCurrency}
                                    />
                                </div>
                            ) : null}
                            {invoiceConclusionsByPluAndSubtype &&
                            !i.type &&
                            selectedTypeOrSubType &&
                            selectedTypeOrSubType[Number(i.posTerminalId)] &&
                            selectedTypeOrSubType[Number(i.posTerminalId)].includes(i.subtype ? i.subtype : 'null') ? (
                                <div>
                                    <InvoiceConclusionDetailsByPluHeader />
                                    <InvoiceConclusionDetailsListByPlu
                                        invoiceConclusionDetails={invoiceConclusionsByPluAndSubtype}
                                        defaultCurrency={defaultCurrency}
                                    />
                                </div>
                            ) : null}
                        </div>
                    );
                })}
            </ul>
            <ul className={'mt-2 mb-1 pt-3 border-top'} style={{ listStyle: 'none' }}>
                <li className={'p-0'}>
                    <div className="row d-flex justify-content-between">
                        <div className="col-6">
                            <b></b>
                        </div>
                        {/* <div className="col-4 col-sm-3">
                            <b></b>
                        </div>
                        <div className="col-4 col-sm-2">
                            <b></b>
                        </div> */}

                        {/* <div className="col-4 col-sm-2">
                            <b></b>
                        </div> */}
                        <div className="col-6 text-right">
                            <b>
                                {numbro(total ? Number(total) : 0).format({
                                    mantissa: 2,
                                    trimMantissa: false,
                                    thousandSeparated: true,
                                })}{' '}
                                {defaultCurrency}
                            </b>
                        </div>
                    </div>
                </li>
            </ul>
        </React.Fragment>
    );
};

export const InvoiceConclusionDetailsHeader = () => {
    return (
        <div className="">
            <ul className={'mt-2 mb-1 pt-3'} style={{ listStyle: 'none' }}>
                <li className={'p-0'}>
                    <div className="row d-flex justify-content-between">
                        <div className="col-6">
                            <b> {translate('Type')} </b>
                        </div>
                        {/* <div className="col-4 col-sm-3">
                            <b> {translate('Price')} </b>
                        </div>
                        <div className="col-4 col-sm-3">
                            <b> {translate('Quantity')} </b>
                        </div> */}
                        <div className="col-6 text-right">
                            <b> {translate('Total')} </b>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    );
};

const InvoiceConclusionListHeader = () => {
    return (
        <div className="custom-list-header">
            <div className="" style={{ maxWidth: '5%' }}>
                {translate('ID')}
            </div>
            <div className="">{translate('Time')}</div>
            <div className="">{translate('Operater')}</div>
            {/* <div className="">{translate('Shift manager')}</div> */}
            <div className="">{translate('Initial cash balance')}</div>
            {/* <div className="">{translate('Cash turnover')}</div> */}
            <div className="">{translate('POS terminal')}</div>
            <div className="">{translate('Note')}</div>

            <div className="" style={{ minWidth: '12%' }}></div>
        </div>
    );
};

interface InvoiceConclusionListProps {
    invoiceConclusions: InvoiceConclusionModel[];
    defaultCurrency: string;
    setInvoiceConclusionDetails: Function;
    setShowConclusionReport: Function;
    setShowPosItemDetails: Function;
    locale: string;
    setInvoiceReconciliationReport: Function;
    invoiceReconciliationReport: InvoiceConclusionModel | null;
    setInvoiceConclusionReports: Function;
}
const InvoiceConclusionList = (props: InvoiceConclusionListProps) => {
    const {
        invoiceConclusions,
        // defaultCurrency,
        setInvoiceConclusionDetails,
        setShowConclusionReport,
        setShowPosItemDetails,
        setInvoiceReconciliationReport,
        invoiceReconciliationReport,
        locale,
        setInvoiceConclusionReports,
    } = props;

    const [editInvoiceConclusion, setEditInvoiceConclusion] = React.useState<InvoiceConclusionModel | null>(null);

    return (
        <div className="">
            <InlineModal
                show={editInvoiceConclusion}
                header={translate(editInvoiceConclusion?.note ? translate('Edit note') : translate('New note'))}
                width="lg"
                setShow={setEditInvoiceConclusion}
            >
                <div className="mt-16p">
                    <textarea
                        autoFocus
                        rows={4}
                        value={editInvoiceConclusion?.note ? editInvoiceConclusion.note : ''}
                        className="form-control form-control-sm"
                        onChange={(e) => {
                            if (editInvoiceConclusion) {
                                const newInvoiceConslusionCandidate = { ...editInvoiceConclusion };
                                newInvoiceConslusionCandidate.note = e.target.value;
                                setEditInvoiceConclusion(newInvoiceConslusionCandidate);
                            }
                        }}
                    />
                </div>
                <hr />
                <ConfirmButtons
                    onConfirm={async () => {
                        try {
                            if (editInvoiceConclusion) {
                                await updateInvoiceConslusion(editInvoiceConclusion);
                                if (editInvoiceConclusion.isPending) {
                                    setInvoiceReconciliationReport(editInvoiceConclusion);
                                } else {
                                    const newInvoiceConclusionReports = invoiceConclusions.map((i) => {
                                        if (i.id === editInvoiceConclusion.id) {
                                            return editInvoiceConclusion;
                                        } else {
                                            return i;
                                        }
                                    });
                                    setInvoiceConclusionReports(newInvoiceConclusionReports);
                                }
                                setEditInvoiceConclusion(null);
                            }
                        } catch (error: any) {
                            console.log(error);
                        }
                    }}
                    onCancel={() => {
                        setEditInvoiceConclusion(null);
                    }}
                />
            </InlineModal>
            {invoiceConclusions.map((i) => {
                return (
                    <div
                        key={i?.id}
                        className="custom-list-item hover-disabled"
                        onClick={() => {
                            //  setInvoiceConclusionDetails(i);
                        }}
                    >
                        <div className="" style={{ maxWidth: '5%' }}>
                            {i.id}
                        </div>
                        <div className="">{moment(i.timestamp).locale(locale).format('lll')}</div>
                        <div className="">{i.operatorName}</div>
                        {/* <div className="">
                            <input
                                value={i?.shiftManager ? i.shiftManager : ''}
                                type="text"
                                disabled={i.isPending ? false : true}
                                className="form-control form-control-sm"
                                onChange={(e) => {
                                    if (i.isPending && invoiceReconciliationReport) {
                                        const newInvoiceConslusionCandidate = { ...invoiceReconciliationReport };
                                        newInvoiceConslusionCandidate.shiftManager = e.target.value;
                                        setInvoiceReconciliationReport(newInvoiceConslusionCandidate);
                                    }
                                }}
                            />
                        </div> */}
                        <div className="">
                            <input
                                value={i?.cashDeposit ? i.cashDeposit : ''}
                                type="number"
                                disabled={i.isPending ? false : true}
                                className="form-control form-control-sm"
                                onChange={(e) => {
                                    if (i.isPending && invoiceReconciliationReport) {
                                        const newInvoiceConslusionCandidate = { ...invoiceReconciliationReport };
                                        newInvoiceConslusionCandidate.cashDeposit = Number(e.target.value);
                                        setInvoiceReconciliationReport(newInvoiceConslusionCandidate);
                                    }
                                }}
                            />
                        </div>
                        {/* <div className="">
                            <input
                                value={i?.cashWithdrawal ? i.cashWithdrawal : ''}
                                type="number"
                                disabled={i.isPending ? false : true}
                                className="form-control form-control-sm"
                                onChange={(e) => {
                                    if (i.isPending && invoiceReconciliationReport) {
                                        const newInvoiceConslusionCandidate = { ...invoiceReconciliationReport };
                                        newInvoiceConslusionCandidate.cashWithdrawal = Number(e.target.value);
                                        setInvoiceReconciliationReport(newInvoiceConslusionCandidate);
                                    }
                                }}
                            />
                        </div> */}
                        <div className="truncated-inline-block">{i.posTerminalName}</div>
                        <div className="">
                            <div
                                className="button-light"
                                onClick={() => {
                                    setEditInvoiceConclusion(i);
                                }}
                            >
                                {translate('Edit')}
                            </div>
                        </div>

                        <div className="display-flex" style={{ minWidth: '12%' }}>
                            <div
                                onClick={async () => {
                                    setInvoiceConclusionDetails(i);
                                    setShowPosItemDetails(true);
                                    setShowConclusionReport(false);
                                }}
                                className="button-light mr-4p"
                            >
                                {translate('Details')}
                            </div>
                            <div
                                onClick={async () => {
                                    setInvoiceConclusionDetails(i);
                                    setShowPosItemDetails(false);
                                    setShowConclusionReport(true);
                                }}
                                className="button-light"
                            >
                                {translate('View Report')}
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

interface InlineModalReportsProps {
    setInvoiceConclusionReports: Function;
    setInvoiceReconciliationReport: Function;
    reportType: string | null;
    posTerminalId: number;
    setReportType: Function;
    invoiceConclusionReports: InvoiceConclusionModel[];
    invoiceReconciliationReport: InvoiceConclusionModel | null;
}

const InlineModalReports = (props: InlineModalReportsProps) => {
    const {
        posTerminalId,
        setInvoiceConclusionReports,
        setInvoiceReconciliationReport,
        reportType,
        setReportType,
        invoiceConclusionReports,
        invoiceReconciliationReport,
    } = props;

    return (
        <div className="container-fluid ">
            <div className="mt-3 mb-3 text-center">
                <ConfirmButtons
                    onConfirm={async () => {
                        try {
                            if (posTerminalId) {
                                if (reportType === 'zReport' && invoiceReconciliationReport) {
                                    const invoiceConclusionReport = await createInvoiceConclusion(
                                        invoiceReconciliationReport,
                                        reportType
                                    );
                                    const newInvoiceConclusions = [
                                        invoiceConclusionReport,
                                        ...invoiceConclusionReports,
                                    ];
                                    setInvoiceConclusionReports(newInvoiceConclusions);
                                    setInvoiceReconciliationReport(null);
                                } else if (reportType === 'xReport') {
                                    const _invoiceReconciliationReport = await getInvoiceReconciliation(
                                        posTerminalId,
                                        reportType
                                    );
                                    setInvoiceReconciliationReport(_invoiceReconciliationReport);
                                }
                                setReportType(null);
                            }
                        } catch (error: any) {
                            console.log(error);
                            setReportType(null);
                        }
                    }}
                    onCancel={() => {
                        setReportType(null);
                    }}
                />
            </div>
        </div>
    );
};

interface InvoiceConclusionDetailsListByPluProps {
    invoiceConclusionDetails: InvoiceConclusionDetailsModel[];
    defaultCurrency: string;
}

const InvoiceConclusionDetailsListByPlu = (props: InvoiceConclusionDetailsListByPluProps) => {
    const { invoiceConclusionDetails, defaultCurrency } = props;
    const [showNormatives, setShowNormatives] = React.useState<InvoiceConclusionDetailsModel | null>(null);
    let total = 0.0;

    return (
        <React.Fragment>
            {invoiceConclusionDetails.map((i, index) => {
                total += Number(i.totalValueWithDiscount);
                return (
                    <React.Fragment key={index}>
                        <div
                            className="custom-list-print custom-list-item-no-hover pointer"
                            onClick={() => {
                                if (
                                    showNormatives &&
                                    showNormatives.plu === i.plu &&
                                    showNormatives.pluPrice === i.pluPrice
                                ) {
                                    setShowNormatives(null);
                                } else {
                                    setShowNormatives(i);
                                }
                            }}
                        >
                            <div className="custom-list-item">
                                <div className="">{i.plu}</div>
                                <div className="">{i.name}</div>
                                <div className="">
                                    {numbro(Number(i.pluPrice)).format({
                                        mantissa: 2,
                                        trimMantissa: false,
                                        thousandSeparated: true,
                                    })}{' '}
                                    {defaultCurrency}
                                </div>
                                <div className="">
                                    {' '}
                                    {numbro(Number(i.quantity)).format({
                                        mantissa: 2,
                                        trimMantissa: false,
                                        thousandSeparated: true,
                                    })}
                                </div>
                                <div className="">
                                    {numbro(Number(i.totalValue)).format({
                                        mantissa: 2,
                                        trimMantissa: false,
                                        thousandSeparated: true,
                                    })}{' '}
                                    {defaultCurrency}
                                </div>
                                <div className="">
                                    {numbro(Number(i.totalValue) - Number(i.totalValueWithDiscount)).format({
                                        mantissa: 2,
                                        trimMantissa: false,
                                        thousandSeparated: true,
                                    })}{' '}
                                    {defaultCurrency}
                                </div>
                                <div className="">
                                    {numbro(Number(i.totalValueWithDiscount)).format({
                                        mantissa: 2,
                                        trimMantissa: false,
                                        thousandSeparated: true,
                                    })}{' '}
                                    {defaultCurrency}
                                </div>
                                <div className="">
                                    {numbro(Number(i.taxTotal)).format({
                                        mantissa: 2,
                                        trimMantissa: false,
                                        thousandSeparated: true,
                                    })}{' '}
                                    {defaultCurrency}
                                </div>
                                <div className="">
                                    {numbro(Number(i.skuTotal)).format({
                                        mantissa: 2,
                                        trimMantissa: false,
                                        thousandSeparated: true,
                                    })}{' '}
                                    {defaultCurrency}
                                </div>
                                <div className="">
                                    {numbro(Number(i.dailyPriceAdjustments)).format({
                                        mantissa: 2,
                                        trimMantissa: false,
                                        thousandSeparated: true,
                                    })}{' '}
                                    {defaultCurrency}
                                </div>
                            </div>
                        </div>
                        {/* {showNormatives &&
                        showNormatives.plu === i.plu &&
                        showNormatives.pluPrice === i.pluPrice &&
                        showNormatives.normativeRecapitulations.length > 0 ? (
                            <div className="w-50 sku-list-item mb-4p">
                                <NormativeHeader />
                                <NormativeItems
                                    normativeRecapitulations={showNormatives.normativeRecapitulations}
                                    defaultCurrency={defaultCurrency}
                                />
                            </div>
                        ) : null} */}
                    </React.Fragment>
                );
            })}
            <div className="custom-list-print custom-list-item-no-hover" style={{ backgroundColor: '#eff2f9' }}>
                <div className="custom-list-item " style={{ backgroundColor: '#eff2f9' }}>
                    <div className="">
                        <b></b>
                    </div>
                    <div className="">
                        <b></b>
                    </div>
                    <div className="">
                        <b></b>
                    </div>

                    <div className="">
                        <b></b>
                    </div>

                    <div className="">
                        <b></b>
                    </div>
                    <div className="">
                        <b></b>
                    </div>
                    <div className="">
                        <b>
                            {numbro(total).format({
                                mantissa: 2,
                                trimMantissa: false,
                                thousandSeparated: true,
                            })}{' '}
                            {defaultCurrency}
                        </b>
                    </div>
                    <div className="">
                        <b></b>
                    </div>
                    <div className="">
                        <b></b>
                    </div>
                    <div className="">
                        <b></b>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

const InvoiceConclusionDetailsByPluHeader = () => {
    return (
        <div className="custom-list-print">
            <div className={'custom-list-header'}>
                <div className="">
                    <b> {translate('PLU')} </b>
                </div>
                <div className="">
                    <b> {translate('Name')} </b>
                </div>
                <div className="">
                    <b> {translate('Price')} </b>
                </div>
                <div className="">
                    <b> {translate('Quantity')} </b>
                </div>
                <div className=" text-right">
                    <b> {translate('Total value')} </b>
                </div>
                <div className=" text-right">
                    <b> {translate('Discount')} </b>
                </div>
                <div className="">
                    <b> {translate('Invoiced revenue')} </b>
                </div>
                <div className="">
                    <b> {translate('Tax total')} </b>
                </div>
                <div className="">
                    <b> {translate('Stock value')} </b>
                </div>
                <div className=" text-right">
                    <b> {translate('Stock value adjustment')} </b>
                </div>
            </div>
        </div>
    );
};

export default InvoiceConclusion;
