import * as React from 'react';
import { translate } from '@data/translations';
import { Reservation, ReservationController } from '../reservations2/resController';
import {
    ReservationModel,
    ReservationInvoicedStatus,
    ReservationStatus,
} from '@common/modelDefinition';
import MiniLoader from '@components/loaders/miniLoader';
import ReservationGuestReference from './reservationGuestReference';
import RouteLeavingGuard from './closingFormGuard';
import ReservationNotifications from './reservationNotifications';
//@ts-ignore
import { branch } from 'baobab-react/higher-order';
import MainReservationInfo from './mainReservationInfo';
import MinibarConsumption from './minibarConsumption';
import PosTerminalConsumption from './posTerminalConsumption';
import SelfCheckIn from './selfCheckIn';
import { ReactComponent as ChevronDown } from '../../assets/custom/icons/chevron_down.svg';
import { ReactComponent as Bell } from '../../assets/custom/icons/bell.svg';
import { ReactComponent as Edit } from '../../assets/custom/icons/edit.svg';
import { ReactComponent as Change } from '../../assets/custom/icons/change.svg';
import { ReactComponent as DeleteIcon } from '../../assets/custom/icons/trash.svg';
import { ReactComponent as Info } from '../../assets/custom/icons/info.svg';

import CondoOwnerRestrictionWrapper from '@pages/reservations2/CondoOwnerRestrictionWrapper';
import { closeModal, setModalContent } from '@components/modal';
import FolderBox from '@components/documentTools/folderBox';
import ShowAvailableRoomsForReservationModal from '../reservations2/ChangeRoomModal';
import PermissionCheck from '@components/PermissionCheck';
import getRoomSRCStatusIcon, { getRoomSRCStatus } from '@data/controllers/SRCStatus';
import BasicSRCSettings from '../roomInfo/components/basicSRCSettingsModal';
import { reservationStatusEnumsDesc } from '@data/reservationStatusEnums';
import ConfirmButtons from '@components/buttons/confirmButtons';
import InlineModal from '@components/modalInline';
import HouseholdTask from './householdTask';

interface TabsBodyProps {
    history: any;
    isPending: boolean;
    loading: boolean;
    setLoading: Function;
    reservations: Reservation[];
    acp: any;
    showReservationDetails: Function;
    otherSettings: any;
}

const ReservationLinkTabs: any = {
    0: 'General',
    1: 'Guest info',
    2: 'Minibar',
    3: 'Terminal',
    4: 'Self check in',
    5:'Household',
};

enum ReservationLinkTabsEnum {
    'general' = 0,
    'guestInfo' = 1,
    'minibar' = 2,
    'terminal' = 3,
    'selfCheckIn' = 4,
    'household' =5,
}

function TabsBody(props: TabsBodyProps) {
    const { history, reservations, showReservationDetails } = props;
    const [activeTab, setActiveTab] = React.useState(0);
    const [showNoteModal, setShowNoteModal] = React.useState(false as boolean);
    const [showMoreOptions, setShowMoreOptions] = React.useState(false as boolean);
    const ctrl = new ReservationController();

    const reservation = ctrl.getActiveReservation();
    const groupRes = ctrl.getGroupReservation();

    const onFolderUpdated = async (documents: any) => {
        const res = { ...reservation };
        res.documents = documents;
        ctrl.updateActiveReservation(res as ReservationModel);
    };

    if (!reservation || !reservation.RoomInfo) {
        return <MiniLoader />;
    }

    // const style = loading ? { opacity: '0.4' } : {};
    // const unEditable = loading ? 'unEditable' : '';
    let isGroupInvoiceIssued = false;
    if (groupRes && groupRes.invoicedStatus !== ReservationInvoicedStatus.notPaid) {
        isGroupInvoiceIssued = true;
    }

    let disableSettingButton = true;

    const roomSRCStatusIcon = getRoomSRCStatusIcon(reservation?.RoomInfo);

    const checkInToday = reservation
        ? new Date(reservation.checkInTimestamp).toISOString().substr(0, 10) === new Date().toISOString().substr(0, 10)
        : false;

    if (
        reservation.statusEnum === ReservationStatus.checkedIn ||
        reservation.statusEnum === ReservationStatus.confirmed
    ) {
        disableSettingButton = false;
    } else if (checkInToday) {
        disableSettingButton = false;
    }

    //disable if not connected
    if (getRoomSRCStatus(reservation?.RoomInfo) >= 3) {
        disableSettingButton = true;
    }

    return (
        <>
            <CondoOwnerRestrictionWrapper history={history} reservation={reservation}>
                <RouteLeavingGuard history={history} />
                <div className="reservation-body-wrapper mt-2p mb-8p">
                    <InlineModal
                        show={showNoteModal}
                        header={translate('Add document')}
                        width="md"
                        setShow={setShowNoteModal}
                    >
                        <div>
                            <FolderBox onFolderUpdated={onFolderUpdated} {...reservation} />
                        </div>
                    </InlineModal>
                   
                    <div className="display-flex align-items-center justify-content-between mb-8p">
                        <div className="display-flex" style={{ flexDirection: 'column' }}>
                            <div className="reservation-room">
                                {translate('Room')} {reservation?.RoomInfo?.name} (
                                {translate(reservationStatusEnumsDesc[reservation.statusEnum])})
                            </div>
                            <div className="mt-4p text-bold color-warning">
                                {reservation?.providerReservation ? translate('Provider reservation') : ''}
                            </div>
                        </div>
                        <div className="display-flex">
                            {((groupRes && groupRes?.Reservations) || reservation?.id) && (
                                <div>
                                    <div
                                        className="button-info mr-4p pointer pb-8p"
                                        onClick={() => {
                                            if (groupRes && groupRes.uuid) {
                                                history.push('/issueGroupInvoice/' + groupRes.uuid);
                                            } else {
                                                const reservation = ctrl.getReservations()[0];
                                                history.push('/checkoutAndInvoice/' + reservation.id);
                                            }
                                        }}
                                    >
                                        {/* <i className="fa fa-print" />  */}
                                        {translate('Invoices')}
                                    </div>
                                </div>
                            )}
                            {reservation?.RoomInfo?.remoteDeviceId === null ? null : (
                                <div className="mr-4p pointer">
                                    <PermissionCheck name="roomInfo_roomSettings">
                                        <button
                                            disabled={disableSettingButton}
                                            title={translate('Room settings')}
                                            onClick={() => {
                                                const roomName = reservation?.RoomInfo?.name;
                                                const basicSRCSettings = (
                                                    <BasicSRCSettings
                                                        roomInfoId={reservation?.RoomInfo?.id}
                                                        key={reservation?.RoomInfo?.id}
                                                        reservation={reservation}
                                                        remoteDeviceId={reservation?.RoomInfo?.remoteDeviceId}
                                                    />
                                                );
                                                setModalContent(
                                                    basicSRCSettings,
                                                    <span>
                                                        {translate('Room settings') + ' - '} <b>{roomName}</b>
                                                    </span>,
                                                    true,
                                                    'modal-xl'
                                                );
                                            }}
                                            className={
                                                (reservation?.RoomInfo?.remoteDeviceId === null ||
                                                roomSRCStatusIcon === null
                                                    ? 'button-light  disabled mb-2 '
                                                    : 'button-light   mb-2') + ' mr-1'
                                            }
                                            style={{ padding: 0 }}
                                        >
                                            <span className="d-inline-flex">{roomSRCStatusIcon} </span>
                                            <i className="fa fa-cog" style={{ paddingRight: '6px' }} />
                                        </button>
                                    </PermissionCheck>
                                </div>
                            )}
                            <div
                                className="mr-4p pointer"
                                onClick={() => {
                                    setModalContent(
                                        <ReservationNotifications history={history} reservation={reservation} />,
                                        translate('Reservation notifications'),
                                        false,
                                        'modal-lg'
                                    );
                                }}
                            >
                                <Bell />
                            </div>
                            {reservation?.id && showReservationDetails ? (
                                <div
                                    className="mr-4p pointer"
                                    onClick={() => {
                                        if (showReservationDetails && reservation?.id) {
                                            showReservationDetails(reservation?.id);
                                        }
                                    }}
                                >
                                    <Info />
                                </div>
                            ) : null}
                            <div className="pointer" onClick={() => setShowMoreOptions(!showMoreOptions)}>
                                <ChevronDown />
                                {showMoreOptions ? (
                                    <div className="more-options-wrapper shadow-sm">
                                        <div
                                            className="more-options"
                                            onClick={() => {
                                                setShowNoteModal(true);
                                            }}
                                        >
                                            <div className=" mr-8p">
                                                <Edit />
                                            </div>
                                            <div>{translate('Add document')}</div>
                                        </div>
                                        <div
                                            className="more-options"
                                            onClick={() => {
                                                setModalContent(
                                                    <ShowAvailableRoomsForReservationModal
                                                        ctrl={ctrl}
                                                        reservation={{ ...reservation }}
                                                    />,
                                                    translate('Confirm ') + translate('change room'),
                                                    false,
                                                    'modal-lg'
                                                );
                                            }}
                                        >
                                            <div className=" mr-8p">
                                                <Change />
                                            </div>
                                            <div>{translate('Change room')}</div>
                                        </div>
                                        <hr
                                            style={{
                                                width: '90%',
                                                paddingRight: '5px',
                                                paddingLeft: '5px',
                                                borderTop: '1px solid lightgray',
                                                marginTop: '5px',
                                                marginBottom: '5px',
                                            }}
                                        />
                                        {reservation.groupUuid && reservation.id && !isGroupInvoiceIssued ? (
                                            <div
                                                className="more-options color-error"
                                                onClick={() =>
                                                    setModalContent(
                                                        <div className="container-fluid">
                                                            <div className="text-center">
                                                                {translate(
                                                                    'Do you really want to remove this reservation from group? Please confirm and reservation will become standalone!'
                                                                )}
                                                                <hr />
                                                            </div>
                                                            <div className="text-center">
                                                                <ConfirmButtons
                                                                    onConfirm={async () => {
                                                                        await ctrl.removeFromGroupRes();
                                                                        // setTabIndex(0);
                                                                        //vidi da samo sejvaš ovaj detalj!!!
                                                                        // console.log(
                                                                        //     'JOŠ POSLA OVDJE IMA. Hoću li prikazati samo izbačenu rezervaciju ili šta?'
                                                                        // );
                                                                        // console.log('Prikazem izbacenu rezervaciju.');
                                                                        closeModal();
                                                                    }}
                                                                    onCancel={() => {
                                                                        closeModal();
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>,
                                                        translate('Remove from group'), // header
                                                        false, // showCloseButton
                                                        'modal-md'
                                                    )
                                                }
                                            >
                                                <div className=" mr-8p fill-red">
                                                    <DeleteIcon />
                                                </div>
                                                <div>{translate('Remove from group')}</div>
                                            </div>
                                        ) : reservation.id &&
                                          !reservation.groupUuid &&
                                          !reservation.previousGroupUuid &&
                                          ![4, 5].includes(reservation.statusEnum) ? (
                                            <div
                                                className="more-options color-error"
                                                onClick={() =>
                                                    history.push('/groupInfos?reservationId=' + reservation.id)
                                                }
                                            >
                                                <div className=" mr-8p fill-red">
                                                    <svg width="32" height="32"></svg>
                                                </div>
                                                <div>{translate('Attach to group')}</div>
                                            </div>
                                        ) : null}
                                        {reservation.groupUuid &&
                                        reservation.id &&
                                        reservations?.every((r) => r.statusEnum === 1 && !isGroupInvoiceIssued) ? (
                                            <div
                                                className="more-options color-error"
                                                onClick={() =>
                                                    setModalContent(
                                                        <div className="container-fluid">
                                                            <div className="text-center">
                                                                {translate(
                                                                    'Do you really want to cancel all this reservations?'
                                                                )}
                                                                <hr />
                                                            </div>
                                                            <div className="text-center">
                                                                <ConfirmButtons
                                                                    onConfirm={async () => {
                                                                        for (let i = 0; i < reservations.length; i++) {
                                                                            if (reservations[i]) {
                                                                                ctrl.setActiveReservationIndex(i);
                                                                                let res = { ...reservations[i] };
                                                                                res.statusEnum =
                                                                                    ReservationStatus.canceled;
                                                                                ctrl.updateActiveReservation(
                                                                                    res as ReservationModel
                                                                                );
                                                                            }
                                                                        }
                                                                        closeModal();
                                                                    }}
                                                                    onCancel={() => {
                                                                        closeModal();
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>,
                                                        translate('Cancel all reservations'), // header
                                                        false, // showCloseButton
                                                        'modal-md'
                                                    )
                                                }
                                            >
                                                <div className=" mr-8p fill-red">
                                                    <svg width="32" height="32"></svg>
                                                </div>
                                                <div>{translate('Cancel all reservations')}</div>
                                            </div>
                                        ) : null}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>

                    <div className="reservation-form-tabs">
                        {Object.keys(ReservationLinkTabs)?.map((t: string, index: number) => {
                            return (
                                <div
                                    onClick={() => {
                                        if (reservation?.id) {
                                            setActiveTab(index);
                                        }
                                    }}
                                    className={
                                        ' reservation-form-tabs-single ' + (activeTab === index ? ' active-tab ' : '')
                                    }
                                    style={{ color: reservation?.id ? 'black' : '' }}
                                    key={index}
                                >
                                    {translate(ReservationLinkTabs[t])}
                                </div>
                            );
                        })}
                    </div>

                    {activeTab === ReservationLinkTabsEnum.general ? (
                        <MainReservationInfo history={history} otherSettings={props.otherSettings} />
                    ) : null}

                    {activeTab === ReservationLinkTabsEnum.guestInfo && reservation?.id !== undefined ? (
                        <ReservationGuestReference
                            history={history}
                            reservation={reservation}
                            otherSettings={props.otherSettings}
                        />
                    ) : null}

                    {activeTab === ReservationLinkTabsEnum.minibar && reservation?.id !== undefined ? (
                        <MinibarConsumption history={history} reservation={reservation} />
                    ) : null}
                        {activeTab === ReservationLinkTabsEnum.household && reservation?.id !== undefined ? (
                        <HouseholdTask history={history} reservation={reservation} />
                    ) : null}

                    {activeTab === ReservationLinkTabsEnum.terminal && reservation?.id !== undefined ? (
                        <PosTerminalConsumption history={history} reservation={reservation} />
                    ) : null}

                    {activeTab === ReservationLinkTabsEnum.selfCheckIn && reservation?.id !== undefined ? (
                        <SelfCheckIn />
                    ) : null}
                </div>
            </CondoOwnerRestrictionWrapper>
        </>
    );
}

export default branch(
    {
        reservations: ['state', 'reservationForm2', 'reservations'],
        isPending: ['state', 'reservationForm2', 'isPending'],
        acp: ['model', 'AccommodationPriceList'],
        otherSettings: ['otherSettings'],
    },
    TabsBody
);
