import Baobab from 'baobab';
import _ from 'lodash';
import parsedAccommodationPriceLists from '../controllers/accommodationPriceLists';
var monkey = Baobab.monkey;

var parseRoomInfos = (roomInfoDataSet, SRCEnums) => {
    let parsedRoomInfo = [];
    _.forEach(roomInfoDataSet, (r) => {
        let tmp = _.clone(r);
        if (r.SRCSettings) {
            tmp.SRCSettings = JSON.parse(r.SRCSettings);
        }
        if (r.SRCIOStates && SRCEnums.ioNamedEnum) {
            tmp.SRCStates = {
                openInputsAndClosedOutputs: [],
            };
            let ioKeys = Object.keys(SRCEnums.ioNamedEnum);
            for (let i = 0; i < ioKeys.length; i++) {
                let isInput = _.includes(SRCEnums.inputs, ioKeys[i]); //else are output
                let inputOpenOutputClosed = r.SRCIOStates & (1 << (31 - i));
                if (isInput) {
                    if (!inputOpenOutputClosed) {
                        //CLOSED INPUT DETECTED
                        tmp.SRCStates.openInputsAndClosedOutputs.push(ioKeys[i]);
                        //console.log("INPUT", ioKeys[i], inputOpenOutputClosed, r.SRCIOStates);
                    }
                } else {
                    if (inputOpenOutputClosed) {
                        //closed output detected
                        tmp.SRCStates.openInputsAndClosedOutputs.push(ioKeys[i]);
                        //console.log("OUTPUT", ioKeys[i], inputOpenOutputClosed, r.SRCIOStates);
                    }
                }
            }
        }
        if (r.params) {
            tmp.params = JSON.parse(r.params);
        }

        tmp.tags = _.isString(r.tags)
            ? _.map(r.tags.split(';'), (s) => {
                  return s.trim();
              })
            : [];
        parsedRoomInfo.push(tmp);
    });
    return parsedRoomInfo;
};

var monkeys = {
    parsedAccommodationPriceLists: monkey({
        cursors: {
            accommodationPriceLists: ['model', 'AccommodationPriceList'],
            timelineYearsCount: ['uiStates', 'accommodationPriceListStack', 'timelineYearsCount'],
            startFromYear: ['uiStates', 'accommodationPriceListStack', 'startFromYear'],
        },
        get: (data) => {
            //return parsedAccommodationPriceLists(data.accommodationPriceLists);
            return parsedAccommodationPriceLists(
                data.accommodationPriceLists,
                data.startFromYear,
                data.timelineYearsCount
            );
        },
    }),
    parsedRoomInfo: monkey({
        cursors: {
            roomInfo: ['model', 'RoomInfo'],
            SRCEnums: ['SRCEnums'],
        },
        get: (data) => {
            return parseRoomInfos(data.roomInfo, data.SRCEnums);
        },
    }),
    parsedAndTagedRoomInfo: monkey({
        cursors: {
            roomInfo: ['model', 'RoomInfo'],
            SRCEnums: ['SRCEnums'],
        },
        get: (data) => {
            let parsedData = parseRoomInfos(data.roomInfo, data.SRCEnums);
            let response = [];
            let tags = _.uniq(_.flatten(_.map(parsedData, 'tags')));
            _.forEach(tags, (tag) => {
                response.push({
                    tag,
                    rooms: _.filter(parsedData, (p) => {
                        return _.includes(p.tags, tag);
                    }),
                });
            });
            response.push({
                tag: null,
                rooms: _.filter(parsedData, (p) => {
                    return p.tags.length === 0;
                }),
            });
            return _.orderBy(response, (r) => r.rooms.length, 'desc');
        },
    }),
    roomTags: monkey({
        cursors: {
            roomInfo: ['model', 'RoomInfo'],
            SRCEnums: ['SRCEnums'],
        },
        get: (data) => {
            let parsedData = parseRoomInfos(data.roomInfo, data.SRCEnums);
            let tags = _.uniq(_.flatten(_.map(parsedData, 'tags')));
            return tags;
        },
    }),
    companyInfo: monkey({
        cursors: {
            licence: ['licence'],
            companyInfo: ['companyInfo'],
        },
        get: (data) => {
            let companyInfo = _.clone(data.companyInfo);
            let decodedLicence = {};
            if (
                data.licence &&
                data.licence.licence &&
                data.licence.licence.decodedLicence &&
                data.licence.licence.decodedLicence.companyInfo
            ) {
                decodedLicence = data.licence.licence.decodedLicence.companyInfo;
            }
            _.forIn(decodedLicence, (val, key) => {
                companyInfo[key] = val;
            });

            return companyInfo;
        },
    }),

    condoOwnerMap: monkey({
        cursors: {
            condoOwners: ['model', 'CondoOwner'],
        },
        get: (data) => {
            const result = {};
            data.condoOwners.forEach((c) => {
                result[c.id] = { ...c };
                result[c.id].roomInfoIds = c.condoRoomInfoIds
                    ? c.condoRoomInfoIds.split(',').map((i) => {
                          const targetId = i.split(':')[0];
                          return Number(targetId);
                      })
                    : [];
            });
            return result;
        },
    }),
    condoOwnerReceptionistUuidMap: monkey({
        cursors: {
            condoOwners: ['model', 'CondoOwner'],
        },
        get: (data) => {
            const result = {};
            data.condoOwners.forEach((c) => {
                result[c.userUuid] = { ...c };
                result[c.userUuid].roomInfoIds = c.condoRoomInfoIds
                    ? c.condoRoomInfoIds.split(',').map((i) => {
                          const targetId = i.split(':')[0];
                          return Number(targetId);
                      })
                    : [];
            });
            return result;
        },
    }),
    filteredAccommodationPriceLists: monkey({
        cursors: {
            accommodationPriceLists: ['model', 'AccommodationPriceList'],
        },
        get: (data) => {
            const filterAccommodationPriceLists = data.accommodationPriceLists.filter((acc) => {
                return !acc.condoPricelist && acc.isActive && !acc.isDeleted;
            });
            return filterAccommodationPriceLists;
        },
    }),
    // roomReservations: monkey({
    //     cursors: {
    //         reservations: ['model', 'Reservation'],
    //     },
    //     get: (data) => {
    //         const result = {};
    //         data.reservations.forEach((res) => {
    //             if (result[res.roomInfoId] === undefined) {
    //                 result[res.roomInfoId] = [];
    //             }
    //             result[res.roomInfoId].push(res);
    //         });
    //         return result;
    //     },
    // }),

};

export { parseRoomInfos };

export default monkeys;
