import React, { useState } from 'react';
import { branch } from 'baobab-react/higher-order';
import baobab from '../../data/state/index';
import { getRoomSRCStatusIconByLastSeenTimestamp } from '../../data/controllers/SRCStatus';
import rest from '../../data/restWrapper';
import baobabTree, { saveCachedBaobabData } from '../../data/state/index';
//const srcSettingsMap = {};
import BasicSRCSettings from '../roomInfo/components/basicSRCSettingsModal';
import { setModalContent } from '@components/modal';
import CustomInput from '@components/custom/input';
import { ReactComponent as Search } from '../../assets/custom/icons/search.svg';
import translate from '@data/translations';

const calcDashboard = ({ rooms, eventTickets, src, SRCEnums, alarmInterpreter }) => {
    const result = [];
    const roomModeInterpreter = alarmInterpreter?.roomModeInterpreter ? alarmInterpreter.roomModeInterpreter : {};
    if (!SRCEnums.ioNamedEnum) {
        return result;
    }
    const dashboardItemTemplate = {
        roomInfoId: null,
        roomName: '',
        lastSeenTimestamp: null,
        inputs: {},
        outputs: {},

        minibar: false,
        cleaningStatus: '', //cleaningStatus.noActionTaken,
        minTemperature: 5,
        maxTemperature: 26,
        targetTemperature: 22,
        currentTemperature: 21,
        roomMode: '',
    };

    const evTicketRoomMap = {};
    eventTickets.forEach((ev) => {
        if (evTicketRoomMap[ev.roomInfoId] === undefined) {
            evTicketRoomMap[ev.roomInfoId] = {};
        }
        evTicketRoomMap[ev.roomInfoId][ev.ruleName] = ev;
    });

    const ioKeys = Object.keys(SRCEnums.ioNamedEnum);

    const srcMap = {};
    src.forEach((s) => {
        srcMap[s.remoteDeviceId] = s;
    });

    for (const room of rooms) {
        if (room?.id && evTicketRoomMap[room.id]) {
            //ili nema HW-a

            const temp = { ...dashboardItemTemplate, inputs: {} };
            temp.roomInfoId = room.id;
            temp.roomName = room.name;
            temp.lastSeenTimestamp = srcMap[room.remoteDeviceId] ? srcMap[room.remoteDeviceId].lastSeenTimestamp : 0;

            temp.thReadoutTimestamp = srcMap[room.remoteDeviceId]
                ? srcMap[room.remoteDeviceId].thReadoutTimestamp
                : null;
            temp.lastTh1Readout = srcMap[room.remoteDeviceId] ? srcMap[room.remoteDeviceId].lastTh1Readout : null;
            temp.lastTh2Readout = srcMap[room.remoteDeviceId] ? srcMap[room.remoteDeviceId].lastTh2Readout : null;
            temp.lastTh3Readout = srcMap[room.remoteDeviceId] ? srcMap[room.remoteDeviceId].lastTh3Readout : null;

            temp.lastWire1Readout = srcMap[room.remoteDeviceId] ? srcMap[room.remoteDeviceId].lastWire1Readout : null;
            temp.lastWire2Readout = srcMap[room.remoteDeviceId] ? srcMap[room.remoteDeviceId].lastWire2Readout : null;
            temp.lastWire3Readout = srcMap[room.remoteDeviceId] ? srcMap[room.remoteDeviceId].lastWire3Readout : null;
            temp.wireReadoutTimestamp = srcMap[room.remoteDeviceId]
                ? srcMap[room.remoteDeviceId].wireReadoutTimestamp
                : null;

            temp.lastCleaningDate = new Date(room.cleaningModeActivatedTs).toISOString(true).substr(0, 10);

            for (let i = 0; i < ioKeys.length; i++) {
                const ioName = ioKeys[i];
                const isInput = SRCEnums.inputs.includes(ioKeys[i]); //_.includes(SRCEnums.inputs, ioKeys[i]); //else are output
                const inputOpenOutputClosed = room.SRCIOStates & (1 << (31 - i));
                if (isInput) {
                    temp.inputs[ioName] = inputOpenOutputClosed; //false for closed
                } else {
                    temp.outputs[ioName] = inputOpenOutputClosed === 0; // false for off
                }
            }

            if (evTicketRoomMap[room.id]['alarm_ecoRoomMode']) {
                temp.roomMode = roomModeInterpreter['ECO'];
            } else if (evTicketRoomMap[room.id]['alarm_comfortRoomMode']) {
                temp.roomMode = roomModeInterpreter['COMFORT'];
            } else if (evTicketRoomMap[room.id]['alarm_preComfortRoomMode']) {
                temp.roomMode = roomModeInterpreter['PRECOMFORT'];
            } else if (evTicketRoomMap[room.id]['alarm_cleaningRoomMode']) {
                temp.roomMode = 'CLN';
            }
            temp.minibar = evTicketRoomMap[room.id]['customAlarm_minibarNotClosed'] !== undefined;
            result.push(temp);
        } else {
            //console.log('ZAPELO', evTicketRoomMap[room.id], room.id);
        }
    }

    return result;
};

const HwRoomView = (props) => {
    const [isFetchTemperatureDisabled, setIsFetchTemperatureDisabled] = useState(false);
    const [areTemperatureValuesShown, setShowTemperatureValues] = useState(false);
    const [searchTermState, setSearchTerm] = useState('');

    const { history, rooms, alarmInterpreter, columnsNumber } = props;
    const { eventTickets, src, SRCEnums } = props;
    /*const cursor = useBranch({
eventTickets: ['model', 'RoomEventTicket'],
src: ['model', 'SmartRoomController'],
SRCEnums: ['SRCEnums'],
});*/

    const filteredRoomsIds = rooms.map((r) => Number(r.id));
    const parsedRoomInfo = baobab.root
        .select(['monkeys', 'parsedRoomInfo'])
        .get()
        .filter((pr) => filteredRoomsIds.includes(Number(pr.id)));

    const filteredRooms = parsedRoomInfo
        ?.filter((i) => (i ? i.availableForRent : null))
        ?.filter((f) => f !== null)
        ?.filter((r) => r.name.toLowerCase().includes(searchTermState.toLowerCase()))
        ?.sort((a, b) => (a.name > b.name ? 1 : -1));
    const srcStatusDashboard = calcDashboard({ eventTickets, src, SRCEnums, rooms: filteredRooms, alarmInterpreter });

    let numberOfItems = srcStatusDashboard.length;
    let maxNumberOfItemsPerColumn = Math.ceil(numberOfItems / columnsNumber);

    let clmnArray = [];

    React.useEffect(() => {
        const loadData = async () => {
            try {
                const data = await rest('/getGeneralSettingByKey/alarmInterpreter');
                if (data !== null) {
                    baobab.root.select('state', 'alarmInterpreter').set(JSON.parse(data.params));
                }
            } catch (err) {
                console.log('error', err);
            }
        };

        loadData().catch((err) => {
            console.warn(err);
        });
    }, []);

    for (let i = 0; i < columnsNumber; i++) {
        clmnArray.push(i);
    }

    const handleShowBasicOptions = (roomInfoId, roomsName) => {
        const room = filteredRooms.find((r) => r.id === roomInfoId);

        let roomName = room.name;
        let basicSRCSettings = (
            <BasicSRCSettings
                roomInfoId={room.id}
                // key={roomInfo.id}
                // reservation={this.props.reservation}
                remoteDeviceId={room.remoteDeviceId}
            />
        );
        setModalContent(
            basicSRCSettings,
            <span>
                {window.translate('Room settings') + ' - '} <b>{roomName}</b>
            </span>,
            true,
            'modal-xl'
        );
    };
    // const fetchTemperatureValues = async () => {
    //     try {
    //         setIsFetchTemperatureDisabled(true);
    //         const roomIds = filteredRooms.map((room) => {
    //             return room.id;
    //         });
    //         const data = await rest('/src/fetchThermostatValuesForRooms', 'POST', { roomIds: roomIds });

    //         console.log('data', data);

    //         setRoomThermostatTemperatures(data);
    //         setLastReadout(new Date().getTime());
    //         setIsFetchTemperatureDisabled(false);
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    const isRoomOnline = (lastSeenTimestamp) => {
        if (lastSeenTimestamp) {
            const lastSeenMilisecondsFromNow = Date.now() - lastSeenTimestamp;
            if (lastSeenMilisecondsFromNow > 20 * 60 * 1000) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    };

    function chunkArray(arr, size) {
        const chunks = [];
        for (let i = 0; i < arr.length; i += size) {
            chunks.push(arr.slice(i, i + size));
        }
        return chunks;
    }

    const fetchTemperatureValues = async (type) => {
        try {
            setIsFetchTemperatureDisabled(true);
            const maxRoomPerRequest = 50;
            // const roomIds = filteredRooms.map((room) => room.id);
            const roomIds = [];
            // saljem samo online id-jeve soba
            // saljem samo online id-jeve soba
            src.forEach((contr) => {
                const _roomInfo = filteredRooms.find((room) => room.remoteDeviceId === contr.remoteDeviceId);
                if (_roomInfo && isRoomOnline(contr.lastSeenTimestamp)) {
                    roomIds.push(_roomInfo.id);
                }
            });

            const roomBatches = chunkArray(roomIds, maxRoomPerRequest);
            const roomThermostatTemperatures = {};
            async function fetchThermostatValuesForRooms(roomBatch) {
                const data = await rest('/src/fetchThermostatValuesForRooms', 'POST', {
                    roomIds: roomBatch,
                    type: type,
                });
                for (const iterator in data) {
                    roomThermostatTemperatures[iterator] = data[iterator];
                }
            }
            const fetchPromises = roomBatches.map(fetchThermostatValuesForRooms);
            await Promise.all(fetchPromises);

            setIsFetchTemperatureDisabled(false);
        } catch (error) {
            console.log(error);
        }
    };

    const searchRoom = (e) => {
        setSearchTerm(e.target.value);
    };

    return (
        <div className="w-100">
            <div className="d-flex justify-content-center align-items-center my-1 hardware-rooms">
                <div className="flex-1">
                    <div
                        className="px-3 btn increase-decrease-button pointer"
                        onClick={() => {
                            if (columnsNumber > 1) {
                                // baobab.root.select('uiStates', 'columnsNumber').set(columnsNumber - 1);
                                baobabTree.root.select(['uiStates', 'columnsNumber']).set(columnsNumber - 1);
                                saveCachedBaobabData(baobabTree);
                            }
                        }}
                    >
                        -
                    </div>
                    <div className=" px-1 d-inline-block">
                        {translate('Columns')}: {columnsNumber}
                    </div>
                    <div
                        className="px-3 btn increase-decrease-button pointer"
                        onClick={() => {
                            // baobab.root.select('uiStates', 'columnsNumber').set(columnsNumber + 1);
                            baobabTree.root.select(['uiStates', 'columnsNumber']).set(columnsNumber + 1);
                            // saveCachedBaobabData(baobabTree );
                        }}
                    >
                        +
                    </div>
                </div>
                <div className="flex-2">
                    <div style={{ position: '', display: 'flex', right: 0, marginRight: '12px', marginTop: '12px' }}>
                        <div
                            onClick={() => {
                                setShowTemperatureValues(!areTemperatureValuesShown);
                            }}
                            className="mr-4p button-light-w-icon flex-center-center"
                        >
                            <div className="flex-center-center" style={{ height: '32px', width: '32px' }}>
                                <i
                                    className={areTemperatureValuesShown ? 'fa fa-check-square-o' : 'fa fa-square-o'}
                                ></i>
                            </div>
                            <span>{window.translate('Show temperatures')}</span>
                        </div>
                        <button
                            disabled={isFetchTemperatureDisabled}
                            onClick={async () => {
                                await fetchTemperatureValues('thermostat');
                            }}
                            className="button-primary ml-4p mr-4p"
                        >
                            {window.translate('Fetch temperature values')}
                        </button>

                        <button
                            disabled={isFetchTemperatureDisabled}
                            onClick={async () => {
                                await fetchTemperatureValues('wire');
                            }}
                            className="button-primary ml-4p mr-4p"
                        >
                            {window.translate('Fetch wire sensor values')}
                        </button>
                        <div className="mr-4p ml-4p flex-center-center">
                            <CustomInput
                                onInputChange={(e) => {
                                    searchRoom(e);
                                }}
                                icon={<Search />}
                                placeholder={window.translate('Search by room name')}
                            />
                        </div>
                    </div>
                </div>
                <div></div>
            </div>

            {srcStatusDashboard.map((val, ind) => {
                if (ind < maxNumberOfItemsPerColumn) {
                    return (
                        <div key={ind} className="d-flex w-100">
                            {clmnArray.map((itm, i) => {
                                if (srcStatusDashboard[ind + i * maxNumberOfItemsPerColumn]) {
                                    return (
                                        <div
                                            key={ind + '_' + i}
                                            className="d-flex justify-content-between"
                                            style={{ flexBasis: `${100 / columnsNumber}%` }}
                                        >
                                            <HWLine
                                                key={ind}
                                                history={history}
                                                dashboardObj={{
                                                    ...srcStatusDashboard[ind + i * maxNumberOfItemsPerColumn],
                                                }}
                                                alarmInterpreter={alarmInterpreter}
                                                handleShowBasicOptions={handleShowBasicOptions}
                                                areTemperatureValuesShown={areTemperatureValuesShown}
                                            ></HWLine>
                                        </div>
                                    );
                                } else {
                                    return null;
                                }
                            })}
                        </div>
                    );
                } else {
                    return null;
                }
            })}
        </div>
    );
};

const HWLine = ({ history, dashboardObj: i, alarmInterpreter, handleShowBasicOptions, areTemperatureValuesShown }) => {
    const inputInterpreter = alarmInterpreter?.inputInterpreter ? alarmInterpreter.inputInterpreter : [];
    const enabledInterpreters = inputInterpreter.filter((i) => i.enabled);

    // const roomInfoId = i?.roomInfoId;
    const lastReadoutTs = i.thReadoutTimestamp ? i.thReadoutTimestamp : null;

    const lastWireReadoutTs = i.wireReadoutTimestamp ? i.wireReadoutTimestamp : null;

    return (
        <small
            className="w-100 hw-room-tile"
            style={{ backgroundColor: 'white', margin: '1px', marginRight: '5px', border: '1px solid #ccc' }}
        >
            <div
                className="flex-center-center "
                // onClick={(e) => {e.stopPropagation()
                //     history.push('/roomInfo/' + i.roomInfoId);
                // }}
                style={{ display: 'flex', justifyContent: 'space-between' }}
            >
                <div className="px-1 elipsis" style={{ flex: 1 }}>
                    {getRoomSRCStatusIconByLastSeenTimestamp(i.lastSeenTimestamp)}
                </div>
                <div className="px-1 elipsis" style={{ flex: 1 }}>
                    {i.roomName}
                </div>
                {enabledInterpreters.map((ei, indx) => {
                    if (ei.name === 'cleaningServiceInput') {
                        return (
                            <div key={indx} className="px-1 elipsis" style={{ flex: 1 }}>
                                {i.lastCleaningDate === new Date().toISOString(true).substr(0, 10) ? (
                                    <i className="fa fa-female text-success" />
                                ) : (
                                    <i className="fa fa-female" />
                                )}
                            </div>
                        );
                    } else {
                        return HWIcon(i, ei.name, alarmInterpreter);
                    }
                })}

                {/*<div style={{ width: '30px' }}>{HWIcon(i, 'sosInput')}</div> //ovo nisu state inputi, već event inputi
<div style={{ width: '30px' }}>{HWIcon(i, 'doNotDisturbInput')}</div>
<div style={{ width: '30px' }}>{HWIcon(i, 'cleaningServiceInput')}</div>*/}

                <div className="px-1 elipsis" style={{ width: '80px' }}>
                    {i.roomMode}
                </div>

                <div
                    onClick={(e) => {
                        e.stopPropagation();
                        handleShowBasicOptions(i.roomInfoId, i.roomName);
                    }}
                    className="button-light  mr-4p"
                >
                    {' '}
                    <i className="fa fa-key"></i>
                </div>
                <div
                    onClick={(e) => {
                        e.stopPropagation();
                        history.push('/roomEventHistory/' + i.roomInfoId);
                    }}
                    className="button-light"
                >
                    {' '}
                    <i className="fa fa-list"></i>
                </div>
            </div>
            {areTemperatureValuesShown ? (
                <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span className="ml-4p">
                            <span>
                                <strong>T1: </strong>
                                {i.lastTh1Readout}
                            </span>
                        </span>
                        <span className="">
                            <span>
                                <strong>T2: </strong>
                                {i.lastTh2Readout}
                            </span>
                        </span>
                        <span className="">
                            <span>
                                <strong>T3: </strong>
                                {i.lastTh3Readout}
                            </span>
                        </span>
                        <span className="mr-4p">
                            {lastReadoutTs ? `Last read: ${new Date(lastReadoutTs).toLocaleString()}` : null}
                        </span>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span className="ml-4p">
                            <span>
                                <strong>1WT1: </strong>
                                {i?.lastWire1Readout}
                            </span>
                        </span>
                        <span className="">
                            <span>
                                <strong>1WT2: </strong>
                                {i?.lastWire2Readout}
                            </span>
                        </span>
                        <span className="">
                            <span>
                                <strong>1WT3: </strong>
                                {i?.lastWire3Readout}
                            </span>
                        </span>

                        <span className="mr-4p">
                            {lastWireReadoutTs ? `Last read: ${new Date(lastWireReadoutTs).toLocaleString()}` : null}
                        </span>
                    </div>
                </div>
            ) : null}
        </small>
    );
};

const HWIcon = (dashboardObj, inputName, alarmInterpreter) => {
    let ioSRC = {};
    ioSRC['inputs'] = alarmInterpreter?.inputInterpreter
        ? alarmInterpreter.inputInterpreter.filter((i) => i.enabled)
        : [];
    ioSRC['outputs'] = [];

    ioSRC['inputMap'] = {};
    ioSRC.inputs.forEach((i) => {
        ioSRC['inputMap'][i.name] = i;
    });

    const desc = ioSRC.inputMap[inputName];
    if (inputName === 'floodAlarmInput' || inputName === 'cardHolderInput') {
        const isInputClosed = dashboardObj.inputs[inputName] === 0; //inputi su zatvoreni ako postoji i ako je false!!!
        //alarm se pali na true state
        const iconStyle = isInputClosed
            ? `${desc?.icon} ${desc?.falseStateAnimationBlink === 'true' ? ' animated infinite flash ' : ''}`
            : `${desc?.icon} ${desc?.trueStateAnimationBlink === 'true' ? ' animated infinite flash ' : ''}`;
        if (desc && desc.enabled) {
            return (
                <div key={inputName} className="px-1 elipsis" style={{ flex: 1 }}>
                    <i
                        className={iconStyle}
                        style={{ color: isInputClosed ? desc?.falseStateIconColor : desc?.trueStateIconColor }}
                    />
                </div>
            );
        } else {
            return null;
        }
    } else {
        const isInputClosed = dashboardObj.inputs[inputName] === 0; //inputi su zatvoreni ako postoji i ako je false!!!
        //alarm se pali na false state
        const iconStyle = !isInputClosed
            ? `${desc?.icon} ${desc?.falseStateAnimationBlink === 'true' ? ' animated infinite flash ' : ''}`
            : desc?.icon;
        if (desc && desc.enabled) {
            return (
                <div key={inputName} className="px-1 elipsis" style={{ flex: 1 }}>
                    <i
                        className={iconStyle}
                        style={{ color: !isInputClosed ? desc?.falseStateIconColor : desc?.trueStateIconColor }}
                    />
                </div>
            );
        } else {
            return null;
        }
    }
};

export default branch(
    {
        eventTickets: ['model', 'RoomEventTicket'],
        src: ['model', 'SmartRoomController'],
        SRCEnums: ['SRCEnums'],
        alarmInterpreter: ['state', 'alarmInterpreter'],
        columnsNumber: ['uiStates', 'columnsNumber'],
        parsedRoomInfo: ['monkeys', 'parsedRoomInfo'],
    },
    HwRoomView
);
