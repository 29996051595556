// import pubsub from "./data/pubsub"
import Keycloak from 'keycloak-js';
import baobabTree, { saveCachedBaobabData } from './data/state';
import rest from "./data/restWrapper";
const keycloak = new Keycloak('/keycloakConfig');

async function setBaobabToken(authenticated) {
	const { realm, realmAccess, resourceAccess, sessionId, token, tokenParsed, refreshToken } = keycloak;

	let role = undefined;
	const instanceNameObj = await rest("/getInstanceName")

	let client = instanceNameObj?.instanceName ? instanceNameObj?.instanceName : "development"

	if (tokenParsed.resource_access[client] && tokenParsed.resource_access[client]?.roles[0]) {
		role = tokenParsed.resource_access[client].roles[0]
	}

	let authToken = await rest("/checkAndAuthenticateKeycloakUser", "POST",{keycloakTokenParsed:{...tokenParsed, role:role}},)
	baobabTree.root.select('keycloak').set({
		realm,
		realmAccess,
		resourceAccess,
		sessionId,
		token,
		tokenParsed,
		refreshToken,
		authenticated,
	});
	baobabTree.root.select('authTokenInfo').set({
		...authToken
	});
	saveCachedBaobabData(baobabTree)

}

async function handleTokenRefresh() {
	const refreshed = await keycloak.updateToken(30);
	if (refreshed) {
		setBaobabToken(refreshed);
	}
}

export async function initKeycloak(params) {
	keycloak.onTokenExpired = () => handleTokenRefresh();
	const isAuthenticated = await keycloak.init({
		//onLoad: 'login-required',
		checkLoginIframe: true,
		...params
	});

	if (isAuthenticated) {
		setBaobabToken(isAuthenticated);
	}
	return keycloak;
}

export async function logout() {
	try{
		if(keycloak.authenticated){
			return await keycloak.logout();
		}else{
			return true;
		}
	} catch(err){
		console.log('Could not log out. ', err);
	}
}

export function attachAuthorizationHeader(headers = {}) {	
	if(keycloak.token){
		headers['Authorization'] = `Bearer ${keycloak.token}`;
	}
}

export function checkClientRole(roleName) {
	return keycloak.hasResourceRole(roleName);

}

export function checkRealmRole(roleName) {
	return keycloak.hasRealmRole(roleName);
}