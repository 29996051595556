import * as React from 'react';
//@ts-ignore
import { useBranch } from 'baobab-react/hooks';
//@ts-ignore
import { Link } from 'react-router-dom';
import { translate } from '@data/translations';
import { ReservationController, Reservation } from '../reservations2/resController';
import {
    InvoiceModelWithInvoiceItems,
    PosDisplayConfigModel,
    PosTerminalModel,
    ReservationStatus,
} from '@common/modelDefinition';
import FeatureFlag from '@components/FeatureFlag';
import moment from 'moment';
import { searchInvoice } from '../reservations2/api';
import ConfirmButtons from '@components/buttons/confirmButtons';
import { setModalContent, closeModal } from '@components/modal';
// import { customStyles } from '@pages/dashboard/toolbar';
// import Select from 'react-select';
const ctrl = new ReservationController();

var searchDebouncer: NodeJS.Timeout = setTimeout(() => {}, 0);

interface PosTerminalConsumptionProps {
    reservation: Reservation;
    history: any;
}

interface ConsumptionInvoicesProps {
    reservation: Reservation;
    history: any;
    consumptionInvoices: any[];
    posTerminals?: any;
}

function PosTerminalConsumption(props: PosTerminalConsumptionProps) {
    const { reservation, history } = props;

    const [searchTerm, setSearchTerm] = React.useState('');
    const [invoices, setInvoices] = React.useState<InvoiceModelWithInvoiceItems[]>([]);

    const { posTerminals, loggedUser, posDisplayConfigs } = useBranch({
        posTerminals: ['model', 'PosTerminal'],
        loggedUser: ['authTokenInfo', 'user'],
        posDisplayConfigs: ['model', 'PosDisplayConfig'],
    });

    const posTerminalInvoices: any[] = reservation?.Invoices?.filter((inv: any) => inv.terminalType === 'posTerminal');
    const reservationId = reservation ? reservation.id : null;

    if (!reservationId) {
        //trenutno, na sve rezervacije se moze nakacit racun iz pos terminala (ali samo ako se ide kroz formu rezervacije)
        return null;
    }

    const filteredPosTerminals = posTerminals.filter(
        (posTerminal: any) => posTerminal.isEnabled === true && posTerminal.terminalType === 0
    );

    // const optionList = filteredPosTerminals?.map((posTerminal: any) => {
    //     return { label: posTerminal.name, value: posTerminal.id };
    // });

    const onInvoiceSearch = (e: any) => {
        clearTimeout(searchDebouncer);
        const searchString = e.target.value;
        setSearchTerm(searchString);
        searchDebouncer = setTimeout(async () => {
            const result = await searchInvoice(searchString);
            const filteredInvoices = result.filter((r) => r.terminalType === 'posTerminal');
            setInvoices(filteredInvoices);
        }, 500);
    };

    return (
        <FeatureFlag flag="simplePosTerminal">
            <div className="">
                <div className="mt-4p">
                    {filteredPosTerminals &&
                    filteredPosTerminals.length > 0 &&
                    reservation.statusEnum !== ReservationStatus.canceled &&
                    reservation.statusEnum !== ReservationStatus.autoBlocked ? (
                        <div className="display-flex justify-content-between">
                            <div
                                style={{ paddingTop: '8px' }}
                                onClick={() => {
                                    if (loggedUser?.uuid && posDisplayConfigs) {
                                        const posDisplayConfig = posDisplayConfigs.find((p: PosDisplayConfigModel) =>
                                            p.associatedUserUuids?.includes(loggedUser?.uuid)
                                        );
                                        if (posDisplayConfig?.associatedTerminalIds) {
                                            const targetPosTerminal = posTerminals?.find((p: PosTerminalModel) => {
                                                if (
                                                    p.terminalType === 0 &&
                                                    posDisplayConfig?.associatedTerminalIds?.includes(Number(p.id))
                                                ) {
                                                    return p; //Pronađen prvi dozvoljen pos terminal
                                                } else {
                                                    return null;
                                                }
                                            });
                                            if (targetPosTerminal) {
                                                props.history.push(
                                                    '/posTerminal/' + targetPosTerminal.id + '?resId=' + reservationId
                                                );
                                            } else {
                                                return null;
                                            }
                                        }
                                    } else {
                                        return null;
                                    }
                                }}
                                className="button-link"
                            >
                                {translate('Create invoice')}
                            </div>
                            <input
                                // disabled={true}
                                autoComplete="off"
                                placeholder={translate('Search by invoice number or invoice id')}
                                name="search"
                                value={searchTerm}
                                onChange={(e) => {
                                    onInvoiceSearch(e);
                                }}
                                type="text"
                                className="input input-modal ml-16p"
                            />
                        </div>
                    ) : null}
                    <div style={{ maxHeight: '300px', overflowY: 'scroll' }}>
                        {invoices.length > 0 ? (
                            <div>
                                {invoices.map((i: InvoiceModelWithInvoiceItems) => {
                                    const { customerInfo, companyInfo } = i;
                                    const customer = customerInfo
                                        ? typeof customerInfo === 'string'
                                            ? JSON.parse(customerInfo)
                                            : customerInfo
                                        : null;

                                    const company = companyInfo
                                        ? typeof companyInfo === 'string'
                                            ? JSON.parse(companyInfo)
                                            : companyInfo
                                        : null;
                                    return (
                                        <div key={i.id} className="custom-list-item">
                                            <div>
                                                <button
                                                    onClick={() => {
                                                        const newInvoice = { ...i };
                                                        if (reservation.id) {
                                                            newInvoice.reservationId = reservation.id;
                                                            if (reservation.groupUuid) {
                                                                newInvoice.groupUuid = reservation.groupUuid;
                                                            }
                                                            ctrl.addOrRemoveInvoice(newInvoice, true);
                                                            setInvoices([]);
                                                            setSearchTerm('');
                                                        }
                                                    }}
                                                    className="btn btn-sm btn-outline-primary pull-left mr-3"
                                                >
                                                    {translate('Select')}
                                                </button>
                                            </div>

                                            <div>{i.invoiceNumberFull}</div>
                                            {customer ? (
                                                <div>
                                                    {customer.firstName} {customer.lastName}
                                                </div>
                                            ) : null}
                                            {company ? <div>{company.name}</div> : null}
                                            <div>
                                                {i.totalValue} {i.currency}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        ) : null}

                        <ConsumptionInvoices
                            reservation={reservation}
                            consumptionInvoices={posTerminalInvoices}
                            history={history}
                            posTerminals={posTerminals}
                        />
                    </div>
                </div>
            </div>
        </FeatureFlag>
    );
}

export const ConsumptionInvoices = (props: ConsumptionInvoicesProps) => {
    // Minibar ili POS Terminal
    const { consumptionInvoices, posTerminals = null } = props;

    const headerField = posTerminals ? (
        <div className="">{translate('TERMINAL')}</div>
    ) : (
        <div className=" uppercase">{translate('Invoice issued by')}</div>
    );

    if (consumptionInvoices.length === 0 && !posTerminals) {
        return null; // ne prikazuj lista je prazna ako su upitanju minibar računi
    }

    return (
        <div>
            <div className="my-2">
                {consumptionInvoices.length === 0 ? (
                    <small>{translate('List is empty')}</small>
                ) : (
                    <div className="">
                        <div className="custom-list-header" style={{ width: '100%', margin: 0, padding: 0 }}>
                            <div>{translate('DATE')}</div>
                            {headerField}
                            <div>{translate('TOTAL VALUE')}</div>
                            <div>{translate('INV. NUM.')}</div>
                            <div></div>
                        </div>
                        {consumptionInvoices.map((t, key) => {
                            const terminal = posTerminals
                                ? posTerminals.find((p: any) => p.id === t.posTerminalId)
                                : null;
                            return (
                                <div
                                    key={key}
                                    className="custom-list-item"
                                    style={{ width: '100%', margin: 0, padding: 0 }}
                                >
                                    <div>{moment(t.timestamp).format('DD/MM/YYYY')}</div>
                                    <div>{terminal ? terminal.name : t.operatorName}</div>
                                    <div>
                                        {t.totalValue} {t.defaultCurrency}
                                    </div>
                                    <div>
                                        <Link
                                            key={key}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            to={'/invoiceView/' + t.id}
                                        >
                                            {t.invoiceNumberFull}
                                        </Link>
                                    </div>
                                    <div>
                                        <button
                                            disabled={t.connectedInvoiceProcessed}
                                            onClick={() =>
                                                setModalContent(
                                                    <div className="container-fluid">
                                                        <div className="text-center">
                                                            {translate('Please confirm.')}
                                                            <hr />
                                                        </div>

                                                        <div className="text-center">
                                                            <ConfirmButtons
                                                                onConfirm={async () => {
                                                                    const newInvoice = { ...t };
                                                                    newInvoice.reservationId = null;
                                                                    newInvoice.groupUuid = null;
                                                                    ctrl.addOrRemoveInvoice(newInvoice, false);
                                                                    closeModal();
                                                                }}
                                                                onCancel={() => {
                                                                    closeModal();
                                                                }}
                                                            />
                                                        </div>
                                                    </div>,
                                                    translate('Remove invoice'), // header
                                                    false, // showCloseButton
                                                    'modal-md'
                                                )
                                            }
                                            className="button-secondary mr-1 ml-auto"
                                        >
                                            <i className="fa fa-trash"></i>
                                        </button>
                                        {/* <button
                                            // disabled={!addOrRemoveFromConnectedInvoices}
                                            onClick={() => {
                                                const { groupUuid, id } = reservation;
                                                if (!groupUuid) {
                                                    history.push('/checkoutAndInvoice/' + id);
                                                } else {
                                                    history.push('/issueGroupInvoice/' + groupUuid);
                                                }
                                            }}
                                            className="button-primary mr-1"
                                        >
                                            {translate('Invoices')}
                                        </button> */}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
};

export default PosTerminalConsumption;
